import { Component,OnInit } from '@angular/core';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { ExcelService } from '../../services/excel.service';
import { TableUtil } from "../auditor/tableUtil";

import { Router } from '@angular/router';
import { p_model } from './model';

import { items,p_model_rep } from './model';
import { sublist } from './model';
@Component({
    selector: 'sales-red',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class Stock_redReport {
  
   
    data : any [] =[];
    data_e : any [] = [];
    data_pur : any [] =[];
    data_pur_mis : any [] =[];
    data_pur_ret : any [] =[];
    post_invo = new items(1,[]);
    p_model_rep_model = new p_model_rep([])
    total_amount : number = 0;
    pdtlist : any [] = [];
    qty_list : any [] = [];
    amount_list : any [] = [];
    cats : any [] = [];
    items = [];
    itemCount = 0;
    to_date : string;
    from_date : string;
    isClickedOnce : boolean = false;
    view : boolean = false;
    length : number = 0;
    total_revenue : number =0;
     total_tax :number = 0;
     today: number = Date.now();
     total_due : number = 0;
     bool : boolean ;
     si : number = 0;
      j :number = 0;
      view_head : boolean = false
      total_stock_item : number = 0;
      tot_pur_val : number = 0;
      tot_sal_val : number = 0;
      tot_profit_val : number = 0;
     index : number = 0;
     view_cat : boolean = false;
     category : string = ''
     product_array : p_model [] = [];
       dateFormm = this.fb.group({                  
                                                  
                                                  from_date : '1987-09-10',
                                                  to_date: '2055-08-09',
                                                  cat : '',
                                                  option : 2
                                                 
                                              
                                         });

    constructor( private fb: FormBuilder,private es: ExcelService,
     private ds : DataService, private uS: UpdateService, private rs: Router ,private Rs: ReportService ) {
       
    }
ngOnInit() {
   

   this.get_cats();
   this.get_lists();

  
    
   
  }

  
  get_cats()
  {
        this.ds.get_product_categories()
       .subscribe((jsonData) => { this.getjson_cats(jsonData)
                      },(err) => console.error(err),
                      
                      );

  }

getjson_cats(json:any)
{
 
  
     this.cats[0] = 'All'

       for(var i = 0; i < json.length ; i++)
       {

         this.cats[i+1]= json[i].CATEGORY_NAME;
       }
    this.view_cat = true;

}


 exportAsXLSX():void {
    // this.es.exportAsExcelFile(this.post_invo.item, 'sales_report');
    TableUtil.exportToExcel("Sales_Table_cat");
  }

get_lists()
{
   
  this.uS.list_stocks()
 .subscribe((jsonData) => { this.getjson252(jsonData)
                      },(err) => console.error(err),
                      
                      );
}
  

  

      getjson252(json :any)
  {
    
    
    this.view = true;
    this.product_array = json;

  


    for(var i =0;i<this.product_array.length;i++)
    {
      this.p_model_rep_model.p_model_slave_array.push({DESCRIPTION:null,SALES_QTY:0,PRO_ID:null,OPEN_QTY:0,SI:0,PUR_QTY_MIS:0,SALES_QTY_E:0,
        SALES_RET_QTY:0,CATGERY:null,PUR_QTY:0,PUR_RET_QTY:0,BINNED_QTY:0,CURR_QTY:0})

       this.p_model_rep_model.p_model_slave_array[i].DESCRIPTION = this.product_array[i].DESCRIPTION;
       this.p_model_rep_model.p_model_slave_array[i].PRO_ID = this.product_array[i].ID;
       this.p_model_rep_model.p_model_slave_array[i].CATGERY = this.product_array[i].CATGERY;
       this.p_model_rep_model.p_model_slave_array[i].OPEN_QTY = this.product_array[i].OPEN_QTY;
       this.p_model_rep_model.p_model_slave_array[i].SI = i+1;

    }

    
    
   
  }







  change_cat(x:any)
  {
  
     
      this.category = x;

  }



search_by_cats()
{
     this.from_date = this.Rs.from_date ;
     this.to_date = this.Rs.to_date;

   this.isClickedOnce = true

    this.dateFormm.patchValue({from_date:  this.from_date, to_date: this.to_date })
  
    
    
    this.ds.get_sales_report_stock(this.dateFormm.value)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );


                                  

}

// get_sales_report_stock
  // 



print_re()
{
  window.print();
}
 auto_push()
  {
        this.post_invo.item.push({ SI:1,DESCRIPTION:'',OPENING_QTY:0,PURCHASE_QTY:0,PURCHASE_QTY_MIS:0,SALES_QTY:0,SALES_RET_QTY:0,
        PUR_RET_QTY:0,TOTAL_QTY:0})

  }



  getjson(s:any)
  {
     
     this.view = true;
     this.data = s.data;
  
     this.length = this.data.length;
     
 
     for(var x=0;x<this.p_model_rep_model.p_model_slave_array.length;x++)
     {
 
         for(var y=0;y<this.data.length;y++)
         {
         
           if(this.data[y].PRODUCT_ID === this.p_model_rep_model.p_model_slave_array[x].PRO_ID)
           {
            
              this.p_model_rep_model.p_model_slave_array[x].SALES_QTY =
              this.p_model_rep_model.p_model_slave_array[x].SALES_QTY + this.data[y].QUANTITY;

              if(this.data[y].RET_QTY != null)
              {
                this.p_model_rep_model.p_model_slave_array[x].SALES_RET_QTY =
                this.p_model_rep_model.p_model_slave_array[x].SALES_RET_QTY + this.data[y].RET_QTY
              }
             

              if(this.data[y].STOCK_RET_QTY != null)
              {
              
              this.p_model_rep_model.p_model_slave_array[x].BINNED_QTY =  this.p_model_rep_model.p_model_slave_array[x].BINNED_QTY +
              (this.data[y].RET_QTY - this.data[y].STOCK_RET_QTY);

              }
            


              
           }
         }

     }


    
     console.log('Sales',this.data);



     this.ds.get_sales_report_stock_from_estimate(this.dateFormm.value)
     .subscribe((jsonData) => { this.getjson_sales_e(jsonData)
                    },(err) => console.error(err),
                    
                    );


    
     this.ds.get_purchase_detail_report(this.dateFormm.value)
     .subscribe((jsonData) => { this.getjson_purchase(jsonData)
                    },(err) => console.error(err),
                    
                    );

  }

  getjson_sales_e(jsn:any)
  {
      this.data_e = jsn.data;
      
      for(var h=0;h<this.p_model_rep_model.p_model_slave_array.length;h++)
      {
        for(var d=0;d<this.data_e.length;d++)
        {

          if(this.data_e[d].PRODUCT_ID === this.p_model_rep_model.p_model_slave_array[h].PRO_ID)
          {
           
             this.p_model_rep_model.p_model_slave_array[h].SALES_QTY_E =
             this.p_model_rep_model.p_model_slave_array[h].SALES_QTY_E + this.data_e[d].QUANTITY;
          }
        }

      }

        




  }
  getjson_purchase(js:any)
  {
   
    this.data_pur = js.data;
   
    for(var p=0;p<this.p_model_rep_model.p_model_slave_array.length;p++)
    {
      
        for(var q=0;q<this.data_pur.length;q++)
        {
        
          if(this.data_pur[q].PRO_ID === this.p_model_rep_model.p_model_slave_array[p].PRO_ID)
          {
           
             this.p_model_rep_model.p_model_slave_array[p].PUR_QTY =
             this.p_model_rep_model.p_model_slave_array[p].PUR_QTY + this.data_pur[q].QUANTITY;
          }
        }

    }

    
    this.ds.get_purchase_return_detail_report(this.dateFormm.value)
    .subscribe((jsonData) => { this.getjson_purchase_return(jsonData)
                   },(err) => console.error(err),
                   
                   );

    
  }


getjson_purchase_return(json:any)
{
  

   this.data_pur_ret = json.data;

  
   
   for(var pq=0;pq<this.p_model_rep_model.p_model_slave_array.length;pq++)
   {
     
       for(var qs=0;qs<this.data_pur_ret.length;qs++)
       {
       
         if(this.data_pur_ret[qs].PRO_ID === this.p_model_rep_model.p_model_slave_array[pq].PRO_ID)
         {          
            this.p_model_rep_model.p_model_slave_array[pq].PUR_RET_QTY =
            this.p_model_rep_model.p_model_slave_array[pq].PUR_RET_QTY + this.data_pur_ret[qs].RET_QTY;
         }
       }

   }


   
   this.ds.purchase_report_stock_from_mise(this.dateFormm.value)
   .subscribe((jsonData) => { this.getjson_purchase_mise(jsonData)
                  },(err) => console.error(err),
                  
                  );

                }



  getjson_purchase_mise(js:any)             

   {
     console.log('js',js);


     this.data_pur_mis = js.data;

  
   
     for(var pqb=0;pqb<this.p_model_rep_model.p_model_slave_array.length;pqb++)
     {
       
         for(var qsb=0;qsb<this.data_pur_mis.length;qsb++)
         {
         
           if(this.data_pur_mis[qsb].PRO_ID === this.p_model_rep_model.p_model_slave_array[pqb].PRO_ID)
           {          
              this.p_model_rep_model.p_model_slave_array[pqb].PUR_QTY_MIS =
              this.p_model_rep_model.p_model_slave_array[pqb].PUR_QTY_MIS + this.data_pur_mis[qsb].QUANTITY;
           }
         }
  
     }



  
   for(var pqz=0;pqz<this.p_model_rep_model.p_model_slave_array.length;pqz++)
   {



   
     this.p_model_rep_model.p_model_slave_array[pqz].CURR_QTY  =  (this.p_model_rep_model.p_model_slave_array[pqz].PUR_QTY-0) - 
     (this.p_model_rep_model.p_model_slave_array[pqz].SALES_QTY_E-0) + (this.p_model_rep_model.p_model_slave_array[pqz].PUR_QTY_MIS-0) - 
     (this.p_model_rep_model.p_model_slave_array[pqz].PUR_RET_QTY-0)  + (this.p_model_rep_model.p_model_slave_array[pqz].SALES_RET_QTY-0) - 
     (this.p_model_rep_model.p_model_slave_array[pqz].BINNED_QTY-0) ;
   
 
   }
 
   
  if(this.category != 'All')
  {
    this.p_model_rep_model.p_model_slave_array = this.p_model_rep_model.p_model_slave_array.filter(xy=>xy.CATGERY === this.category);
  }

 


   this.view_head = true;



}



 
print_report()
{
  window.print();
}
 
}