export class items {
    constructor(
      
           
           public item : sublist[]
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  
  public si : number,
  public com_name : string,
  public com_line : string,
  public net_wt : string,
  public mrp : string,
  public bcValue : string,
  public fssai : string,
  public Ingradiants : string,
  public other : string,
  public packed_on : string,
  public best_before : string,
  public name : string
  
  
    ) {  }
  }