

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth.guard';
import { User_role } from './services/user_role';
import { DashComponent } from './dash/dash';
import { HomeComponents } from './home/home';
import { LoginComponent } from './login/login';
import { Bill_b2b } from './bill/new/main';
import { Greeting_Component } from './admin/greeting/main';
import { Tax_manageComponent } from './admin/manage_tax/main';
import { Add_product } from './product/add_product';
import { Update_product } from './product/update';
import { BulkUpdate_product } from './product/bulk_update';
import { Delete_product } from './product/del_product';
import { Invoiceall_b2b } from './bill/view/b2b/main';
import { Invoiceall_b2c } from './bill/view/b2c/main';
import { Bill_quick } from './bill/quick/quick';

import { Customer_Component } from './admin/customer/main';
import { MainReportComponent } from './reports/main/main';
import { Expence_main } from './expence/main';
import { ListStock } from './reports/stock/main';
import { Revenue_report } from './reports/revenue/main';
import { Purchase_report } from './reports/purchase/main';
import { Expense_report } from './reports/expense/main';
import  { SalesReport } from './reports/sales/main';
import  { Stock_redReport } from './reports/stock_red/main';

import { Customer_statement } from './reports/customer/main';
import { Admin_Invoice_string_mainComponent } from './admin/prefix/main';
import { BackupComponents } from './admin/backup/main';
import { ReturnComponent } from './return/main';
import  { Auditor_statement } from './reports/auditor/auditor';
import  { Customer_cr_deb_statement} from './reports/cus-cr-deb/main';
import { Purchase_main } from './purchase/main';
import { PaymentComponentsonbills } from './admin/cash_on_bill/main';
import { Purchase_all } from './admin/purchase_all/view/main';
import  { Expence_main_details } from './admin/purchase_all/detail_view/main';
import { Expence_main_view } from './expence/view/main';
import { Profit_report } from './reports/profit_loss/main';
import { Vendor_cr_deb_statement } from './reports/vendor-cr-deb/main';
import { ListStock_details } from './reports/stock_detailed/main';
import  { BarcodeComponent } from './barcode/main';
import { Purchase_ret_edit } from './admin/purchase_return/edit/main';
import {  Purchase_main_misce  } from './purchase/misce/main';

import { Invoiceall_view } from './return/view/view';
import { Customer_sales_report } from './reports/sales_cus_wise/main';
import { Sales_statement_all_customer } from './reports/all_cus_sale/main';
import { Admin_menuComponent } from './component/admin_menu/main';
import { Vendor_Component } from './admin/vendor/main';
import { Payments_all } from './admin/payments/view/main';
import { Payment_edit_Component } from './admin/payments/edit/main';
import { PaymentComponent_gen } from './admin/payments/new/main';
import { RecieptComponent } from './admin/reciepts/new/main';
import { Reciepts_all } from './admin/reciepts/view/main';
import { Reciept_edit_Component } from './admin/reciepts/edit/main';
import { Company_main } from './admin/company/main/main';
import { Bill_estimate } from './bill/estimate/main';
import { Add_route }  from './admin/route/add/add_route';
import { Add_unit } from './admin/unit/main';
import { Expence_main_edit } from './expence/edit/edit';
import { Auditor_tax_statement } from './reports/auditor_tax/main';
import { Purchaseall_mise } from './purchase/misce/view/view';
import { Purchase_main_misce_edit } from './purchase/misce/edit/edit';
import { Estimates_all } from './bill/view/estimate/main';
import { Bill_estimate_edit } from './bill/estimate/edit/main';

const routes: Routes = [
    { path: '', component: HomeComponents },
    { path: 'login',component: LoginComponent },
    { path: 'dash', component: DashComponent,canActivate:[AuthGuard] },
    { path: 'New', component: Bill_b2b,canActivate:[AuthGuard] },
    { path: 'invoice-all-b2b', component: Invoiceall_b2b,canActivate:[AuthGuard && User_role]},
    { path: 'invoice-all-b2c', component: Invoiceall_b2c,canActivate:[AuthGuard && User_role]},
    { path: 'Quick', component: Bill_quick,canActivate:[AuthGuard] },    
    { path: 'add-product', component: Add_product, canActivate:[AuthGuard && User_role] },
    { path: 'update-product', component: Update_product, canActivate:[AuthGuard && User_role] },
    { path: 'bulk-update-product', component: BulkUpdate_product, canActivate:[AuthGuard && User_role] },
    { path: 'delete-product', component: Delete_product, canActivate:[AuthGuard && User_role] }, 
    { path: 'set-greeting', component: Greeting_Component, canActivate:[AuthGuard && User_role] },
    { path: 'manage-tax', component: Tax_manageComponent, canActivate:[AuthGuard && User_role] },
    { path: 'manage-customer', component: Customer_Component, canActivate:[AuthGuard && User_role] },
    { path: 'manage-vendor', component: Vendor_Component, canActivate:[AuthGuard && User_role] },
    { path: 'reports-main', component: MainReportComponent, canActivate:[AuthGuard && User_role] },
    { path: 'expense-main', component: Expence_main, canActivate:[AuthGuard && User_role] },
    { path: 'report_stock', component: ListStock, canActivate:[AuthGuard && User_role] },
    { path: 'report_auditor', component: Auditor_statement, canActivate:[AuthGuard && User_role]},
    { path: 'report_revenue', component: Revenue_report, canActivate:[AuthGuard && User_role]},
    { path: 'purchase_report', component: Purchase_report, canActivate:[AuthGuard && User_role]},
    { path: 'profit_report', component: Profit_report, canActivate:[AuthGuard && User_role]},
    { path: 'expense_report', component: Expense_report, canActivate:[AuthGuard && User_role] },
    { path: 'sales_report', component: SalesReport, canActivate:[AuthGuard && User_role] },
    { path: 'sales_report_cus', component: Customer_sales_report, canActivate:[AuthGuard && User_role] },    
    { path: 'customer_statement', component: Customer_statement, canActivate:[AuthGuard && User_role]},
    { path: 'manage-prefix', component: Admin_Invoice_string_mainComponent, canActivate:[AuthGuard && User_role]},  
    { path: 'back_up', component: BackupComponents, canActivate:[AuthGuard && User_role] },  
    { path: 'Return', component: Invoiceall_view, canActivate:[AuthGuard && User_role] },  
    { path: 'Cus-cr-deb', component: Customer_cr_deb_statement, canActivate:[AuthGuard && User_role]}, 
    { path: 'Purchase-main', component: Purchase_main, canActivate:[AuthGuard && User_role]},
    { path: 'Purchase-mainesl', component: Purchaseall_mise, canActivate:[AuthGuard && User_role]},
    { path: 'cash-on-bill', component: PaymentComponentsonbills, canActivate:[AuthGuard && User_role]},
    { path: 'purchase_all', component: Purchase_all, canActivate:[AuthGuard && User_role] }, 
    { path: 'purchase_details', component: Expence_main_details, canActivate:[AuthGuard && User_role] }, 
    { path: 'vendor_cr_deb', component: Vendor_cr_deb_statement, canActivate:[AuthGuard && User_role] },  
    { path: 'stock_report_detail', component: ListStock_details, canActivate:[AuthGuard && User_role] },
    { path: 'purchase_return', component: Purchase_ret_edit, canActivate:[AuthGuard && User_role] },
    { path: 'sales_report_all_cus', component: Sales_statement_all_customer, canActivate:[AuthGuard && User_role] },
    { path: 'Return_edit', component: ReturnComponent, canActivate:[AuthGuard && User_role] },  
    { path: 'admin_menu', component: Admin_menuComponent, canActivate:[AuthGuard && User_role] },
    { path: 'payments_all', component: Payments_all, canActivate:[AuthGuard && User_role] },
    { path: 'payments_details', component: Payment_edit_Component, canActivate:[AuthGuard && User_role] },
    { path: 'payments_general', component: PaymentComponent_gen, canActivate:[AuthGuard && User_role] },
    { path: 'reciepts_general', component: RecieptComponent, canActivate:[AuthGuard && User_role] },
    { path: 'reciepts_all', component: Reciepts_all, canActivate:[AuthGuard && User_role] },
    { path: 'reciept_details', component: Reciept_edit_Component, canActivate:[AuthGuard && User_role] },
    { path: 'manage-company', component: Company_main, canActivate:[AuthGuard && User_role] },
    { path: 'stock_detail_report', component: Stock_redReport, canActivate:[AuthGuard && User_role] },
    { path: 'add-route', component: Add_route, canActivate:[AuthGuard && User_role] },    
    { path: 'Newes', component: Bill_estimate,canActivate:[AuthGuard] },
    { path: 'add_unit', component: Add_unit, canActivate:[AuthGuard && User_role] },
    { path: 'expense-view', component: Expence_main_view, canActivate:[AuthGuard && User_role] },   
    { path: 'expense-view-edit', component: Expence_main_edit, canActivate:[AuthGuard && User_role] }, 
    { path: 'report_auditor_tax_rate', component: Auditor_tax_statement, canActivate:[AuthGuard && User_role] },
    { path: 'Purchase-maines', component: Purchase_main_misce, canActivate:[AuthGuard && User_role]},
    { path: 'Purchase-maines_edit', component: Purchase_main_misce_edit, canActivate:[AuthGuard && User_role]},
    { path: 'Newesl', component: Estimates_all,canActivate:[AuthGuard && User_role]},
    { path: 'Newesl_edit', component: Bill_estimate_edit,canActivate:[AuthGuard && User_role]},
    
    { path: 'bar_code',component: BarcodeComponent },
     
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }