

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { DataService } from '../../services/data.service';
import { UpdateService } from '../../services/update.service';
import { ReportService } from '../../services/report.service';

import { p_model } from './model';
@Component({
  selector: 'revenue-report',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Revenue_report implements OnInit {
 

   arrayOfKeyValues: any[] = [];
   isclicked : boolean= false;
   avil_qty : any;
   view : boolean = false;
   view2 : boolean = false;
   product_array : any [] = [];
   product_array_copy : any [] = [];
   cash_arry : any [] = [];
   to_date : string;
   from_date : string;
   cats : any [] = [];
  total_grand: number = 0;
  total_payed: number = 0;
  total_due: number = 0;
  view_cat : boolean = false;
  loop: number =0;
  total_grand_coll : number = 0;
  isClickedOnce : boolean = false;
  user : string = 'All';

        dateForm = this.fb.group({                  
                                                  
                                                  from_date : '',
                                                  to_date: '',
                                                  user:'All',
                                                  option : 2
                                                 
                                              
                                         });


  constructor(private fb: FormBuilder, private router: Router ,private Rs: ReportService,
   private ds: DataService , private us : UpdateService) {   }
  ngOnInit()  {
                   
                   this.get_users();
                     this.get_report();

              }

              get_users()
              {
                this.ds.get_cus_list()
                .subscribe((jsonData) => { this.get_json_cus(jsonData)
                               },(err) => console.error(err),
                               
                               );
              }  
              
              get_json_cus(json:any)
              {
                console.log('json',json);

                 
                      this.cats[0] = 'All'

                      for(var i = 0; i < json.length ; i++)
                      {

                        this.cats[i+1]= json[i].USER_NAME;
                      }
                    this.view_cat = true;

              }
  
get_report()
{
    this.from_date = this.Rs.from_date ;
    this.to_date = this.Rs.to_date;
    this.dateForm.patchValue({from_date:  this.from_date, to_date: this.to_date })

    
    this.ds.get_report_revenue(this.dateForm.value)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );


                
 } 

 change_cat(cat:any)
 {
   this.user = cat;
 }

 search_by_cats()
 {
  this.total_grand = 0;
  this.total_payed = 0;
  this.total_due = 0;
  
  if(this.user != 'All')
  {
   this.product_array =  this.product_array_copy.filter(xy=>xy.COUNTER === this.user);
   for(this.loop = 0; this.loop<this.product_array.length; this.loop++)
   {

    this.total_grand = (this.total_grand-0) + (this.product_array[this.loop].GRAND_TOTAL-0)
     this.total_payed = (this.total_payed-0) + (this.product_array[this.loop].TOTAL_PAYED-0)
     this.total_due = (this.total_due-0) + (this.product_array[this.loop].TOTAL_DUE-0)
   }
  }

  else
  {
    this.product_array =  this.product_array_copy
    for(this.loop = 0; this.loop<this.product_array.length; this.loop++)
    {
 
     this.total_grand = (this.total_grand-0) + (this.product_array[this.loop].GRAND_TOTAL-0)
      this.total_payed = (this.total_payed-0) + (this.product_array[this.loop].TOTAL_PAYED-0)
      this.total_due = (this.total_due-0) + (this.product_array[this.loop].TOTAL_DUE-0)
    }

  }

 }
  
 getjson_cash(jsons:any)
 {
  console.log('second',jsons);
  this.view2 = true;
  this.cash_arry = jsons;
  for(this.loop = 0; this.loop<this.cash_arry.length; this.loop++)
   {
    this.total_grand_coll = (this.total_grand_coll-0) + (this.cash_arry[this.loop].AMOUNT_PAYED-0)
   }

 }
      getjson(json :any)

  {
    console.log('first',json);
    this.view = true;
this.product_array_copy = json;

   for(this.loop = 0; this.loop<this.product_array_copy.length; this.loop++)
   {

    this.total_grand = (this.total_grand-0) + (this.product_array_copy[this.loop].GRAND_TOTAL-0)
     this.total_payed = (this.total_payed-0) + (this.product_array_copy[this.loop].TOTAL_PAYED-0)
     this.total_due = (this.total_due-0) + (this.product_array_copy[this.loop].TOTAL_DUE-0)
   }





   this.ds.get_cash_reci_revenue(this.dateForm.value)
   .subscribe((jsonData) => { this.getjson_cash(jsonData)
                  },(err) => console.error(err),
                  
                  );

   
  }


print_report()
{
  window.print();
}
 
   
  }

