
import { Component,OnInit  } from '@angular/core';

import {  UpdateService } from '../../../services/update.service';
import { DataService } from '../../../services/data.service';
import { SharingService } from '../../../services/sharing.service';
import { pdt_model } from './model';
import { Router } from '@angular/router';
@Component({
    selector: 'purchase-ret-edit',
    templateUrl: './main.html',
    styleUrls: ['./main.css'],
   
})
export class Purchase_ret_edit {

    
  view : boolean = false;
  mos : any = {"invo_string":''};


  arrayOfCusValues : any[] =[];
   arrayOfKeyValues: any[] = [];
 

  is_invo_num_loaded : boolean = false;
  dip_invo_number : number;
 invoice_number_b2b : number = 0;
 invoice_number_b2c : number = 0;
  isServer_res : boolean = false
 tap_flag : boolean = false;
 arrayOfvendorValues :  any [] =[];
  product_array : pdt_model[] =[];
  up_click_once : boolean = false

 j : number = 0
 d_ : number = 0
 z : number = -1;
 temp_alt : number[] = [];
  total_ret_sub : number =0;
 custoo : any
 post_model : any = {invo_string : "",is_b2b:false , invo_number: 0}
 fig_model : any = { number_to_convert : 0,fraction:0,msg : ''};
 fig_main : string = null

 constructor( private ds : DataService, private uS: UpdateService, private rs: Router, private ss: SharingService  )
 {
    
 }
  
  ngOnInit() {
   
    this.get_def_invo_string();

    this.get_vendors();

    this.get_products();

      this.get_def_company_dets();
   
       
  }






  get_def_company_dets()
  {
    this.ds.get_def_company_details().subscribe((jsonData) => { this._get_def_com_dets(jsonData)  
    },(err) => console.error(err),);
  }
  _get_def_com_dets(json:any)
  {
    
    
    this.ss.i_m.company_info[0].COMPANY_NAME = json.COMPANY_NAME;
    this.ss.i_m.company_info[0].COMPANY_ADR_1 = json.COMPANY_ADR_1;
    this.ss.i_m.company_info[0].COMPANY_ADR_2 = json.COMPANY_ADR_2;
    this.ss.i_m.company_info[0].COMPANY_ADR_2 = json.COMPANY_ADR_2;
    this.ss.i_m.company_info[0].COMPANY_GST = json.COMPANY_GST;
    this.ss.i_m.company_info[0].COMPANY_PH1 = json.COMPANY_PH1;
    this.ss.i_m.company_info[0].COMPANY_PH2 = json.COMPANY_PH2;
    this.ss.i_m.company_info[0].COMPANY_EMAIL = json.COMPANY_EMAIL;
    this.ss.i_m.company_info[0].COMPANY_BANK_NAME = json.COMPANY_BANK_NAME
    this.ss.i_m.company_info[0].COMPANY_BANK_AC_IFSC = json.COMPANY_BANK_AC_IFSC
    this.ss.i_m.company_info[0].COMPANY_BANK_AC_NO = json.COMPANY_BANK_AC_NO
    this.ss.i_m.company_info[0].COMPANY_BANK_BRANCH = json.COMPANY_BANK_BRANCH
  }


         get_vendors()
         {
          this.ds.get_vendors_all()
          .subscribe((jsonData) => { this._get_vendors(jsonData)
                  },(err) => console.error(err),
                    
                  );
         }

         _get_vendors(json:any)
         {

           console.log(json)
           this.arrayOfvendorValues = json
         
           // this.vends = json;
         }



cus_blur(p:any)
{
  
     let s : any; 

s = this.arrayOfvendorValues.filter(xi=> xi.CUSTOMER_NAME === p);



if(s.length != 0)
{

  console.log(s[0]);

 this.ss.i_m.heads[0].CUSTOMER_NAME = s[0].CUSTOMER_NAME;
 this.ss.i_m.heads[0].CUSTOMER_STREET = s[0].STREET;
 this.ss.i_m.heads[0].CUSTOMER_CITY  = s[0].CITY;
 this.ss.i_m.heads[0].CUSTOMER_PHONE = s[0].PHONE;
 this.ss.i_m.heads[0].CUSTOMER_PHONE2 = s[0].MOBILE;
 this.ss.i_m.heads[0].CUSTOMER_GST_IN = s[0].GSTIN;
 this.ss.i_m.heads[0].CUS_ID = s[0].ID;
}
else

{
   this.ss.i_m.heads[0].CUSTOMER_NAME  = p;
}

}
get_def_invo_string()

{

  this.ds.get_default_invoice_string()
                .subscribe((jsonData) => { this._get_invo_string(jsonData) 
                  },(err) => console.error(err));

}


  _get_invo_string(json:any)
              {
               
                this.ss.i_m.heads[0].INVOICE_STRING = json.STRING_NAME;
                this.post_model.invo_string = json.STRING_NAME;
              
              
                
                
                 this.get_purchase_return_no(this.post_model); 
                
              
              }



    get_purchase_return_no(invo_string:any)
              {
                 this.ds.get_purchase_return_no(invo_string).subscribe((jsonData) => { this._get_pur_ret_number(jsonData)  
                  },(err) => console.error(err),);
              }

_get_pur_ret_number(json:any)
{
    this.ss.i_m.heads[0].INVOICE_NUMBER = json.count + 1;
   this.invoice_number_b2c = json.count + 1;
 
   this.ss.i_m.flags[0].IS_INVO_NUMBER_LOADED = true
}



   catch_invoice_date(s:any)
{
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 2 ;
  this.ss.i_m.heads[0].BILL_DATE = s._validSelected.toLocaleDateString();
}

change_date()
{
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 0
}



item_remove_flag()
{
    if(this.ss.i_m.varibs[0].I === -1)
  {  return true;  }
  else
  {  return false;  }
}
focus_one(index:number)
{
  this.ss.i_m.varibs[0].K = index ;
}




    get_products()
            {
              this.ds.get_products_list()
              .subscribe((jsonData) => { this._get_products(jsonData)
                      },(err) => console.error(err),
                       
                      );
           }

      _get_products(json :any)
        {     

          
            this.arrayOfKeyValues = json;
            
            this.product_array = json;
            this.isServer_res = true;
            
      

         

            
       
         }






change_one(item_code:any)
{



  this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = item_code
 
  let si : any; 
  
  
  
si = this.product_array.filter(xi=> xi.DESCRIPTION  === item_code);

if(si.length > 0)
{
  
//  this.stock_check = si[0].AVAIL_QTY ;

this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = si[0].DESCRIPTION;
this.ss.i_m.items[this.ss.i_m.varibs[0].K].ID = si[0].ID;

this.ss.i_m.items[this.ss.i_m.varibs[0].K].CATEGORY = si[0].CATGERY;
this.ss.i_m.items[this.ss.i_m.varibs[0].K].HSN_CODE = si[0].HSN_CODE;

this.ss.i_m.items[this.ss.i_m.varibs[0].K].UNIT = si[0].UNIT;
this.ss.i_m.items[this.ss.i_m.varibs[0].K].TAX = si[0].TAX;


this.temp_alt[this.ss.i_m.varibs[0].K] = si[0].ALT_QTY;

this.ss.i_m.items[this.ss.i_m.varibs[0].K].ALT_UNIT = si[0].ALT_UNIT;



this.ss.i_m.items[this.ss.i_m.varibs[0].K].ALT_QTY = si[0].ALT_QTY;
this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE = si[0].PUR_PRICE;



this.calculation();

}
else
{
   // this.snak_fun('Enter valid Specification');
}




}


change_qty(qty:any)
{
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY = qty;

   this.ss.i_m.items[this.ss.i_m.varibs[0].K].ALT_QTY = this.temp_alt[this.ss.i_m.varibs[0].K] * this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY

   this.calculation();
}  


change_price(np:any)
{

  
    
   // 

     this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE = np

   //   this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE =  Math.round(this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE * 100) / 100;

    this.calculation();
}







addItem(inx:number)

{
      
  this.ss.i_m.varibs[0].I= this.ss.i_m.varibs[0].I+1;
      this.push_items(this.ss.i_m.varibs[0].I + 2);  
          
        
}

push_items( si : number )
    
           {
           
             if(si > this.ss.i_m.items.length)
             {
               
               this.ss.i_m.items.push({ SI_NO:si,ID:0,DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',PRICE_COPY:0,
               QTY:1,FREE_ITEM:0,TAX:0,CESS_AMT:0,MRP:0,BATCH_NO:'',EXPIRY_DATE:null,BAR_CODE:'',MASTER_ID:0,
     PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })

              }
              else
              {
                 this.reset_ss();
              }
            }

            reset_ss()
            {
             this.z = this.ss.i_m.items.length
              while (this.z > 1) 
              {
                 this.ss.i_m.items.pop();
                  this.z--
                  
              }
            this.ss.i_m.tails[0].SUB_TOTAL = 0;
            }

calculation()
{
   
     

    var xp =  this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE * this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY

     this.d_ = (  this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE  *  this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY ) * ((this.ss.i_m.items[this.ss.i_m.varibs[0].K].TAX )/100)




    this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE = (xp-0) + (this.d_-0) ;


    this.calculate_grand_total()
}

calculate_grand_total()
{


   this.total_ret_sub = 0


  this.ss.i_m.tails[0].GRAND_TOTAL = 0 

     for(var kp = 0; kp < this.ss.i_m.items.length; kp++)
     {

       this.total_ret_sub = this.total_ret_sub +  (this.ss.i_m.items[kp].PRICE *  this.ss.i_m.items[kp].QTY)
       this.ss.i_m.tails[0].GRAND_TOTAL = this.ss.i_m.tails[0].GRAND_TOTAL + this.ss.i_m.items[kp].NET_PRICE


     }

     this.get_figure(this.ss.i_m.tails[0].GRAND_TOTAL)
}




get_figure(x:any)
{
    
  this.fig_model.number_to_convert = x;
   this.ds.get_figure(this.fig_model)
   .subscribe((jsonData) => { this.getjson87(jsonData)
                      },(err) => console.error(err),
                       
                      );

}

getjson87 (p:any)
{
 
   this.fig_main = p.msg;
  
 
}  




   post_pur_ret()
{


   this.up_click_once = true

  console.log('this.ss.',this.ss.i_m)

       this.ds.post_a_pur_return(this.ss.i_m)
       .subscribe((jsonData) => { this.pur_ret_res(jsonData)
                      },(err) => console.error(err),
                      
                       );


     //     this.rs.navigate(['/purchase_return_entry']);


}

pur_ret_res(json:any)
{
  console.log('js',json);

  if(json.msg === 'Saved')
  {

  this.view = true

                window.print();

 
this.rs.navigate(['/dash']);


  
}   

   
}

 
  

   
}