import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '../../../services/auth.guard';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SharingService } from '../../../services/sharing.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-print-eighty',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class PrinteightyComponent  {
  
 b : number = 0;
 bc : number = 0;
 ngOnInit() {
 
 
  }
  constructor(private ag : AuthGuard,private _formBuilder: FormBuilder,
    private router: Router,private ss : SharingService,private ds:DataService ) { }
  
  





customer_statement()
{
  this.router.navigate(['/customer-statement']);
}


}