
export class items {
    constructor(
      
           public si : number ,
           public item : sublist[],
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  public SI : number,  
  public QUANTITY : number, 
  public TAX : number,
  public NET_PRICE : number,
  public NET_VALUE : number,
  public CGST : number,
  public SGST : number,
  public KFC : number,
  public PRICE :number


  
    ) {  }
  }
  