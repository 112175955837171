import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharingService } from '../../../services/sharing.service';
import { DataService } from '../../../services/data.service';
import { i_number } from './model';
import { custo } from './model';

@Component({
  selector: 'app-bill-top-b2b',
  templateUrl: './b2b.html',
  styleUrls: ['./b2b.css']
})
export class B2b_top_Component implements OnInit {

  
  arrayOfCusValues : any[] =[];
  invoice_number_gets = new i_number('',1);
  is_invo_num_loaded : boolean = false;
  dip_invo_number : number;
 invoice_number_b2b : number = 0;
 invoice_number_b2c : number = 0;
  

 tap_flag : boolean = false;

 j : number = 0
 d_ : number = 0
 cusstto = new custo('',0,'','',0,false,0,'',0,'','');
 custoo : any
 post_model : any = {invo_string : "",is_b2b:false , invo_number: 0}
 ss_date : any;

 cats : any [] = [];
 view_cat :  boolean = false;
 b2b_radio : any;
 b2c_radio : any;
 sez_radio : any;
 igst_radio : any;

 entry_id : number = 2;
 radioItems: Array<string>;
 model   = {option: 'option3'};
 cus_vend_model : any = null;
  
  
     constructor(private router: Router , private ss : SharingService , private ds : DataService) {
      this.radioItems = ['B2B', 'B2C', 'SEZ','IGST'];
      }
  

  ngOnInit() {

            
                

               if(this.ss.i_m.configs[0].IS_ON_UPDATE === true)
               {
                this.reset_ss()
                this.tap_flag = false;
                this.post_model.is_b2b =  this.ss.i_m.configs[0].IS_B2B
                this.post_model.invo_number =  this.ss.i_m.heads[0].INVOICE_NUMBER
                this.post_model.invo_string =   this.ss.i_m.heads[0].INVOICE_STRING

                this.ss.i_m.tails[0].SUB_TOTAL = 0;
                this.ss.i_m.tails[0].GRAND_TOTAL = 0;
     
                this.get_master_slave();
               }

               else
               {
                this.ss.i_m.configs[0].IS_B2B = false;
                this.get_def_invo_string();
                
               }
              
               
               this.get_customers();
               
              }

              get_def_invo_string()
              {
                this.ds.get_default_invoice_string()
                .subscribe((jsonData) => { this._get_invo_string(jsonData) 
                  },(err) => console.error(err));
              }
              
              _get_invo_string(json:any)
              {
               
                this.ss.i_m.heads[0].INVOICE_STRING = json.STRING_NAME;
                this.post_model.invo_string = json.STRING_NAME;
              
              
                
                
                 this.get_invo_number_com(this.post_model); 
                
                 this.post_model.is_b2b = true;
                
                // this.get_all_sales_routes()
              }


              get_invo_number_com(invo_string:any)
              {
                 this.ds.get_invo_number_comm_(invo_string).subscribe((jsonData) => { this._get_invo_number_b2c(jsonData)  
                  },(err) => console.error(err),);
              }
              
        

              _get_invo_number_b2c(json:any)
{
   this.ss.i_m.heads[0].INVOICE_NUMBER = json.count + 1;
   this.invoice_number_b2c = json.count + 1;
   this.invoice_number_b2b = json.count + 1;
   this.ss.i_m.flags[0].IS_INVO_NUMBER_LOADED = true
}



get_all_sales_routes()
{
  this.ds.get_all_sales_routes()
  .subscribe((jsonData) => { this.get_all_sales_routes_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
}
get_all_sales_routes_res(json:any)
{
  console.log('routes',json)

  this.cats[0] = 'All'

  for(var i = 0; i < json.length ; i++)
  {

    this.cats[i+1]= json[i].ROUTE_NAME;
  }
this.view_cat = true;



}

b22c(e:any)
{
  
  console.log('rb',e);
}

b22c_checked()
{
  // console.log('e',e);
  // console.log('rbc',this.b2c_radio);
}


change_cat(x:any)
{
   console.log('box', x );
   
    this.ss.i_m.heads[0].ROUTE_ID = x;
 

}


             
slide_change(change:any)
{
  
  this.ss.i_m.configs[0].IS_B2B = change.checked;
  if(change.checked === true)
  {
    this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2b;
  }
 else
  {

     this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2c;
  }


}

  get_customers()
{
   this.ds.get_customers_list_b2b()
  .subscribe((jsonData) => { this.get_customer_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
}
get_customer_res(json:any)
{ 
  this.arrayOfCusValues = json;
}
tap()
{
  this.tap_flag = true;
}
focus_cus()
{
  this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = false;
  this.ss.i_m.heads[0].CUS_ID = 0; 
  this.ss.i_m.configs[0].IS_B2B = false;
  this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2c
}
cus_blur(p:any)
{
  
  if(this.cus_vend_model.ID != undefined)
  {

 this.ss.i_m.heads[0].CUS_ID =  this.cus_vend_model.ID 
 this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = true;
 this.ss.i_m.heads[0].CUSTOMER_NAME =  this.cus_vend_model.CUSTOMER_NAME ;
 this.ss.i_m.heads[0].CUSTOMER_STREET = this.cus_vend_model.STREET;
 this.ss.i_m.heads[0].CUSTOMER_CITY  = this.cus_vend_model.CITY;
 this.ss.i_m.heads[0].CUSTOMER_PHONE = this.cus_vend_model.PHONE;
 this.ss.i_m.heads[0].CUSTOMER_PHONE2 = this.cus_vend_model.MOBILE;
 this.ss.i_m.heads[0].CUSTOMER_GST_IN = this.cus_vend_model.GSTIN;
 this.ss.i_m.heads[0].CUS_DUE = this.cus_vend_model.OPENING_BALANCE;
 this.ss.i_m.heads[0].CUS_TYPE = this.cus_vend_model.CUS_TYPE;



 if(this.ss.i_m.heads[0].CUS_TYPE === 'SEZ')
 {
   this.entry_id = 3
 }
 else if(this.ss.i_m.heads[0].CUS_TYPE === 'OUT_STATE')
 {
  this.entry_id = 4

 }
 else if(this.ss.i_m.heads[0].CUS_TYPE === 'NRML' && this.ss.i_m.heads[0].CUSTOMER_GST_IN != '' && this.ss.i_m.heads[0].CUSTOMER_GST_IN != null )
 {
  this.entry_id = 1

 }
 else
 {
  this.entry_id = 2
 }

 

if(this.ss.i_m.heads[0].CUSTOMER_GST_IN != '' && this.ss.i_m.heads[0].CUSTOMER_GST_IN != null )
{
  
 this.ss.i_m.configs[0].IS_B2B = true;
 this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2b
}
else
{
  
  
  this.ss.i_m.configs[0].IS_B2B = false;
  this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2c
}



}

else
{
  
this.ss.i_m.heads[0].CUS_ID = 0;  
this.ss.i_m.heads[0].CUSTOMER_NAME  = p;
this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = false;
this.ss.i_m.heads[0].CUS_TYPE = 'NRML';

}



}


get_customer_bal_res(json:any)
{
  
}
clear_bill()
{

  var cls = this.ss.i_m.items.length;

  for(var gj=1; gj<cls;gj++)
  {
    this.ss.i_m.items.pop();

  }

  this.ss.i_m.varibs[0].I = -1
  this.ss.i_m.varibs[0].K = 0

  

}




   catch_invoice_date(s:any)
{
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 2 ;
  this.ss.i_m.heads[0].BILL_DATE = s._selected.toLocaleDateString();

  console.log('ssdate',s);

 // this.ss.i_m.heads[0].BILL_DATE = this.ss_date.jsdate.toLocaleDateString();
}


 



change_date()
{
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 0
}




reset_ss()
{
 var z = this.ss.i_m.items.length
  while (z > 0) 
  {
     this.ss.i_m.items.pop();
      z--
      
  }
this.ss.i_m.tails[0].SUB_TOTAL = 0;
} 



get_master_slave()
{
  this.ds.get_a_invoice(this.post_model)
  .subscribe((jsonData) => { this._get_a_invoice(jsonData) 
    },(err) => console.error(err));
} 

_get_a_invoice(data : any)
{

 
this.make_master(data.master)
this.make_slave(data.slave)


}


make_master(data)
{

console.log('data',data);

  this.ss.i_m.heads[0].BILL_DATE = data.BILL_DATE
  this.ss.i_m.heads[0].BUNDLES = data.BUNDLES
  this.ss.i_m.heads[0].CUSTOMER_CITY = data.CUSTOMER_CITY
  this.ss.i_m.heads[0].CUSTOMER_GST_IN = data.CUSTOMER_GST_IN
  this.ss.i_m.heads[0].CUSTOMER_NAME = data.CUSTOMER_NAME
  this.ss.i_m.heads[0].CUS_TYPE = data.BILL_TYPE
  this.ss.i_m.heads[0].CUSTOMER_PHONE = data.CUSTOMER_PHONE
  this.ss.i_m.heads[0].CUSTOMER_PHONE2 = data.CUSTOMER_MOBILE
  this.ss.i_m.heads[0].CUSTOMER_STREET = data.CUSTOMER_STREET
  this.ss.i_m.heads[0].LPO = data.LPO
  this.ss.i_m.heads[0].DN = data.DN
  this.ss.i_m.heads[0].TRANS_MODE = data.LPO_ORG
  this.ss.i_m.heads[0].VEH_NO = data.VEH_NO
  this.ss.i_m.heads[0].INVOICE_NUMBER = data.INVOICE_NUMBER
  this.ss.i_m.heads[0].CUSTOMER_PAN = data.PAN_NO
  this.ss.i_m.heads[0].USER_LOG = data.COUNTER
  this.ss.i_m.tails[0].GRAND_TOTAL = data.GRAND_TOTAL
  this.ss.i_m.tails[0].OLD_GRAND_TOTAL = data.GRAND_TOTAL;

  this.ss.i_m.tails[0].DISC_PERCENT = data.DISCOUNT_AMT;
  this.ds.item_count = data.ITEM_LENGTH;

  this.ss.i_m.tails[0].GRAND_TOTAL_DIFF = 0;
  this.ss.i_m.tails[0].TOTAL_TAX = data.TAX_COLLECTED
  this.ss.i_m.tails[0].SUB_TOTAL = data.SUB_TOTAL
  this.ss.i_m.tails[0].TOTAL_CESS = data.CESS_COLLECTED
  this.ss.i_m.tails[0].TOTAL_DUE = data.TOTAL_DUE
  this.ss.i_m.tails[0].OLD_DUE = data.TOTAL_DUE
  this.ss.i_m.heads[0].BILL_DUE = data.TOTAL_DUE
  this.ss.i_m.tails[0].TOTAL_PRE_PAID = data.TOTAL_PAYED
  this.ss.i_m.heads[0].CUS_ID = data.CUS_ID
  this.ss.i_m.tails[0].TOTAL_PAID_TODAY = 0
 
  this.ss.i_m.configs[0].IS_PARTIAL_PAY = data.IS_PARTIAL_PAY
  this.ss.i_m.configs[0].ITEM_LENGTH = data.ITEM_LENGTH;
  this.ss.i_m.configs[0].ITEM_OLD_LENGTH = data.ITEM_LENGTH;
  this.ss.i_m.configs[0].IS_ALREADY_RET = data.IS_RET;
  this.ss.i_m.varibs[0].I = data.ITEM_LENGTH -  2;
  this.ss.i_m.varibs[0].K = data.ITEM_LENGTH -  2;
  this.ss.i_m.flags[0].IS_INVO_NUMBER_LOADED = true;
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 2 ;
  this.ss.i_m.flags[0].CHANGE_OVER_FLAG = true;

  if(data.CUS_ID > 0)
  {
    this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = true;
  }


 
}

make_slave(data)
{
  this.push_ss_edit(data.length);
  this.push_ss_old_edit(data.length);
  for(var i=0;i<data.length;i++)
  {

    this.ss.i_m.items[i].DESCRIPTION = data[i].PRODUCT_DESCRIPTION  
    
    this.ss.i_m.old_items[i].ID = data[i].PRODUCT_ID;
    this.ss.i_m.old_items[i].SI_NO = data[i].SI_NO;
    this.ss.i_m.old_items[i].DESCRIPTION = data[i].PRODUCT_DESCRIPTION 
    this.ss.i_m.old_items[i].QTY = data[i].QUANTITY;
    this.ss.i_m.old_items[i].FREE_ITEM = data[i].FREE_QTY;

     this.ss.i_m.items[i].CATEGORY = data[i].CATGERY
    this.ss.i_m.items[i].HSN_CODE = data[i].HSN_CODE
 
   
   // this.ss.i_m.items[i].PRICE = data[i].PRICE
    this.ss.i_m.items[i].BATCH_NO = data[i].BATCH_NO
    this.ss.i_m.items[i].EXPIRY_DATE = data[i].EXPIRY_DATE

    this.ss.i_m.old_items[i].BATCH_NO = data[i].BATCH_NO
    this.ss.i_m.old_items[i].EXP_DATE = data[i].EXPIRY_DATE


    //this.ss.i_m.items[i].PRICE = data[i].PRICE
    this.ss.i_m.items[i].QTY = data[i].QUANTITY
    this.ss.i_m.items[i].FREE_ITEM = data[i].FREE_QTY
    this.ss.i_m.items[i].SI_NO = data[i].SI_NO
    this.ss.i_m.items[i].UNIT = data[i].UNIT
    this.ss.i_m.items[i].TAX = data[i].TAX
    this.ss.i_m.items[i].NET_PRICE = data[i].NET_PRICE

      this.d_ = this.ss.i_m.items[i].NET_PRICE * 100 / (100 + this.ss.i_m.items[i].TAX);

    this.ss.i_m.items[i].PRICE = this.d_


    // this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE =  Math.round(this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE * 100) / 100;

     this.ss.i_m.items[i].PRICE = Math.round(this.ss.i_m.items[i].PRICE * 100) / 100;

    console.log('price', this.ss.i_m.items[i].PRICE)


  }  

  this.ss.i_m.flags[0].IS_SERVER_RES = true

  for(this.j=0;this.j<this.ss.i_m.items.length;this.j++)
  {
      

        this.ss.i_m.items[this.j].RATE_SUM = this.ss.i_m.items[this.j].PRICE * this.ss.i_m.items[this.j].QTY
  
        this.ss.i_m.items[this.j].ITEM_SUM = this.ss.i_m.items[this.j].NET_PRICE * this.ss.i_m.items[this.j].QTY


    


  }

  
}

push_ss_edit(len)
{
  for(var i=0;i<len;i++)
        {          
        this.ss.i_m.items.push({ SI_NO:1,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,MRP:0,BATCH_NO:null,EXPIRY_DATE:null,
 PRICE:0,UNIT:'pc',ALT_QTY:0,FREE_ITEM:0,PRICE_COPY:0,BAR_CODE:'',MASTER_ID:0,
 ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })  
        }
}

push_ss_old_edit(len)

{
  for(var i=0;i<len;i++)
        {    

          this.ss.i_m.old_items.push({SI_NO:0,ID:0,DESCRIPTION:'',ALT_QTY:0,ALT_UNIT:'',QTY:0,BATCH_NO:null,EXP_DATE:null,FREE_ITEM:0})

        }

}



}
