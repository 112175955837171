

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { DataService } from '../../services/data.service';
import { UpdateService } from '../../services/update.service';
import { ReportService } from '../../services/report.service';

import { p_model } from './model';
import { revenue_invo_model } from './model';
import { purchase_invo_model } from './model';
import  { exp_invo_model } from './model';




@Component({
  selector: 'profit-report',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Profit_report implements OnInit {
 

   arrayOfKeyValues: any[] = [];
   isclicked : boolean= false;
   avil_qty : any;
   view : boolean = false;
   view1 : boolean = false;
   view2 : boolean = false;
   to_tax_sales  : number = 0;
   to_amt_sales : number = 0;
   to_amt_pur : number = 0;
   to_amt_other :number = 0;

   product_array : any [] = [];
   to_date : string;
   from_date : string;
 
   revenue_invo = new revenue_invo_model(1,[]);

   purchase_invo = new purchase_invo_model(1,[]);

   exp_other_invo = new exp_invo_model(1,[]);
  
  total_grand: number = 0;
  total_payed: number = 0;
  total_due: number = 0;
  loop: number =0;

        dateForm = this.fb.group({                  
                                                  
                                                  from_date : '',
                                                  to_date: '',
                                                  option : 2
                                                 
                                              
                                         });


  constructor(private fb: FormBuilder, private router: Router ,private Rs: ReportService,
   private ds: DataService , private us : UpdateService) {   }
  ngOnInit()  {
                   
     
                     this.get_report_revenue();

              }
  
get_report_revenue()
{
    this.from_date = this.Rs.from_date ;
    this.to_date = this.Rs.to_date;
    this.dateForm.patchValue({from_date:  this.from_date, to_date: this.to_date })

    
    this.ds.get_report_revenue(this.dateForm.value)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );



       this.ds.get_report_purchase(this.dateForm.value)
       .subscribe((jsonData) => { this.getjson_purchase(jsonData)
                      },(err) => console.error(err),
                      
                      );



       this.ds.get_report_other_expense(this.dateForm.value)
       .subscribe((jsonData) => { this.getjson_expense(jsonData)
                      },(err) => console.error(err),
                      
                      );




       

 } 
  

      getjson(json :any)

  {
    console.log('revenue',json);

      for(var i = 0; i < json.length ; i++)
   {
     this.push_REVENUE_rEP();
     this.revenue_invo.item[i].SI = i+1;
     this.revenue_invo.item[i].BILL_DATE = json[i].BILL_DATE
     this.revenue_invo.item[i].INVOICE_NUMBER = json[i].INVOICE_NUMBER
     this.revenue_invo.item[i].CUSTOMER_NAME = json[i].CUSTOMER_NAME
     this.revenue_invo.item[i].TOTAL_TAX = json[i].TAX_COLLECTED
     this.revenue_invo.item[i].GRAND_TOTAL = json[i].GRAND_TOTAL

   }

 

   for(this.loop = 0; this.loop<json.length; this.loop++)
   {

      this.to_tax_sales = (this.to_tax_sales-0) + (this.revenue_invo.item[this.loop].TOTAL_TAX-0)
      
      this.to_amt_sales =  (this.to_amt_sales-0) + (this.revenue_invo.item[this.loop].GRAND_TOTAL-0)
   }



this.view = true;


   
  }





getjson_purchase(json:any)
{
   console.log('purchase',json);


        for(var i = 0; i < json.length ; i++)
   {
     this.push_purchase_rEP();
     this.purchase_invo.item[i].SI = i+1;
     this.purchase_invo.item[i].EXPENCE_ENTRY_NO = json[i].EXPENCE_ENTRY_NO
     this.purchase_invo.item[i].EXPENCE_CATEGORY =  json[i].EXPENCE_CATEGORY

     this.purchase_invo.item[i].EXP_DATE = json[i].EXP_DATE
      this.purchase_invo.item[i].VENDOR = json[i].VENDOR
      this.purchase_invo.item[i].BILL_NO = json[i].BILL_NO
      this.purchase_invo.item[i].TOTAL_AMOUNT = json[i].TOTAL_AMOUNT

   }

      for(this.loop = 0; this.loop<json.length; this.loop++)
   {

      this.to_amt_pur = (this.to_amt_pur-0) + (this.purchase_invo.item[this.loop].TOTAL_AMOUNT-0)
      
      
   }






   this.view1 =true


}


getjson_expense(json:any)
{
  console.log('expense',json);

   for(var i = 0; i < json.length ; i++)
   {
     this.push_exp_rEP();
      this.exp_other_invo.item[i].SI = i+1;
      this.exp_other_invo.item[i].EXPENCE_ENTRY_NO = json[i].EXPENCE_ENTRY_NO
      this.exp_other_invo.item[i].EXPENCE_CATEGORY = json[i].EXPENCE_CATEGORY
      this.exp_other_invo.item[i].EXP_DATE = json[i].EXP_DATE
      this.exp_other_invo.item[i].VENDOR = json[i].VENDOR
      this.exp_other_invo.item[i].BILL_NO = json[i].BILL_NO
      this.exp_other_invo.item[i].TOTAL_AMOUNT =  json[i].TOTAL_AMOUNT

   }  
   
         for(this.loop = 0; this.loop<json.length; this.loop++)
   {

      this.to_amt_other = (this.to_amt_other-0) + (this.exp_other_invo.item[this.loop].TOTAL_AMOUNT-0)
      
      
   }



this.view2 =true


}


push_exp_rEP()
{
  this.exp_other_invo.item.push({SI: 0,EXPENCE_ENTRY_NO:1,EXP_DATE:'',EXPENCE_CATEGORY:'',VENDOR:'',BILL_NO:'',TOTAL_AMOUNT:0})

}

push_purchase_rEP()
{
  this.purchase_invo.item.push({SI:0,EXPENCE_ENTRY_NO:0,EXP_DATE:'',EXPENCE_CATEGORY:'',VENDOR:'',BILL_NO:'',TOTAL_AMOUNT:0})
}

push_REVENUE_rEP()
{
  this.revenue_invo.item.push({SI:0,BILL_DATE:'',INVOICE_NUMBER:0,CUSTOMER_NAME:'',GRAND_TOTAL:0,TOTAL_TAX:0})
}



print_report()
{
  window.print();
}
 
   
  }

