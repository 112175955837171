import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-Vendor-manage',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Vendor_Component implements OnInit {


 
  customerForm: FormGroup;
  customer_u_Form: FormGroup;
  customer_d_Form: FormGroup;
  arrayOfCusValues : any[] =[];
  arrayofVendorValues : any[] = [];
  id : number = 0;
  cus_vend_model : any = null;
 
  constructor( private fb: FormBuilder, private ds :DataService, private router : Router ) { } 

  ngOnInit() {
  
   
  this.get_vendors();
    this.customerForm = this.fb.group({ 
      vendor_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      street : '',
      city : '',
      gst :'',
      ph_no:'',
      ph_no2:'',
      credit_bal : 0,
      last_invo_num:0,
      is_vendor : true     
    });

 


   

      this.customer_u_Form = this.fb.group({ 

      vendor_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      is_vendor_update : true,
      street : '',
      city : '',
      gstin :'',
      ph1:'',
      ph2:'',
      opbal:0,
      credit_bal : 0,
      last_invo_num:0,
      is_vendor : true ,
      id : 0
           
    });





      this.customer_d_Form = this.fb.group({ 

      vendor_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      street : '',
      city : '',
      gstin :'',
      ph1 :'',
      ph2 : '',
      opbal : '',
      last_invo_num : '',
      is_vendor : true ,
      id : 0
      
    });


document.getElementById("defaultOpen").click();
     
    
  }







  add_vendor()
  {
   
    this.ds.add_customer(this.customerForm.value)
     .subscribe((jsonData) => { this.res_(jsonData)  
                      },(err) => console.error(err)
                       
                      );

  }
res_(json:any)
{
  console.log('msg',json)
alert(json.msg);
this.router.navigate(['/dash']);
}









vendor_blur_update()
{
  if(this.cus_vend_model.ID != undefined)
  {
   this.customer_u_Form.patchValue({customer_name : this.cus_vend_model.CUSTOMER_NAME ,
     street : this.cus_vend_model.STREET ,  city :  this.cus_vend_model.CITY , gstin :this.cus_vend_model.GSTIN }) ;

 this.customer_u_Form.patchValue({ ph1 : this.cus_vend_model.PHONE , ph2 : this.cus_vend_model.MOBILE,
   opbal : this.cus_vend_model.CREDIT_BALANCE , cus_type: this.cus_vend_model.CUS_TYPE , id :  this.cus_vend_model.ID  }) ;

  }
else
  {
    alert('Select proper Input');
  }


}





get_vendors()
{
  
  this.ds.get_vendors_all()
  .subscribe((jsonData) => { this.json_vendors(jsonData)
                      },(err) => console.error(err),
                    
                      );
}
json_vendors(json :any)
  {
    console.log(json);
  this.arrayofVendorValues = json;
   
  
   
  }








  update_vendor()
  {
        this.ds.update_vendor(this.customer_u_Form.value)
     .subscribe((jsonData) => { this._res_up(jsonData)  
                      },(err) => console.error(err),
                       
                      );
  }





_res_up(json:any)
{
  alert(json.msg)
  this.router.navigate(['/dash']);
}




delete_vendor()

{

   this.ds.delete_vendor(this.customer_u_Form.value)
     .subscribe((jsonData) => { this._res_del(jsonData)  
                      },(err) => console.error(err),
                       
                      );

}

_res_del(json:any)
{
  alert(json.msg)
  this.router.navigate(['/dash']);
}


 openCity(evt, cityName) {
   
  this.id = 0;

  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}




   
}