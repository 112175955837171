





import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { exp_invo } from './model';
import { SharingService } from '../../../services/sharing.service';
import { pdt_array } from './model';
import { vendor_model } from './model';


@Component({
  selector: 'app-expence-main-det',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Expence_main_details implements OnInit {

  catForm: FormGroup;
  customer_u_Form: FormGroup;
  customer_d_Form: FormGroup;
  arrayOfCusValues : any[] =[];
  is_rec_no : number = 1;
  exp_entry_no : number = 1;
  from_date : string;
  is_itemss : number = 1;
  purchases : any [] = [];
  isClickedOnce : boolean = false;
  total_tax_per :number = 0;
  exp_invos = new exp_invo(1,'Purchase',0,0,[],[],'','',0,0,0,0,0,'','','',0,0);
  arrayOfKeyValues :  any [] =[];
   k : number = -1;
   i : number = 0;
   up_click_once : boolean = false;
   product_array : pdt_array[] =[];
  
   arrayOfvendorValues :  any [] =[];
   j : number = 0 ;
   grand_total : number = 0;
   cats :any[] = [];
   vendsi : any = {CUSTOMER_NAME:'',STREET:'',GSTIN:''}
   venni : any[] = [{DESCRIPTION: "kkkk",NET_PRICE:0, PRICE:0 , TAX: 0 , UNIT: 'pc'}]

   vends : vendor_model[] = null
   post_model : any = {eeno : 0};
   net_amttt : number = 0;
   date_flag : number = 0;
   arr_ : number = 0;
  constructor( private fb: FormBuilder, private ds :DataService, private router : Router ,private ss: SharingService ) { } 

  ngOnInit() {
  
    
    this.get_products();
     this.update_records();
    
  
    this.get_vendors();

    this.date_flag = 2 ;
    
    this.catForm = this.fb.group({ 

      Category_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      
      
    });



      this.customer_d_Form = this.fb.group({ 

      customer_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      street : '',
      city : '',
      gstin :'',
      ph1 :'',
      ph2 : '',
      opbal : '',
      last_invo_num : ''
      
    });


   


    
  }

fix_tax_values_on_update(count:any)

    {


         for(this.j=0;this.j<count;this.j++)
      {
 
     this.total_tax_per = (this.exp_invos.ITEMS[this.j].IGST_P-0) + (this.exp_invos.ITEMS[this.j].CGST_P-0) + (this.exp_invos.ITEMS[this.j].SGST_P-0)
     this.net_amttt =   this.exp_invos.ITEMS[this.j].PRICE * this.exp_invos.ITEMS[this.j].QTY 
     this.exp_invos.ITEMS[this.j].IGST_AMT =   ((this.net_amttt) * (this.exp_invos.ITEMS[this.j].IGST_P-0) ) / 100
     this.exp_invos.ITEMS[this.j].SGST_AMT =  ((this.net_amttt) * (this.exp_invos.ITEMS[this.j].SGST_P-0) ) / 100
     this.exp_invos.ITEMS[this.j].CGST_AMT =  ((this.net_amttt) * (this.exp_invos.ITEMS[this.j].CGST_P-0) ) / 100
     this.exp_invos.ITEMS[this.j].NET_PRICE = this.net_amttt + ((this.net_amttt) * this.total_tax_per ) / 100
       }
    }


calculation()
{
   
  this.exp_invos.GRAND_TOTAL = 0; 
  this.exp_invos.CGST = 0;
  this.exp_invos.IGST = 0;
  this.exp_invos.SGST = 0;


  for(this.j=0;this.j<this.exp_invos.ITEMS.length;this.j++)
 {

   this.exp_invos.GRAND_TOTAL  = (this.exp_invos.GRAND_TOTAL-0)  + (this.exp_invos.ITEMS[this.j].NET_PRICE-0) 
   this.exp_invos.CGST =  (this.exp_invos.CGST-0) +  (this.exp_invos.ITEMS[this.j].CGST_AMT-0) 
   this.exp_invos.SGST =  (this.exp_invos.SGST-0) +  (this.exp_invos.ITEMS[this.j].SGST_AMT-0) 
   this.exp_invos.IGST =  (this.exp_invos.IGST-0) +  (this.exp_invos.ITEMS[this.j].IGST_AMT-0) 


 }

}


Update_purchase()
{


  this.up_click_once = true
  this.calculation();


  this.cal_net_price();

  console.log('exp',this.exp_invos)

   this.ds.update_purchase(this.exp_invos)
      .subscribe((jsonData) => { this.getjsonres_2(jsonData)
                      },(err) => console.error(err)
                     
                      );
}


// getjsonres(json:any)
// {

//   if(json.success === true)
//   {
   


//      this.ds.update_purchase_part2(this.exp_invos)
//        .subscribe((jsonData) => { this.getjsonres_2(jsonData)
//                        },(err) => console.error(err)
                     
//                        );
    
//   }

//   else
//   {
//     alert('error')
//   }


// }

getjsonres_2(json:any)
{
  if(json.success === true)
  {
    alert('Updated')
 
    this.router.navigate(['/dash']);
  }

  else
  {
    alert('error')
  }


}



 change_igst_p(igst:any,k:any)
  {
    
    this.exp_invos.ITEMS[k].IGST_P = igst

    this.cal_net_price();
  }
  
  change_cgst_p(cgst:any,k:any)
  {
    
    this.exp_invos.ITEMS[k].CGST_P = cgst
    this.cal_net_price();
  }
  change_sgst_p(sgst:any,k:any)
  {
     this.exp_invos.ITEMS[k].SGST_P = sgst
    this.cal_net_price();

  }




change_date()
{
  this.date_flag = 1 ;
}




 
update_records()
        {     
          
            this.exp_invos.E_ENTRY_NUMBER = this.ss.i_m.heads[0].INVOICE_NUMBER;
              this.post_model.eeno = this.exp_invos.E_ENTRY_NUMBER;

              this.ds.get_expence_master_details(this.post_model)
                .subscribe((jsonData) => { this.get_master_dets(jsonData)
                      },(err) => console.error(err)
                     
                      );



           
            this.exp_invos.VENDOR = this.ss.i_m.heads[0].CUSTOMER_NAME
            this.vendsi.CUSTOMER_NAME = this.ss.i_m.heads[0].CUSTOMER_NAME
         


         this.exp_invos.IGST = this.ss.i_m.tails[0].TOTAL_PRE_PAID ;
         this.exp_invos.CGST = this.ss.i_m.tails[0].TOTAL_PAID_TODAY;
         this.exp_invos.SGST = this.ss.i_m.tails[0].TOTAL_DUE;

         
            


            this.is_rec_no = 1;
            this.post_model.eeno =   this.exp_invos.E_ENTRY_NUMBER ;
            
              this.ds.get_expence_details(this.post_model)
                .subscribe((jsonData) => { this.get_dets(jsonData)
                      },(err) => console.error(err)
                     
                      );
        
       
         }


get_master_dets(json:any)
{


   this.exp_invos.GRAND_TOTAL = json.TOTAL_AMOUNT
   this.exp_invos.OLD_GRAND_TOTAL = json.TOTAL_AMOUNT
   this.exp_invos.BILL_NO  = json.BILL_NO
   this.exp_invos.GST_IN = json.CUS_GST
   this.exp_invos.DATE = json.EXP_DATE
   this.exp_invos.STREET = json.STREET
   this.exp_invos.VENDOR = json.VENDOR  
   this.exp_invos.round_off = json.ROUND_OFF
   this.vendsi.VENDOR = json.VENDOR  
   this.vendsi.STREET =  json.STREET
   this.vendsi.GSTIN = json.CUS_GST
   

}


get_dets(json:any)
{
  
 this.exp_invos.ITEM_OLD_LENGTH = json.length



for(this.k = 0 ; this.k < json.length; this.k++)

{

  this.addItem();
  this.add_old_item_arrr();


this.exp_invos.ITEMS[this.k].CATEGORY = json[this.k].CATEGORY;
this.exp_invos.ITEMS[this.k].QTY = json[this.k].QUANTITY;
this.exp_invos.ITEMS[this.k].FREE_QTY = json[this.k].FREE_QTY;
this.exp_invos.OLD_ITEMS[this.k].QTY = json[this.k].QUANTITY;
this.exp_invos.OLD_ITEMS[this.k].FREE_QTY = json[this.k].FREE_QTY;

this.exp_invos.ITEMS[this.k].PRICE = json[this.k].PRICE;
this.exp_invos.ITEMS[this.k].NET_PRICE = json[this.k].NET_TOTAL;
this.exp_invos.ITEMS[this.k].DESCRIPTION = json[this.k].DESCRI_NAME;
this.exp_invos.OLD_ITEMS[this.k].DESCRIPTION = json[this.k].DESCRI_NAME;
this.exp_invos.ITEMS[this.k].PRO_ID_NEW = json[this.k].PRO_ID;
this.exp_invos.ITEMS[this.k].UNIT = json[this.k].UNIT;
this.exp_invos.OLD_ITEMS[this.k].PRO_ID = json[this.k].PRO_ID;
this.exp_invos.ITEMS[this.k].IGST_P = json[this.k].IGST_P;
this.exp_invos.ITEMS[this.k].CGST_P = json[this.k].CGST_P;
this.exp_invos.ITEMS[this.k].SGST_P = json[this.k].SGST_P;
this.exp_invos.ITEMS[this.k].BATCH_NO = json[this.k].BATCH_NO;
this.exp_invos.ITEMS[this.k].EXP_DATE = json[this.k].EXPIRY_DATE;

this.exp_invos.OLD_ITEMS[this.k].BATCH_NO = json[this.k].BATCH_NO;





}
this.k = 0
this.cal_net_price();

 this.fix_tax_values_on_update(json.length)

}



item_arrange(index: number)
 {

   console.log('index',index);
   console.log('i',this.i);
   console.log('k',this.k);

    
    for(this.arr_ = index ; this.arr_ < this.i-1 ; this.arr_ ++)
    {
      // this.exp_invos.ITEMS[this.arr_].CATEGORY = this.exp_invos.ITEMS[this.arr_+1].CATEGORY
      this.exp_invos.ITEMS[this.arr_].QTY = this.exp_invos.ITEMS[this.arr_+1].QTY
      this.exp_invos.ITEMS[this.arr_].PRICE = this.exp_invos.ITEMS[this.arr_+1].PRICE
      this.exp_invos.ITEMS[this.arr_].NET_PRICE = this.exp_invos.ITEMS[this.arr_+1].NET_PRICE
      this.exp_invos.ITEMS[this.arr_].DESCRIPTION = this.exp_invos.ITEMS[this.arr_+1].DESCRIPTION
      this.exp_invos.ITEMS[this.arr_].PRO_ID_NEW  = this.exp_invos.ITEMS[this.arr_+1].PRO_ID_NEW
      this.exp_invos.ITEMS[this.arr_].UNIT  = this.exp_invos.ITEMS[this.arr_+1].UNIT

      this.exp_invos.ITEMS[this.arr_].IGST_P = this.exp_invos.ITEMS[this.arr_+1].IGST_P
      this.exp_invos.ITEMS[this.arr_].CGST_P  = this.exp_invos.ITEMS[this.arr_+1].CGST_P
      this.exp_invos.ITEMS[this.arr_].SGST_P  = this.exp_invos.ITEMS[this.arr_+1].SGST_P



    

    }


 }




   get_products()
            {
              this.ds.get_products_list()
              .subscribe((jsonData) => { this._get_products(jsonData)
                      },(err) => console.error(err),
                        
                      );
           }

      _get_products(json :any)
        {     
            this.arrayOfKeyValues = json;
            this.product_array = json;
            
         
       
         }

         get_vendors()
         {
          this.ds.get_vendors_all()
          .subscribe((jsonData) => { this._get_vendors(jsonData)
                  },(err) => console.error(err),
                    
                  );
         }

         _get_vendors(json:any)
         {
           
           this.arrayOfvendorValues = json
         
           this.vends = json;
         }

addItem()
{
  this.i = this.i + 1;

  this.exp_invos.ITEMS.push({SI_NO:1,DESCRIPTION:'', CATEGORY:'',QTY:1,UNIT:'',PRICE:0,PRO_ID_NEW:0,BATCH_NO:null,EXP_DATE:null,
    NET_PRICE:0,IGST_P:0,IGST_AMT:0,SGST_P:0,SGST_AMT:0,CGST_P:0,CGST_AMT:0,FREE_QTY:0})

  

}
add_old_item_arrr()
{
  this.exp_invos.OLD_ITEMS.push({SI_NO:1,DESCRIPTION:'', QTY:1,PRO_ID:0,BATCH_NO:null,FREE_QTY:0})
}
 




  

vendor_blur(vn:any)
{

this.exp_invos.VENDOR = vn;

this.exp_invos.STREET = this.vendsi.STREET
this.exp_invos.GST_IN = this.vendsi.GSTIN

}








focus_one(index:number)
{
  this.k = index ;
  this.exp_invos.ITEMS[this.k].SI_NO = index + 1;
}

  change_one(item_name:any)
{


    let s : any; 
 s = this.product_array.filter(xi=> xi.DESCRIPTION === item_name);

if(s.length > 0)
 {
   

  this.exp_invos.ITEMS[this.k].CATEGORY = s[0].CATGERY;
 this.exp_invos.ITEMS[this.k].UNIT = s[0].UNIT;
 this.exp_invos.ITEMS[this.k].CGST_P = s[0].TAX / 2 ; 
 this.exp_invos.ITEMS[this.k].SGST_P = s[0].TAX / 2 ; 
  this.exp_invos.ITEMS[this.k].IGST_P = 0 ;
  this.exp_invos.ITEMS[this.k].PRO_ID_NEW = s[0].ID;
   this.exp_invos.ITEMS[this.k].DESCRIPTION = s[0].DESCRIPTION;


  }

   
 else

 {
   alert('Wrong product Name')
 }    
   
       
 }

  



 change_qty(qty:any)
 {
   this.exp_invos.ITEMS[this.k].QTY = qty
   
        this.cal_net_price();
   
 }

 change_price(price:any)
 {

   this.exp_invos.ITEMS[this.k].PRICE = price;

  
   this.cal_net_price();
    
 }








cal_net_price()
{




  this.total_tax_per = (this.exp_invos.ITEMS[this.k].IGST_P-0) + (this.exp_invos.ITEMS[this.k].CGST_P-0) + (this.exp_invos.ITEMS[this.k].SGST_P-0)


   
   this.net_amttt =   this.exp_invos.ITEMS[this.k].PRICE * this.exp_invos.ITEMS[this.k].QTY 

   this.exp_invos.ITEMS[this.k].IGST_AMT =   ((this.net_amttt) * (this.exp_invos.ITEMS[this.k].IGST_P-0) ) / 100
   this.exp_invos.ITEMS[this.k].SGST_AMT =  ((this.net_amttt) * (this.exp_invos.ITEMS[this.k].SGST_P-0) ) / 100
   this.exp_invos.ITEMS[this.k].CGST_AMT =  ((this.net_amttt) * (this.exp_invos.ITEMS[this.k].CGST_P-0) ) / 100

  this.exp_invos.ITEMS[this.k].NET_PRICE = this.net_amttt + ((this.net_amttt) * this.total_tax_per ) / 100

  


 
   this.exp_invos.GRAND_TOTAL = 0; 
   this.exp_invos.CGST = 0;
   this.exp_invos.IGST = 0;
   this.exp_invos.SGST = 0;


   for(this.j=0;this.j<this.exp_invos.ITEMS.length;this.j++)
  {

    this.exp_invos.GRAND_TOTAL  = (this.exp_invos.GRAND_TOTAL-0)  + (this.exp_invos.ITEMS[this.j].NET_PRICE-0) 
    this.exp_invos.CGST =  (this.exp_invos.CGST-0) +  (this.exp_invos.ITEMS[this.j].CGST_AMT-0) 
    this.exp_invos.SGST =  (this.exp_invos.SGST-0) +  (this.exp_invos.ITEMS[this.j].SGST_AMT-0) 
    this.exp_invos.IGST =  (this.exp_invos.IGST-0) +  (this.exp_invos.ITEMS[this.j].IGST_AMT-0) 


  }

   this.exp_invos.IGST =  Math.round(this.exp_invos.IGST * 100) / 100;
   this.exp_invos.SGST = Math.round(this.exp_invos.SGST * 100) / 100;
   this.exp_invos.CGST =   Math.round(this.exp_invos.CGST * 100) / 100;

   this.exp_invos.GRAND_TOTAL =  (this.exp_invos.GRAND_TOTAL-0) + (this.exp_invos.round_off-0)
}

cat_from_date(s:any)
{ 
    
   this.exp_invos.DATE = s._validSelected.toLocaleDateString();
   this.date_flag = 2;


}

cat_to_date(s:any)
{ 
    
   this.exp_invos.DATE = s._validSelected.toLocaleDateString();

}

cus_blur(name:any)
{
  this.exp_invos.VENDOR =name;
}


billno_blur(no:any)
{
  this.exp_invos.BILL_NO = no
}









RemoveItem(ii:any)
{

  this.item_arrange(ii);


 
 
 
  this.k = this.k - 1;
  this.i = this.i - 1;
  


  
  this.exp_invos.ITEMS.pop()
  this.calculation()
  
}





 openCity(evt, cityName) {

  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}








   
}



