import { Injectable } from '@angular/core';
import { Http, Headers, Response,RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { contentHeaders } from './headers' ;
import { items } from './model';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class DataService {

    total = 0;
    user_log : string = ''
    ee_entry_no : number = 0;
    item_count : number = 0;
    Tax_info = new items(1,[]);

    
    constructor(private http: Http) { }

    push_tax_info()
    {
        this.Tax_info.item.push({ SI:1,QUANTITY:0,PRICE:null,TAX:null,NET_PRICE:null,NET_VALUE:null,CGST:null,SGST:null,KFC:null})

    }

    get_estimates(model:any)
    {
      let body = JSON.stringify(model); 
    
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_estimates',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())

    }
    get_a_estimate(model:any)
    {
      let body= JSON.stringify(model); 
       
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_a_estimate',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

    }

    
    update_estimate(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/update_estimate',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())
    }


    get_purchase_details_misc(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body)
        return this.http.post('http://hb2.csweb.in/hb2api/public/get_purchase_details_misc',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    update_purchase_entry_without(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body)
        return this.http.post('http://hb2.csweb.in/hb2api/public/update_purchase_entry_without',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    get_audit_report_tax(model:any)
    {
       let body = JSON.stringify(model); 
      console.log('b',body)
        return this.http.post('http://hb2.csweb.in/hb2api/public/get_audit_report_tax',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    get_cus_list()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_users_all',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }
    get_all_units()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/post/get_all_units',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    get_batch_list()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_batch_list',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    add_unit(model:any)
    {
         let body = JSON.stringify(model); 
       console.log('body',body);
       return this.http.post('http://hb2.csweb.in/hb2api/post/add_unit',body,{headers : contentHeaders})  
       .map((res: Response) => res.json())
    }

    get_def_company_details()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_def_company_details',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_all_sales_routes()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/post/get_all_sales_routes',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

   add_route(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/post/add_route',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())

    }

    
    delete_product(model:any)
    {
      let body = JSON.stringify(model); 

      return this.http.post('http://hb2.csweb.in/hb2api/public/delete_product',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    post_estimate(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/post/post_a_estimate',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())

    }

    add_company(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_company',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())
    }

    get_estimate_number()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_estimate_no',{headers : contentHeaders})  
      .map((res: Response) => res.json())

    }

    get_sales_return_no()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_sales_return_no',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    update_ex_reciept(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/update_ex_reciept',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())
    }

    update_ex_payment(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/update_ex_payment',body,{headers : contentHeaders})  
     .map((res: Response) => res.json())
    }

    get_payment_details_by_id(model:any)
    {
      let body = JSON.stringify(model);
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_payment_details_by_id',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_reciept_details_by_id(model:any)
    {
      let body = JSON.stringify(model);
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_reciept_details_by_id',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    get_all_payments()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_all_payments',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_all_reciepts() 
    {
      
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_all_reciepts',{headers : contentHeaders})  
        .map((res: Response) => res.json())
      
  
    }


    get_vendor_customer_id()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_cus_or_vendor_id',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

get_company_details()
  {
        
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_company_details',{headers : contentHeaders})  
       .map((res: Response) => res.json())
 }    


get_master_cus_sales_report_slaves(model:any)
{

   let body = JSON.stringify(model); 
             console.log('jj',body);
  return this.http.post('http://hb2.csweb.in/hb2api/public/get_master_cus_sales_report_slaves',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
}

  get_master_cus_sales_report(model:any)
  {

     let body = JSON.stringify(model); 
             console.log('jj',body);
              return this.http.post('http://hb2.csweb.in/hb2api/public/get_master_cus_sales_report',body,{headers : contentHeaders})  
            .map((res: Response) => res.json())

  }  


  save_edited_expense(model:any)
  {
     let body = JSON.stringify(model); 
             console.log('body',body);
              return this.http.post('http://hb2.csweb.in/hb2api/public/save_edited_expense',body,{headers : contentHeaders})  
            .map((res: Response) => res.json())
  }

  serch_expense(model:any)
  {
    let body = JSON.stringify(model); 
             console.log('body',body);
              return this.http.post('http://hb2.csweb.in/hb2api/public/serch_expense',body,{headers : contentHeaders})  
            .map((res: Response) => res.json())

  }
    
       add_party(model:any)
  
      {
        let body = JSON.stringify(model); 
             console.log('body',body);
            return this.http.post('http://hb2.csweb.in/hb2api/public/add_party',body,{headers : contentHeaders})  
            .map((res: Response) => res.json())
      }



      get_all_pur_return_det()
   {
       
       return this.http.get('http://hb2.csweb.in/hb2api/public/get_all_pur_return_det',{headers : contentHeaders})  
      .map((res: Response) => res.json())
   }



   gett_a_pur_return_det(model:any)
    {
       let body= JSON.stringify(model); 
      console.log('bo',body)
       return this.http.post('http://hb2.csweb.in/hb2api/public/gett_a_pur_return_det',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    post_a_pur_return(model:any)
    {
       let body= JSON.stringify(model); 
      console.log('bo',body)
       return this.http.post('http://hb2.csweb.in/hb2api/public/post_a_pur_return',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    get_a_purchase(model:any)
    {

      let body= JSON.stringify(model); 
      console.log('bo',body)
       return this.http.post('http://hb2.csweb.in/hb2api/public/get_a_purchase_details',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    get_expence_master_details(model:any)
    {

      let body= JSON.stringify(model); 
       return this.http.post('http://hb2.csweb.in/hb2api/public/get_expence_master_details',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

      
     get_vendor_cr_report(model : any)
 {

     let body= JSON.stringify(model); 
       return this.http.post('http://hb2.csweb.in/hb2api/public/vendor_rep_deb_cr',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
 }   



    get_report_other_expense(model:any)
    {
       let body = JSON.stringify(model); 
      console.log('b',body)
        return this.http.post('http://hb2.csweb.in/hb2api/public/get_report_other_expense',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    
         get_purchases()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_purchases',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

       update_purchase(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('b',body)
      return this.http.post('http://hb2.csweb.in/hb2api/post/update_purchase',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    update_purchase_part2(model:any)
    {
    let body = JSON.stringify(model); 
    return this.http.post('http://hb2.csweb.in/hb2api/post/update_purchase_part2',body,{headers : contentHeaders})  
    .map((res: Response) => res.json())
    }
 


     get_expence_details(model:any)
    {
      let body = JSON.stringify(model); 
    
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_expence_details',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }




    get_product_categories()
    {
       return this.http.get('http://hb2.csweb.in/hb2api/public/get_product_categories',{headers : contentHeaders})  
         .map((res: Response) => res.json())       

    }

    get_audit_report_ret(model:any)
    {
        let body = JSON.stringify(model); 
      console.log('b',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/auditor_report_return',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_audit_report_b2c(model:any)
    {
        let body = JSON.stringify(model); 
      console.log('b',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/auditor_report',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    backup()
    {
   
      return this.http.get('http://hb2.csweb.in/hb2api/user/backup',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }


    post_a_return(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('return_body',body)
      return this.http.post('http://hb2.csweb.in/hb2api/post/post_a_return',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

    }
    get_product_category()
    {
      
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_product_cat_list',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_all_mis_expensesss()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_all_mis_expense',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    add_product_category(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('b',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_product_category',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


       get_invoice_string_list()
    {
      
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_invoice_string_list',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    
    }

        add_to_invoice_string_list(model:any)
    {
      let body = JSON.stringify(model);
      
        return this.http.post('http://hb2.csweb.in/hb2api/public/add_to_invoice_string_list',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    
    }
   
       set_default_to_invoice_string_list(model:any)
    {
      let body = JSON.stringify(model);
        console.log('bo',body)
        return this.http.post('http://hb2.csweb.in/hb2api/public/set_default_to_invoice_string_list',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    
    }

      get_default_invoice_string()
    {
      
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_default_invoice_string',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    
    }
  
    get_a_invoice(model:any)
    {
        let body= JSON.stringify(model); 
       
        return this.http.post('http://hb2.csweb.in/hb2api/public/get_a_invoice',body,{headers : contentHeaders})  
        .map((res: Response) => res.json())
     
    }

    get_a_invoice_return(model:any)
    {
        let body= JSON.stringify(model); 
       
        return this.http.post('http://hb2.csweb.in/hb2api/public/get_a_invoice_return',body,{headers : contentHeaders})  
        .map((res: Response) => res.json())
     
    }

    get_cus_slave_report(model:any)
    {
     
       let body= JSON.stringify(model); 
     
      return this.http.post('http://hb2.csweb.in/hb2api/public/cus_slave_report',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }
   
   get_master_cus_report(model:any)
   {
      let body= JSON.stringify(model); 
       
      return this.http.post('http://hb2.csweb.in/hb2api/public/cus_mas_report',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
   }
   
   get_reciept_number_b2b()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_reciept_number_b2b',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


  get_payment_number_b2b()

  {
     return this.http.get('http://hb2.csweb.in/hb2api/public/get_payment_number_b2b',{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }

    post_cash_reciept(model:any)
    {
       let body= JSON.stringify(model); 
        console.log('body',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/post_cash_reciept',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    post_cash_reciept_new(model:any)
    {
       let body= JSON.stringify(model); 
        console.log('body',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/post_cash_reciept_new',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    



  post_cash_reciept_on_bill(model:any)
  {

     let body= JSON.stringify(model); 
        console.log('body',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/post_cash_reciept_on_bill',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }

  get_sales_report_stock(model : any)
  {
 
      let body= JSON.stringify(model); 
        console.log('body',body);
       return this.http.post('http://hb2.csweb.in/hb2api/public/sales_report_stock',body,{headers : contentHeaders})  
       .map((res: Response) => res.json())
  } 

  get_sales_report_stock_from_estimate(model : any)
  { 
      let body= JSON.stringify(model); 
        console.log('body',body);
       return this.http.post('http://hb2.csweb.in/hb2api/public/sales_report_stock_from_estimate',body,{headers : contentHeaders})  
       .map((res: Response) => res.json())
  } 

  purchase_report_stock_from_mise(model : any)
  { 
      let body= JSON.stringify(model); 
        console.log('body',body);
       return this.http.post('http://hb2.csweb.in/hb2api/public/purchase_report_stock_from_mise',body,{headers : contentHeaders})  
       .map((res: Response) => res.json())
  } 


    
     get_sales_report(model : any)
 {

     let body= JSON.stringify(model); 
       console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/sales_report',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
 } 

 get_report_expense(model: any)
  {

     let body = JSON.stringify(model); 
       console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_report_expense',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }     

 get_report_purchase(model: any)
  {

     let body = JSON.stringify(model); 
       console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/reports_purchase',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }  


    get_report_revenue(model: any)
  {

     let body = JSON.stringify(model); 
       console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/reports_revenue',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }  
  get_cash_reci_revenue(model: any)
  {

     let body = JSON.stringify(model); 
       console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/reports_revenue_cash_rec',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }  
   
  save_other_expense(model:any)
  {
     let body = JSON.stringify(model); 
       
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_other_expence_entry',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

  }

    get_customer_list()
     {
    
      return this.http.get('http://hb2.csweb.in/hb2api/public/list_customers',{headers : contentHeaders})  
      .map((res: Response) => res.json())
   
     }


     get_customer_only_list()
       {
    
      return this.http.get('http://hb2.csweb.in/hb2api/public/list_only_customers',{headers : contentHeaders})  
      .map((res: Response) => res.json())
   
     }



     get_cus_cr_report(model : any)
 {

     let body= JSON.stringify(model); 
       return this.http.post('http://hb2.csweb.in/hb2api/public/cus_rep_deb_cr',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
 }   


get_purchase_report(model:any)
{
  let body= JSON.stringify(model); 
       return this.http.post('http://hb2.csweb.in/hb2api/public/get_purchase_report',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
}

get_purchase_detail_report(model:any)
  {
    let body= JSON.stringify(model); 
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_purchase_details_report',body,{headers : contentHeaders})  
        .map((res: Response) => res.json())
  }

  get_purchase_return_detail_report(model:any)
  {
    let body= JSON.stringify(model); 
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_purchase__return_details_report',body,{headers : contentHeaders})  
        .map((res: Response) => res.json())
  }


    get_exp_type_list()
    {
            return this.http.get('http://hb2.csweb.in/hb2api/public/list_expence_category',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


   get_party_list()
   {
      return this.http.get('http://hb2.csweb.in/hb2api/public/list_party',{headers : contentHeaders})  
      .map((res: Response) => res.json())
   }

    get_exp_rec_no()
     {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_exp_rec_no',{headers : contentHeaders})  
      .map((res: Response) => res.json())
     }

     add_purchase_entry(model : any)
     {

    let body = JSON.stringify(model); 
      console.log('pe',body)
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_purchase_entry',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())

      }   

      add_purchase_entry_without(model : any)
      {
 
     let body = JSON.stringify(model); 
       console.log('pe',body)
       return this.http.post('http://hb2.csweb.in/hb2api/public/add_purchase_entry_without',body,{headers : contentHeaders})  
       .map((res: Response) => res.json())
 
       }   

       get_all_pur_mis()
       {
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_all_pur_mis',{headers : contentHeaders})  
        .map((res: Response) => res.json())
       }

    add_expense_category(model:any)
    {
      let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_expense_category',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    delete_customer(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/delete_customer',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

        delete_vendor(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/delete_vendor',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    update_customer(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/update_customer',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }


    update_vendor(model:any)
    {
      let body = JSON.stringify(model); 
      console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/update_vendor',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_customer_list_all()
    {
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_customer_list_all',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }
      get_customer_list_b2b()
    {
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_customer_list_b2b',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    get_vendors_all()
    {
        return this.http.get('http://hb2.csweb.in/hb2api/public/get_vendors_all',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    
     
    add_customer(model:any)
    {
         let body = JSON.stringify(model); 
         console.log('body',body);
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_customer',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    update_invoice_part1(model:any)
    {
          let body = JSON.stringify(model);
          console.log('body',body);   
      return this.http.post('http://hb2.csweb.in/hb2api/post/update_invoice_part1',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    update_invoice_part2(model:any)
    {
      let body = JSON.stringify(model);  
  return this.http.post('http://hb2.csweb.in/hb2api/post/update_invoice_part2',body,{headers : contentHeaders})  
  .map((res: Response) => res.json())

    }
     

      get_invoices_b2b(model:any)
    {
       let body = JSON.stringify(model); 
    
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_invoice_list_b2b',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }
    get_invoices_b2c(model:any)
     {
         let body = JSON.stringify(model); 
    
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_invoice_list_b2c',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }

    add_product(model:any)
    {
       let body = JSON.stringify(model); 
       console.log('de',body)
      return this.http.post('http://hb2.csweb.in/hb2api/post/add_product',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    update_product(model:any)
    {
      let body = JSON.stringify(model); 

      return this.http.post('http://hb2.csweb.in/hb2api/public/update_product',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    bulk_update_product(model:any)
    {
      let body = JSON.stringify(model); 

      return this.http.post('http://hb2.csweb.in/hb2api/public/bulk_update_product',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

   get_product_list()
   {
     
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_product_list',{headers : contentHeaders})  
     .map((res: Response) => res.json())

   }

    get_active_taxes_only()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_active_taxes_only',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }
 

    get_active_taxes_on_edit(model:any)
    {
       let body = JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/public/get_active_taxes_on_edit',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    disable_tax_cat(model:any)
    {
      let body = JSON.stringify(model); 
    
      return this.http.post('http://hb2.csweb.in/hb2api/public/disable_tax_cat',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    enable_tax_cat(model:any)
    {
      let body = JSON.stringify(model); 
      
      return this.http.post('http://hb2.csweb.in/hb2api/public/enable_tax_cat',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_tax_cat()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_tax_cat',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    add_tax_cat(model:any)
    {
      
      let body = JSON.stringify(model); 
   
      return this.http.post('http://hb2.csweb.in/hb2api/public/add_tax_cat',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }

    get_greetings()
    {
      return this.http.get('http://hb2.csweb.in/hb2api/public/get_greetings',{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }
   
   add_new_greeting(model:any)
   {
     let body= JSON.stringify(model); 
      return this.http.post('http://hb2.csweb.in/hb2api/post/add_new_greeting', body, { headers: contentHeaders})
            .map((res: Response) => res.json())
   }
   update_default_greeting(model:any)
   {
     let body= JSON.stringify(model); 
                 console.log('bodies',body);
      return this.http.post('http://hb2.csweb.in/hb2api/post/update_default_greeting', body, { headers: contentHeaders})
            .map((res: Response) => res.json())
   }


       post_invoice(model:any) 
       {
        let body= JSON.stringify(model);  
        console.log('bodies',body);      
      return this.http.post('http://hb2.csweb.in/hb2api/post/post_invoice', body, { headers: contentHeaders})
            .map((res: Response) => res.json())
            
        } 

   get_figure(model:any)
  {
    
       let body = JSON.stringify(model); 
        return this.http.post('http://hb2.csweb.in/hb2api/public/number_to_word',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
  }

   get_invo_number_b2b(model:any)
    {
         let body= JSON.stringify(model); 
                
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_invoice_number_b2b',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }


    get_invo_number_comm_(model:any)
    {
         let body= JSON.stringify(model); 
                
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_invoice_number_comm',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }


  
    get_invo_number_b2c(model:any)
    {  
       let body= JSON.stringify(model); 
                 
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_invoice_number_b2c',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }


       get_purchase_return_no(model:any)
    {  
       let body= JSON.stringify(model); 
                 
         return this.http.post('http://hb2.csweb.in/hb2api/public/get_purchase_return_no',body,{headers : contentHeaders})  
         .map((res: Response) => res.json())
    }
    
    get_products_list()
    {
       return this.http.get('http://hb2.csweb.in/hb2api/public/get_products_list',{headers : contentHeaders})  
      .map((res: Response) => res.json())
    }
 
 


   get_customers_list_b2b()
   {
     return this.http.get('http://hb2.csweb.in/hb2api/public/get_customers_list_b2b',{headers : contentHeaders})  
      .map((res: Response) => res.json())
   }

   get_customers_vendors_details_by_id(model:any)
   {
    let body = JSON.stringify(model); 
     return this.http.post('http://hb2.csweb.in/hb2api/public/get_customers_vendors_details_by_id',body,{headers : contentHeaders})  
      .map((res: Response) => res.json())
   }

    adduser(model:any) {

        let body= JSON.stringify(model); 

        
        return this.http.post('http://hb2.csweb.in/hb2api/public/add_user', body, { headers: contentHeaders})
            .map((response: Response) => {
                
                
                {
                    let postr = response.json();
                   console.log('responce=',postr)
                    
                }
            })   .catch((error:any) => Observable.throw(error.json().error || 'Server error'));
            
            
                    }


  
 
 


    private handleError(error: any): Promise<any> 
  {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
