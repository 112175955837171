

export class list {
  constructor(
    
         public si : number ,
         public item : sublist[],
        
       
      
    
  ) {  }
}


export class sublist 
{
 constructor(
public SI : number,
public DESCRIPTION : string,
public QTY : number,
public UNIT : string,
public PRICE : number,
public SALES_VALUE : number,




  ) {  }
}








export class cus_mas_report {
  constructor(
    
         public master : master,
         public slave : cash[]
        
           
  ) {  }
}



export class master 
{
constructor(
            public  INVOICE_NUMBER: number,
            public  GROSS_TOTAL: number,
            public  TOTAL_PAYED : number,
            public  TOTAL_DUE : number,
            public  IS_PARTIAL_PAY : boolean,
   
) { }
}







 export class cash {
  constructor(
  
  public  INVOICE_NUMBER: number,
  public  CUSTOMER_NAME: string,
  public INVOICE_DATE: string,
  public IS_CASH_PAY: boolean,
  public AMOUNT_PAYED : number,
  public cheque_no : number,
  public cheque_date : string,
  public bank : string,
  public createdAt : Date,
  


  ) {} }


export class Subporto
{
   constructor(
    public   PT_NUMBER: number,
    public  INVOICE_NUMBER: number,
    public  GROSS_TOTAL: number,
    public  TOTAL_PAYED : number,
    public  TOTAL_DUE : number,
    public  IS_PARTIAL_PAY : boolean,
    public   cash_items : cash[]
   ) { }

}


   export class Porto {
  constructor(
 
   public   items : Subporto[],
   
  ) {} }



   export class sssl {
  constructor(
 
   public   items : subssl[],
   
  ) {} }


   export class subssl {
  constructor(

   public SI : number,
   public   UNIT : string,
   public MASTER_ID : number,
   public MASTER_STRING : string,
   public IS_B2B :boolean,
   public QUANTITY : number,
   public NET_PRICE : number,
   public PRODUCT_DESCRIPTION : string,
   public SALE_VALUE : number


   
  ) {} }





// ALT_QTY: 200
// ALT_UNIT: "pkt"
// BILL_DATE: "2019-09-02"
// CATGERY: "MAIN"
// HSN_CODE: "298555"
// ID: 280
// IS_B2B: true
// IS_RETURNED: false
// MASTER_ID: 3
// MASTER_STRING: "FY19"
// NET_PRICE: 25
// PRODUCT_DESCRIPTION: "SOAP"
// QUANTITY: 20
// RET_QTY: 0
// SI_NO: 1
// TAX: 18
// UNIT: "Nos"