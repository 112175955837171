export class Item
{
constructor(
    public index : number,
    public Item : items[]
)
{}
}
export class items
{
constructor(
          public AVAIL_QTY: number,
          public BAR_CODE: string,
          public CATGERY : string,
          public DESCRIPTION : string,
          public HSN_CODE : string,
          public ID: number,
          public MRP  : number,
          public NET_PRICE : number,
          public PUR_PRICE : number,
          public PRICE : number,
          public TAX : number,
          public UNIT : number,
          public SI : number
          
          
) { }
}