import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { DataService } from '../../services/data.service';
import { UpdateService } from '../../services/update.service';
import { ExcelService } from '../../services/excel.service';
import { p_model } from './model';
import { TableUtil } from "../auditor/tableUtil";

@Component({
  selector: 'stock-list',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class ListStock implements OnInit {
 

   arrayOfKeyValues: any[] = [];
   isclicked : boolean= false;
   avil_qty : any;
   view : boolean = false;
   product_array : p_model [] = [];
   exact_array : p_model [] = [];
   total_net_value : number = 0;
   total_avl_qty : number = 0;
   cats : any [] = [];
   view_cat :  boolean = false;
   category : string = '';
   pr_cats : string = '';
     dateFormm = this.fb.group({                  
                                               
                                                  cat : '',
                                                 
                                                 
                                              
                                         });

  constructor(private fb: FormBuilder,private es: ExcelService, private router: Router , private ds: DataService , private us : UpdateService) {   }
  ngOnInit()  {
                    this.get_lists();
     
                    this.get_cats();

              }

  get_cats()
  {
        this.ds.get_product_categories()
       .subscribe((jsonData) => { this.getjson_cats(jsonData)
                      },(err) => console.error(err),
                      
                      );

  }

getjson_cats(json:any)
{
 

     this.cats[0] = 'All'

       for(var i = 0; i < json.length ; i++)
       {

         this.cats[i+1]= json[i].CATEGORY_NAME;
       }
this.view_cat = true;

}


  change_cat(x:any)
  {
     console.log('box', x );
     
      this.category = x;
      this.search_by_cats();

  }

  search_by_cats()
  {

        // this.isClickedOnce = true


        if(this.category != 'All')
        {

        
  this.product_array = this.exact_array.filter(xy=> xy.CATGERY === this.category)
   

           this.value_resm(this.product_array);

        }


        else
       {
         this.product_array = this.exact_array;
         this.value_resm(this.product_array);
       }



  }

  value_resm(_arr:any)
  { 
    this.total_net_value = 0;
    this.total_avl_qty = 0;

    for(var od = 0 ; od < _arr.length ; od++)
    {
      this.product_array[od].NET_VALUE =  this.product_array[od].NET_PRICE * this.product_array[od].AVAIL_QTY;
      this.total_net_value = (this.total_net_value-0) +   (this.product_array[od].NET_VALUE-0);
      this.total_avl_qty = (this.total_avl_qty-0)+ (this.product_array[od].AVAIL_QTY-0)
    }
  }
  

get_lists()
{
  
  console.log('jst');
    
  this.us.list_stocks()
 .subscribe((jsonData) => { this.getjson2(jsonData)
                      },(err) => console.error(err),
                      
                      );
}
  
 exportAsXLSX():void {

  TableUtil.exportToExcel("StockTable");
   // this.es.exportAsExcelFile(this.product_array, 'stock_report');
  }
  

      getjson2(json :any)
  {
   
    this.view = true;
this.product_array = json;
this.exact_array = json;


 
 for(var od = 0 ; od < json.length ; od++)
 {
   this.product_array[od].NET_VALUE =  this.product_array[od].NET_PRICE * this.product_array[od].AVAIL_QTY;
   this.total_net_value = (this.total_net_value-0) +   (this.product_array[od].NET_VALUE-0);
   this.total_avl_qty = (this.total_avl_qty-0)+ (this.product_array[od].AVAIL_QTY-0)
 }


   
  }



 print_report() 
{
  window.print();
}
 
   
  }

