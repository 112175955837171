






   export class invoices {
  constructor(
  
  public  heads:head[],
  public  items:items[],
  public  old_items:old_items[],
  public  taxes:taxes[],
  public  flags:flag[],
  public  tails:tail[],
  public  configs:config[],
  public  varibs:varb[],
  public  company_info : company_info[],
  public  duplicates: duplicate[]



  ) {} }

  
export class items 
{
constructor(
          public SI_NO: number,
          public ID : number,
          public DESCRIPTION: string,
          public HSN_CODE: string,
           public ALT_QTY: number,
          public ALT_UNIT: string,       
          public CATEGORY : string,       
          public QTY : number,
          public FREE_ITEM : number,
          public TAX : number,
          public CESS_AMT : number,
          public PRICE: number,
          public PRICE_COPY : number,
          public UNIT : string,
          public NET_PRICE : number,
          public MRP : number,
          public NET_PRICE_COPY : number,
          public RATE_SUM : number,
          public ITEM_SUM : number,
          public IS_RETURN : boolean,
          public RET_QTY : number,
          public STOCK_RET_QTY : number,
          public BATCH_NO : string,
          public EXPIRY_DATE : string,
          public BAR_CODE : string,
          public MASTER_ID : number
          
          
) { }
}

export class old_items 
{
constructor(

          public SI_NO: number,
          public ID : number,
          public DESCRIPTION: string,
          public ALT_QTY: number,
          public ALT_UNIT: string,                
          public QTY : number,
          public FREE_ITEM : number,
          public BATCH_NO : string,
          public EXP_DATE : string

          
          
) { }
}



export class company_info
{
constructor(
          public COMPANY_NAME: string,
          public COMPANY_GST: string,
          public COMPANY_PH1 : string,
          public COMPANY_PH2: number,
          public COMPANY_ADR_1  : string,
          public COMPANY_ADR_2 : string,
          public COMPANY_ADR_3 : string,
          public COMPANY_EMAIL : string,
          public COMPANY_BANK_NAME : string,
          public COMPANY_BANK_BRANCH : string,
          public COMPANY_BANK_AC_NO : string,
          public COMPANY_BANK_AC_IFSC : string

          
          
) { }
}


export class taxes
{
constructor(
          public SI_NO: number,
          public TAX_NAME: string,
          public TAX_DISPLAY_NAME : string,
          public TAX_RATE: number,
          public TAX_AMT  : number,
          public SCOPE : number,
          public IS_B2B : boolean
          
          
) { }
}
  export class varb {
    constructor(
      public  I : number,
      public  K : number,
      public  Z : number,
    ) {}
  }


  export class head {
    constructor(
      
      public  INVOICE_NUMBER: number,
      public  SALES_RET_NUMBER: number,
      public  INVOICE_STRING: string,
      public  BILL_DATE : string,
      public  CUSTOMER_NAME: string,
      public  CUSTOMER_CITY : string,
      public  CUSTOMER_STREET : string,
      public  CUSTOMER_GST_IN : string,
      public  CUSTOMER_PHONE : string,
      public  CUSTOMER_PHONE2 : string,
      public  CUSTOMER_PAN : string,
      public  CUS_TYPE : string,
      public  TRANS_MODE : string,
      public  VEH_NO : string,
      public  PLACE_SUPPLY : string,
      public  BUNDLES : string,
      public  LPO : string,
      public  DN : string,
      public  CUS_DUE : number,
      public  BILL_DUE : number,
      public  SALE_RET_DATE : string,
      public  CUS_ID : number,
      public  ROUTE_ID : string,
      public USER_LOG : string
  
      
      
    ) {  }
  }

  export class tail {
    constructor(

  public  TOTAL_PAID_TODAY : number,
  public  TOTAL_PRE_PAID : number,
  public  SUB_TOTAL : number,
  public  TOTAL_TAX : number,
  public  TOTAL_CESS : number,
  public  GRAND_TOTAL : number,
  public OLD_GRAND_TOTAL : number,
  public GRAND_TOTAL_DIFF  : number,
  public  TOTAL_DUE : number,
  public  OLD_DUE : number,
  public TOTAL_RETURN_AMT : number,
  public TOTAL_CESS_RETURN : number,
  public TOTAL_TAX_RETURN : number,
  public FLOOR_AMT : number,
  public GRAND_SUB_TOTAL : number,
  public DISC_PERCENT : number,
  public mrp_total : number,
  public You_Save : number,
  public floor : number
  
    ) {} }

  export class config {
    constructor(
      
      public  ITEM_LENGTH : number,
      public  ITEM_OLD_LENGTH : number,
      public  IS_B2B : boolean,
      public  IS_PARTIAL_PAY : boolean,
      public  IS_DEL_POSSIBLE : boolean ,
      public  IS_ON_UPDATE : boolean ,
      public  ACTIVE_TAX_COUNT : number,
      public  IS_ALREADY_RET : boolean
      
      
    
        
    ) {  }
  }

  export class flag {
    constructor(
      
      
      public  IS_INVO_NUMBER_LOADED : boolean,
      public  DATE_CHANGE_FLAG : number,
      public  IS_SERVER_RES : boolean ,
      public  CHANGE_OVER_FLAG : boolean,
      public  IS_PAYMENT_ON_BILL : boolean,
      public IS_PAYMENT_ON_UPDATE : boolean,
      public IS_UPDATE_BUTTON_ENABLE : boolean,
      public IS_UPDATE_ENTRY_CORRECT : boolean,
      public IS_LISTED_CUSTOMER : boolean

     
        
    ) {  }
  }

  export class duplicate {
    constructor(
      
      
      public  IS_D : any,
      public  sales_ret_paid_today : number,
      public  sales_ret_total_cr_value : number,
      public  sales_ret_rem_op_bal :  number
      

     
        
    ) {  }
  }