import { Component,OnInit,TemplateRef, ViewChild  } from '@angular/core';

import {  UpdateService } from '../../../services/update.service';
import { DataService } from '../../../services/data.service';
import { SharingService } from '../../../services/sharing.service';
import { invoices } from './model';
import { columns, Company, data } from './assets';
import { Columns } from 'ngx-easy-table';
import { ConfigService } from './config';

import { Router } from '@angular/router';
@Component({
    selector: 'reciepts-all',
    templateUrl: './main.html',
    styleUrls: ['./main.css'],
   
})
export class Reciepts_all {
    @ViewChild('detailsTemplate') detailsTemplateRef: TemplateRef<any>;
  columns: Columns[] = [];
  data: Company[] = [];

  configuration;
 
  clicked : number = 0;
  clicked2 : string = '';
  clicked3 : number = 0;
  clicked4 : number = 0;
  clicked5 : string = '';

  view : boolean = false;
  mos : any = {"invo_string":''};

 constructor( private ds : DataService, private uS: UpdateService, private rs: Router, private ss: SharingService  )
 {
       this.configuration = ConfigService.config;
   // this.data = data;
    this.columns = columns;
 }
  
  ngOnInit() {
   
   
   this.get_all_reciepts();
       
  }

  get_all_reciepts()
  {
    this.ds.get_all_reciepts()
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );

  }



  getjson(s:any)
  {
    console.log(s);

     this.data = s;
     this.view = true;
  }

   eventEmitted($event) {

     

     if($event.event == 'onSearch')
     {
         
     }
     else
     {
        this.clicked = $event.value.row.RECIEPT_NO;
        this.clicked2 = $event.value.row.CUS_NAME;
      
      alert("Selected Reciept Number  " + this.clicked);
         
          
           this.ss.i_m.heads[0].INVOICE_NUMBER = this.clicked;
           this.ss.i_m.configs[0].IS_B2B = false
     

          this.rs.navigate(['/reciept_details']);

     }


   

   


 
  }

   
}