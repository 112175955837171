


export class dummy
{
  constructor(    
    public DARAY : data[]
  ) { }
}

export class data
{
  constructor(

    public SI : number,
    public INVOICE_NUMBER,
    public NAME : string,
    public GSTIN : string,
    public DATE : string,
    public TOTAL_TAXABLE : number,
    public ZERO_P_TAXABLE : number,
    public ZERO_P_TAX : number,
    public ZERO_P_CGST : number,
    public ZERO_P_SGST : number,
    public T5_P_TAXABLE : number,
    public T5_P_TAX : number,
    public T5_P_CGST : number,
    public T5_P_SGST : number,
    public T12_P_TAXABLE : number,
    public T12_P_TAX : number,
    public T12_P_CGST : number,
    public T12_P_SGST : number,
    public T18_P_TAXABLE : number,
    public T18_P_TAX : number,
    public T18_P_CGST : number,
    public T18_P_SGST : number,
    public T28_P_TAXABLE : number,
    public T28_P_TAX : number,
    public T28_P_CGST : number,
    public T28_P_SGST : number,
    public  CESS : number,


  ) { }
}




export class sublistrr 
{
 constructor(

public SI : number,
public MASTER_ID : number,

public NET_PRICE : number,
public QUANTITY : number,
public TAX : number,
public DISCOUNT : number,
public PRICE : number


  ) {  }
}


export class loaded_list {
  constructor(
    
                
         public master : master[], 
         public slave : slave[]
        
       
      
    
  ) {  }
}


export class slave {
  constructor(

    public  MASTER_ID : number,
    public  NET_PRICE : number ,
    public TAX : number,
    public PRICE : number ,
    public TAXABLE_ : number ,
    public TAX_COLLECTED : number ,
    public   QUANTITY : number,
    public is_ommited : boolean
  

   
   
  
 

) {  }
}

export class master {
  constructor(
    
         public INVOICE_NUMBER : number ,
         public CUSTOMER_NAME : string,
         public BILL_DATE : string,
         public CUSTOMER_GST_IN : string
         
        
        
       
      
    
  ) {  }
}

export class dets {
  constructor(
    
         public INVOICE_NUMBER : number ,
         public CUSTOMER_NAME : string,
         public BILL_DATE : string,
         public CUSTOMER_GST_IN : string,
         public item : sublist[]
        
        
       
      
    
  ) {  }
}


export class items {
  constructor(
    
         public si : number ,
         public master_dets : dets[],
         
        
       
      
    
  ) {  }
}


export class sublist 
{
 constructor(

public SI : number,
public TAX : number,
public TAXABLE_VALUE : number,
public TAX_COLLECTED : number,
public QTY : number,
public NET_PRICE : number,
public PRICE : number,
public is_ommited : boolean,
public TAX_VALUE_TOTAL : number

  ) {  }
}