

  

    export class i_number {
      constructor(
        
        public text : string,
        public count: number
    
        
        
      ) {  }
    }


    export class custo {
      constructor(

   public  CITY: string,
   public  CREDIT_BALANCE : number,
   public CUSTOMER_NAME: string,
   public GSTIN: string,
   public ID: number,
   public IS_VENDOR: boolean,
   public LAST_PAYED_INVO_NUM: number,
   public MOBILE: string,
   public OPENING_BALANCE: number,
   public PHONE: string,
   public STREET: string

      )
      {}
    }