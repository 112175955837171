export class items {
  constructor(
    
         public si : number ,
         public item : sublist[]
        
       
      
    
  ) {  }
}


export class sublist 
{
 constructor(

public SI : number,
public INVOICE_NUMBER : number,
public INVOICE_TYPE : string,
public BILL_DATE : string,
public CUSTOMER_NAME : string,
public CUSTOMER_GST_IN : string,
public SUB_TOTAL : number,
public CESS_COLLECTED : number,
public TAX_AMOUNT : number,
public GRAND_TOTAL : number


  ) {  }
}

