import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';
import { DataService } from '../../../services/data.service';
import { SharingService } from '../../../services/sharing.service';
import { UpdateService } from'../../../services/update.service';
import { pdt_model } from './model';
import { figure } from './model';
import {tax_deri}  from './model';
import  { hsn_array }  from './model';




@Component({
  selector: 'bill-estimate-edit',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Bill_estimate_edit  {


  
   z : number = -1;
   j : number;
   total_qty : number = 0;


   invo_string : string = '';
   vendsi : any = null ;
  

   
   
   
   isClickedOnce : boolean = false;
   
   isPrint : boolean = true;
 
   customer_name : string = '';
   is_invo_num_loaded : boolean = false
   isServer_res : boolean = false
  
  
   arrayOfKeyValues: any[] = [];
   arrayOfspecValues: any[] = [];
   arrayOfCusValues : any[] =[];
   specs :any[] = []
 
  fig_model = new figure(0,0,'');
  product_array : pdt_model[] =[];
  stock_check : number = 0;
  figured_out : string;
  fig_main : string ;
  fig_tax : string ;
  fig_sub : string ;
  floor : number;
  frac : number;
  date_change_flag : number = 0;
  snak_msg : string = '';
  print_section : boolean = false;
  b: number = 0;
  d_ : number = 0;
  pdtary : number [] = [];
  tax_d = new tax_deri(0,[]);
  bool : boolean ;

  arr_ : number = 0;
  post_qq : any = {invo_string : "",is_b2b:false}
  up_click_once : boolean = false;
  si : number = 0;
  pdt_tax_list : any [] = [];
  pdt_tax_list_counter : number = 0;
 gross_total : number = 0;
 myDatePickerOptions: any;

 post_model : any = {invo_string : "", estimate_number: 0}
 


  constructor(private fb: FormBuilder, private ds :DataService, private router : Router,
                         private us : UpdateService, private ss : SharingService) { }
 ngOnInit() {


  this.myDatePickerOptions = {
    dateFormat: 'dd-mm-yyyy'
}
this.post_model.invo_string =   this.ss.i_m.heads[0].INVOICE_STRING;
this.post_model.estimate_number =  this.ss.i_m.heads[0].INVOICE_NUMBER
    this.get_estimate_master(); 
    this.get_products();
    this.get_customers();

       
   
  }

 

  cus_blur(p:any)
{





  let s : any; 

   s = this.arrayOfCusValues.filter(xi=> xi.CUSTOMER_NAME === p);

if(s.length != 0)
{
 this.ss.i_m.heads[0].CUSTOMER_NAME = s[0].CUSTOMER_NAME;
 this.ss.i_m.heads[0].CUSTOMER_STREET = s[0].STREET;

 this.ss.i_m.heads[0].CUSTOMER_PHONE = s[0].PHONE;
 this.ss.i_m.heads[0].CUSTOMER_PHONE2 = s[0].MOBILE;
 
 

}

}

get_estimate_master()
{
  this.ds.get_a_estimate(this.post_model)
  .subscribe((jsonData) => { this._get_estimate_master(jsonData) 
    },(err) => console.error(err));
} 
  


  _get_estimate_master(json:any)
  {
    
    console.log(json,json);
 

    this.ss.i_m.heads[0].BILL_DATE = json.master.BILL_DATE;
    this.ss.i_m.heads[0].CUSTOMER_NAME = json.master.CUSTOMER_NAME
    this.ss.i_m.heads[0].CUSTOMER_STREET = json.master.CUSTOMER_STREET
    this.ss.i_m.heads[0].CUSTOMER_PHONE2 = json.master.CUSTOMER_MOBILE
    this.ss.i_m.tails[0].SUB_TOTAL = json.master.SUB_TOTAL
    this.ss.i_m.tails[0].GRAND_TOTAL = json.master.GRAND_TOTAL
    this.ss.i_m.varibs[0].I = json.slave.length;

    this.date_change_flag = 2;

    for (this.b = this.ss.i_m.items.length ; this.b > 0 ; this.b--)
    {
       this.ss.i_m.items.pop();
      
    }
  
    



    for(var i=0;i<json.slave.length;i++)
    {
      
        this.push_ss_edit();

      this.ss.i_m.items[i].DESCRIPTION = json.slave[i].PRODUCT_DESCRIPTION ;      
      this.ss.i_m.items[i].QTY = json.slave[i].QUANTITY;
      this.ss.i_m.items[i].TAX = json.slave[i].TAX;
      this.ss.i_m.items[i].NET_PRICE = json.slave[i].NET_PRICE;


    


      this.d_ =  this.ss.i_m.items[i].NET_PRICE * 100 / (100 +  this.ss.i_m.items[i].TAX );

      this.ss.i_m.items[i].PRICE = this.d_


      this.ss.i_m.items[i].HSN_CODE = json.slave[i].HSN_CODE;
      this.ss.i_m.items[i].SI_NO = json.slave[i].SI_NO;
  
      this.ss.i_m.items[i].UNIT = json.slave[i].UNIT;
      

   

      


  }
//   this.ss.i_m.items.pop();

this.calculation();
  }

  push_ss_edit()
{
         
        this.ss.i_m.items.push({ SI_NO:1,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,FREE_ITEM:null,PRICE_COPY:null,
        MRP:null,BATCH_NO:null,EXPIRY_DATE:null,BAR_CODE:null,MASTER_ID:null,
 PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })  
      
}

focus_one(index:number)
{
  this.ss.i_m.varibs[0].K = index ;
  this.ss.i_m.items[index].SI_NO = index + 1;

}
change_net_price(np:any)
{
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE = np ;
    
    
    this.calculation();
}
change_qty(qty:any)
{
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY = qty;
      

   this.calculation();
}  

addItem(inx:number)

{
  
   
  this.si = inx;
 
   if(inx >= 10)
  {
    this.snak_fun('No more Items allowed')
  }
  else
  {
      
    this.ss.i_m.varibs[0].I= this.ss.i_m.varibs[0].I+1;

   

  this.ss.i_m.items.push({ SI_NO:this.si,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,FREE_ITEM:null,
  CESS_AMT:0,BAR_CODE:null,BATCH_NO:null,EXPIRY_DATE:null,MASTER_ID:null,MRP:null,PRICE_COPY:null,
  PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })
          
       } 
}

calculation()
{
   
 
   this.ss.i_m.tails[0].GRAND_TOTAL = 0 
   this.total_qty = 0
   this.gross_total = 0;
   this.ss.i_m.tails[0].SUB_TOTAL = 0

   




for(this.j=0;this.j<this.ss.i_m.varibs[0].I;this.j++)

{

  

   this.ss.i_m.items[this.j].RATE_SUM = this.ss.i_m.items[this.j].NET_PRICE * this.ss.i_m.items[this.j].QTY;



   this.ss.i_m.tails[0].SUB_TOTAL = (this.ss.i_m.tails[0].SUB_TOTAL-0) + (this.ss.i_m.items[this.j].RATE_SUM-0)

   
    this.gross_total = (this.gross_total-0) +  (this.ss.i_m.items[this.j].NET_PRICE *  this.ss.i_m.items[this.j].QTY);
  

  
  
    this.total_qty = (this.total_qty-0) + (this.ss.i_m.items[this.j].QTY-0) ;
}




   this.ss.i_m.tails[0].GRAND_TOTAL =  this.ss.i_m.tails[0].SUB_TOTAL-0

   


   this.floor =  Math.floor(this.ss.i_m.tails[0].GRAND_TOTAL);  
   this.frac =  (this.ss.i_m.tails[0].GRAND_TOTAL) % 1;

     if(this.frac > .49)
     {
       this.frac = 1 - this.frac;
       this.floor = this.floor + 1;
     }

   this.ss.i_m.tails[0].GRAND_TOTAL = this.floor;  

   this.get_figure(this.floor);



   this.get_fig_tax(this.ss.i_m.tails[0].TOTAL_TAX);

   }
  
   update_estimate()
   {
    this.Tax_info(this.ss.i_m)
    this.up_click_once = true;
    this.ds.update_estimate(this.ss.i_m)
    .subscribe((jsonData) => { this.get_res_update_init(jsonData)
                   },(err) => console.error(err),
                  );
   }

   get_res_update_init(json:any)
   {
   alert(json.msg);

   window.print();
   this.router.navigate(['/dash']);
  

   }
   

   Tax_info(data:any)
   {
     
   
   
   
   
   
    this.pdt_tax_list_counter = 1;
   
     for(var liss = 0;liss<data.items.length;liss++)
     {
   
   
       console.log('liss',data.items[liss].TAX);
   
       if(liss == 0)
       { 
         this.pdt_tax_list[0] = data.items[0].TAX
         this.ds.push_tax_info();
         this.ds.Tax_info.item[0].TAX = data.items[0].TAX
         this.ds.Tax_info.item[0].PRICE = data.items[0].PRICE;
         this.ds.Tax_info.item[0].NET_VALUE = data.items[0].PRICE * data.items[0].QTY;
         this.ds.Tax_info.item[0].CGST =  (this.ds.Tax_info.item[0].NET_VALUE *  (this.ds.Tax_info.item[0].TAX)/2) / 100;
         this.ds.Tax_info.item[0].SGST = (this.ds.Tax_info.item[0].NET_VALUE *  (this.ds.Tax_info.item[0].TAX)/2) / 100;
        
         if(this.ds.Tax_info.item[0].TAX > 5)
         {
           this.ds.Tax_info.item[0].KFC =  ( this.ds.Tax_info.item[0].NET_VALUE ) / 100;
         }
   
   
       }
   
       else
       {
          
       var x = this.pdt_tax_list.indexOf(data.items[liss].TAX,0)
       console.log('id',x)
       if(x < 0)
       {
         this.ds.push_tax_info();
         this.pdt_tax_list[this.pdt_tax_list_counter] = data.items[liss].TAX
         this.ds.Tax_info.item[this.pdt_tax_list_counter].TAX =  data.items[liss].TAX
        
         this.ds.Tax_info.item[this.pdt_tax_list_counter].PRICE = data.items[liss].PRICE;
         
         this.ds.Tax_info.item[this.pdt_tax_list_counter].NET_VALUE = data.items[liss].PRICE * data.items[liss].QTY;
   
         var temp_net_val =  data.items[liss].PRICE * data.items[liss].QTY;
    
         this.ds.Tax_info.item[this.pdt_tax_list_counter].CGST =   (temp_net_val *  (data.items[liss].TAX)/2) / 100;
         this.ds.Tax_info.item[this.pdt_tax_list_counter].SGST =   (temp_net_val *  (data.items[liss].TAX))/2 / 100;
          
         if(this.ds.Tax_info.item[this.pdt_tax_list_counter].TAX > 5)
         {
           this.ds.Tax_info.item[this.pdt_tax_list_counter].KFC =  temp_net_val / 100;
         }
         
         
         this.pdt_tax_list_counter = this.pdt_tax_list_counter + 1;
       }
     
       else
       {
         
         this.ds.Tax_info.item[x].NET_VALUE = this.ds.Tax_info.item[x].NET_VALUE + data.items[liss].PRICE * data.items[liss].QTY;
         this.ds.Tax_info.item[x].CGST =  (this.ds.Tax_info.item[x].NET_VALUE *  (this.ds.Tax_info.item[x].TAX)/2) / 100;
         this.ds.Tax_info.item[x].SGST =  (this.ds.Tax_info.item[x].NET_VALUE *  (this.ds.Tax_info.item[x].TAX)/2) / 100;
         if(this.ds.Tax_info.item[x].TAX > 5)
         {
           this.ds.Tax_info.item[x].KFC =   this.ds.Tax_info.item[x].NET_VALUE  / 100;
         }
         
   
       }
   
   
   
     }
   }
   console.log('list',this.ds.Tax_info);
   
   }


 change_one(item_code:any)
 {
 
 
 
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = item_code ;
   let si : any;     
 si = this.product_array.filter(xi=> xi.DESCRIPTION  === item_code);
 if(si.length > 0)
 {
   
 this.stock_check = si[0].AVAIL_QTY ;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = si[0].DESCRIPTION;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].ID = si[0].ID;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].CATEGORY = si[0].CATGERY;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].HSN_CODE = si[0].HSN_CODE;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].UNIT = si[0].UNIT;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].TAX = si[0].TAX;
 
 
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE_COPY = si[0].NET_PRICE;
 
 

 if(this.ss.i_m.varibs[0].K > 0)
 {
 
   this.check_for_duplication(si[0].ID)

  
 }
 else
 {
 
    this.pdtary[this.ss.i_m.varibs[0].K] = si[0].ID;
 }
 

 
 
 this.calculation();
 
 }
 else
 {
   this.snak_fun('Enter valid Specification');
 }
 
 
 
 
 }
 
 check_for_duplication(id : number)
 {
 
   var x = this.pdtary.indexOf(id,0)
   
   if(x < 0)
   {
    this.pdtary[this.ss.i_m.varibs[0].K] = id;
   }
   else
   {
     var nun = x + 1;
   // alert('Already Present in Position '+ nun);
   }

 }



   get_figure(x:any)
   {
       
     this.fig_model.number_to_convert = x;
      this.ds.get_figure(this.fig_model)
      .subscribe((jsonData) => { this.getjson87(jsonData)
                         },(err) => console.error(err),
                          
                         );
   
   }
   
   getjson87 (p:any)
   {
    
      this.fig_main = p.msg;
     
    
   }
   get_fig_tax(y:any)
   {
       this.fig_model.number_to_convert = y;
      this.ds.get_figure(this.fig_model)
      .subscribe((jsonData) => { this.getjson89(jsonData)
                         },(err) => console.error(err),
                          
                         );
   }
   getjson89(json:any)
   {
      this.fig_tax = json.msg;
   }
   



get_customers()
{
   this.ds.get_customers_list_b2b()
  .subscribe((jsonData) => { this.get_customer_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
}
get_customer_res(json:any)
{ 
  this.arrayOfCusValues = json;
}




change_date()
{
  this.date_change_flag = 0;
}


push_items( si : number )
    
           {
           
             if(si > this.ss.i_m.items.length)
             {
               
               this.ss.i_m.items.push({ SI_NO:si,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,FREE_ITEM:null,
               MASTER_ID:null,MRP:null,EXPIRY_DATE:null,BAR_CODE:null,BATCH_NO:null,PRICE_COPY:null,
     PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })

              }
              else
              {
                 this.reset_ss();
              }
            }

            reset_ss()
            {
             this.z = this.ss.i_m.items.length
              while (this.z > 1) 
              {
                 this.ss.i_m.items.pop();
                  this.z--
                  
              }
            this.ss.i_m.tails[0].SUB_TOTAL = 0;
            }

    get_products()
            {
              this.ds.get_products_list()
              .subscribe((jsonData) => { this._get_products(jsonData)
                      },(err) => console.error(err),
                       
                      );
           }

      _get_products(json :any)
        {     

          
            this.arrayOfKeyValues = json;
            
            this.product_array = json;
            this.isServer_res = true;
            
            for(var nip = 0; nip < json.length; nip++)
           
            {
              this.specs[nip] = json[nip].SPECIFICATION
            }

         

            
       
         }














snak_fun(msg:string)
{
      this.snak_msg = msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    
}

}


















  


