import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { exp_invo } from '../model';

@Component({
  selector: 'app-expence-main-edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.css']
})
export class Expence_main_edit implements OnInit {

  E_Edit_Form : FormGroup;
  cats :any[] = [];
  purchases : any [] = [];
  exp_invos = new exp_invo(1,'Purchase',0,[],'','',0,0,0,0,0,'','','');
  is_edit : boolean = false;
  arrayOfCusValues : any[] =[];
  constructor( private fb: FormBuilder, private ds :DataService, private router : Router ) { } 

  ngOnInit() {
   
    this.get_cat_list();
    this.get_exp_type_list();
    this.get_third_party();
       
       this.E_Edit_Form = this.fb.group({

        entry_no : '',
      })

   
  }
  getjson45(json :any)
  {
   this.arrayOfCusValues = json;
  }

  get_third_party()
  {
     this.ds.get_party_list()
    .subscribe((jsonData) => { this.getjson45(jsonData)
                        },(err) => console.error(err),
                        
                        );
  }

  get_exp_type_list()
  {
     this.ds.get_exp_type_list()
        .subscribe((jsonData) => { this.getjsonss(jsonData)
                        },(err) => console.error(err)
                       
                        );
  
  }
  
  getjsonss(json :any)
    {
      
     
     for( var i = 0 ; i < json.length; i++)
    this.purchases[i] = json[i].CATEGORY_NAME;
    }

    
  get_cat_list()
  {

    
  
  
    this.ds.get_product_category()
    .subscribe((jsonData) => { this.get_res_cat_list(jsonData)
                    },(err) => console.error(err),
                   
                    );

  }
  get_res_cat_list(json:any)
  {
    console.log(json)
    for(var jp=0; jp<json.length;jp++)
    {
      this.cats[jp] = json[jp].CATEGORY_NAME

    }
    this.serch_expense()
   }
  
   cus_blur(name:any)
   {
     this.exp_invos.VENDOR =name;
   }

  serch_expense()
  {
      this.E_Edit_Form.patchValue({entry_no : this.ds.ee_entry_no})
      
       this.ds.serch_expense(this.E_Edit_Form.value)
   .subscribe((jsonData) => { this.expenses_resu(jsonData)
                     },(err) => console.error(err),
                      
                     );
  }

  expenses_resu(json:any)
  {
    console.log('nicj',json)

    if(json.sucess === false)
    {
      alert(json)
    }

    else
    {
       
       
    
       this.exp_invos.BILL_NO = json.msg.BILL_NO;
       this.exp_invos.E_CATEGORY = json.msg.EXPENCE_CATEGORY
       this.exp_invos.VENDOR = json.msg.VENDOR
       this.exp_invos.GRAND_TOTAL = json.msg.TOTAL_AMOUNT
       this.exp_invos.REMARKS = json.msg.REMARKS
       this.exp_invos.DATE = json.msg.EXP_DATE
       this.exp_invos.E_ENTRY_NUMBER = json.msg.EXPENCE_ENTRY_NO


       this.is_edit = true
    }
  }



  get_all_mis_expense()
   {
        this.ds.get_all_mis_expensesss()
    .subscribe((jsonData) => { this.expenses_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
   }

   expenses_res(json:any)
   {
     console.log('nicj',json)

     if(json.sucess === false)
     {
       alert(json.msg)
     }

     else
     {
       

      this.is_edit = true;
        
     
   


     }
   }


   change_opt(bi : any)
   {
     this.exp_invos.E_CATEGORY = bi;
   }
   
   save_edited_expense()
   {
     console.log('EEE',this.exp_invos)
   
        this.ds.save_edited_expense(this.exp_invos)
       .subscribe((jsonData) => { this.get_res_edited(jsonData)
                         },(err) => console.error(err),
                          
                         );
   
   }
   
   get_res_edited(json:any)
   {
    alert('Updated');

    this.router.navigate(['/dash']); 

   }
   
   
}