

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { items } from './model';

@Component({
  selector: 'my-barcode',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class BarcodeComponent {
  elementType = 'svg';
  value = '12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1;
  height = 23;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'top';
  textMargin = 2;
  fontSize = 12;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  b : number = 0;
  Item = new items([]);
  count : number = 0;
  status : number = 0;
  bcvalue : string = null;
  //count_all : number = 0;
  status_all : number = 0;
  Ingvalue : string;
  best_before : string;
  packed_on : string;
  mrp: string;
  net_wt : string;
  name : string;
  ngOnInit() {

    this.pop_items();
   
    }
    constructor(private router: Router ) { }
    

  get values(): string[] {
    return this.value.split('\n');
  }

  pop_items()
  {

    for (this.b = this.Item.item.length ; this.b > 0 ; this.b--)
    {
       
       this.Item.item.pop()
      
    }
    
  }

  push()
  {
  
    if((this.status_all + this.count) < 25)
    {

     
      for(this.b=0;this.b<this.count;this.b++)
      this.Item.item.push({si:null,fssai:null,
        com_line:null,packed_on:this.packed_on,best_before:this.best_before,
        com_name:null,name:this.name,
        bcValue:this.bcvalue,
        net_wt:this.net_wt,mrp:this.mrp,Ingradiants:this.Ingvalue,other:null})




      this.status_all = this.status_all + this.count;
    }

    else
    {
       alert('limit exceded')
    }
    
   
  }

  pop()
  {
   this.pop_items();
   this.status_all = 0;
  }

  print()
  {
    window.print();
  }



  codeList: string[] = [
    '', 'CODE128',
    'CODE128A', 'CODE128B', 'CODE128C',
    'UPC', 'EAN8', 'EAN5', 'EAN2',
    'CODE39',
    'ITF14',
    'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110',
    'pharmacode',
    'codabar'
  ];
}


