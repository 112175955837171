import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';
import { DataService } from '../../services/data.service';
import { SharingService } from '../../services/sharing.service';
import { UpdateService } from'../../services/update.service';
import { pdt_model,figure } from '../new/model';



@Component({
  selector: 'quick-invoice',
  templateUrl: './quick.html',
  styleUrls: ['./quick.css']
})
export class Bill_quick  {

    ia : number = 0;
    ka : number = 0;
    modal : any;
    pro_qty_model : number;
    pro_np_model : number;
    pro_name : string;
    arrayOfKeyValues: any[] = [];
    product_array : pdt_model[] =[];
    isServer_res : boolean = false;
    pdtary : number [] = [];

    pro_id : number = 0;
    pro_catgery : string ;
    pro_hsn : string;
    pro_unit : string;
    pro_mrp : number;
    pro_tax : number;
    pro_np  : number;
    pro_cat : string;
    pro_qty : number = 1;
    pro_barcode : string;
    pro_price : number;
    arr_: number = 0;
    floor : number;
    frac : number;
    j:number;
    fig_tax : string ;
    fig_main : string ;
    total_qty : number = 0;
    gross_total : number = 0;
    temp_total_tax : number = 0;
    temp_total_mrp : number = 0 ;
    fig_model = new figure(0,0,'');
    discount_amt : number = 0;
    push_disabled : boolean = false;
    push_disabled_enter : boolean = false;
    data_error_string : string;
    data_error_flag : number;
    isClickedOnce : boolean = false;
    b : number;
    recamt_amt : number = 0;
    bal_due   : number = 0;
    a5_print : boolean = true;
    a4_print : boolean = false;
    @ViewChild("item_qty_model") _eq: ElementRef; 
    @ViewChild("autos") _en: ElementRef; 
    @ViewChild("disamt") _ed: ElementRef; 
    

    constructor(private fb: FormBuilder, private ds :DataService, private router : Router,
        private us : UpdateService, private ss : SharingService) { }
ngOnInit() {


this.modal = document.getElementById("native");

this.get_products();

this.ss.i_m.items.pop();



}

get_products()
{
  this.ds.get_products_list()
  .subscribe((jsonData) => { this._get_products(jsonData)
          },(err) => console.error(err),
           
          );
}

_get_products(json :any)
{               
this.arrayOfKeyValues = json;            
this.product_array = json;
this.isServer_res = true;            
   

//this._en.nativeElement.focus();

}

change_item_name(i_n:any)
{
    console.log(i_n)
    
    let bi : any;
 
  bi = this.product_array.filter(xi=> xi.BAR_CODE  === i_n);
 
 
 
      if(bi.length == 1)
      {
 
       this.pro_id = bi[0].ID;
       this.pro_catgery = bi[0].CATGERY;
       this.pro_hsn = bi[0].HSN_CODE;
       this.pro_unit =  bi[0].UNIT;
       this.pro_mrp = bi[0].MRP;
       this.pro_tax = bi[0].TAX;
       this.pro_np =  bi[0].NET_PRICE;
       this.pro_name = bi[0].DESCRIPTION;
       this.pro_barcode = i_n;
       this.pro_price = bi[0].PRICE
       this.pro_cat = bi[0].CATGERY;
 
 
         this.check_for_duplication(bi[0].ID)
 
       this._eq.nativeElement.focus(); 
       this.pro_qty = 1;
       this.push_disabled = false;
 
      }
 
}
pushItem_down()
{
    this.stack_push();
    this.ss.i_m.items[this.ia].BAR_CODE = this.pro_barcode;
    this.ss.i_m.items[this.ia].HSN_CODE = this.pro_hsn;
    this.ss.i_m.items[this.ia].DESCRIPTION = this.pro_name;
    this.ss.i_m.items[this.ia].QTY = this.pro_qty
    this.ss.i_m.items[this.ia].TAX = this.pro_tax
    this.ss.i_m.items[this.ia].NET_PRICE = this.pro_np
    this.ss.i_m.items[this.ia].NET_PRICE_COPY = this.pro_np
    this.ss.i_m.items[this.ia].PRICE = this.pro_price
    this.ss.i_m.items[this.ia].ID = this.pro_id;
    this.ss.i_m.items[this.ia].MRP = this.pro_mrp;
    this.ss.i_m.items[this.ia].CATEGORY = this.pro_cat;
 
    this.ia = this.ia+1;
    this._en.nativeElement.value = null 
    this._en.nativeElement.focus(); 
    this.push_disabled = true;
    this.calculation();
}
pushItem()
{
    this.stack_push();
    this.ss.i_m.items[this.ia].BAR_CODE = this.pro_barcode;
    this.ss.i_m.items[this.ia].HSN_CODE = this.pro_hsn;
    this.ss.i_m.items[this.ia].DESCRIPTION = this.pro_name;
    this.ss.i_m.items[this.ia].QTY = this.pro_qty;
    this.ss.i_m.items[this.ia].TAX = this.pro_tax;
    this.ss.i_m.items[this.ia].NET_PRICE = this.pro_np;
    this.ss.i_m.items[this.ia].NET_PRICE_COPY = this.pro_np;
    this.ss.i_m.items[this.ia].PRICE = this.pro_price;
    this.ss.i_m.items[this.ia].ID = this.pro_id;
    this.ss.i_m.items[this.ia].MRP = this.pro_mrp;
    this.ss.i_m.items[this.ia].CATEGORY = this.pro_cat;
 
    this.ia = this.ia+1;
    this.push_disabled = true;
    this._ed.nativeElement.focus(); 
     this.calculation();

    

}
stack_push()
{
    this.ss.i_m.items.push({ SI_NO:this.ia+1,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,BATCH_NO:null,EXPIRY_DATE:null,MRP:0,
    PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',FREE_ITEM:0,PRICE_COPY:0,BAR_CODE:'',MASTER_ID:0
    ,NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })
}

editItem(index:any)
{
    this.open_modal();
    this.ka = index;
    this.pro_qty_model = this.ss.i_m.items[index].QTY;
    this.pro_np_model = this.ss.i_m.items[index].NET_PRICE;
}



change_qty_first(qty:number)
{
    
}

check_for_duplication(id : number)
{

  if(this.ia === 0)
  {
   this.pdtary[this.ia] = id;
  }
  else
  {
   console.log('id aray', this.pdtary);

   var x = this.pdtary.indexOf(id,0)
   console.log('id',x)
   if(x < 0)
   {
    this.pdtary[this.ia] = id;
   }
   else
   {
     
      if(x === this.ia)
      {
  
      }
      else
      {
        var nun = x + 1;
        alert('Already Present in Position '+ nun);
      }
    
   }


  }


}




open_modal()
{
    this.modal.style.display = "block";
}

close_modal()
{
    this.ss.i_m.items[this.ka].QTY = this.pro_qty_model 
   this.ss.i_m.items[this.ka].NET_PRICE = this.pro_np_model 

    this.modal.style.display = "none";

    this.calculation()
}

RemoveItem(ipx:number)
{
    this.item_arrange(ipx);

}

item_arrange(index)
{

    console.log('ia',this.ia);

    console.log('index',index);

    for(this.arr_ = index ; this.arr_ <= this.ia-2 ; this.arr_ ++)
    {

        console.log('arr',this.arr_);
      
     this.ss.i_m.items[this.arr_].DESCRIPTION = this.ss.i_m.items[this.arr_+1].DESCRIPTION;
    
     this.ss.i_m.items[this.arr_].ID = this.ss.i_m.items[this.arr_+1].ID;
     this.ss.i_m.items[this.arr_].HSN_CODE = this.ss.i_m.items[this.arr_+1].HSN_CODE;
     this.ss.i_m.items[this.arr_].QTY = this.ss.i_m.items[this.arr_+1].QTY;
     this.ss.i_m.items[this.arr_].UNIT = this.ss.i_m.items[this.arr_+1].UNIT;
     this.ss.i_m.items[this.arr_].TAX = this.ss.i_m.items[this.arr_+1].TAX;
     this.ss.i_m.items[this.arr_].PRICE = this.ss.i_m.items[this.arr_+1].PRICE;
     this.ss.i_m.items[this.arr_].NET_PRICE = this.ss.i_m.items[this.arr_+1].NET_PRICE;
     this.ss.i_m.items[this.arr_].RATE_SUM  = this.ss.i_m.items[this.arr_+1].RATE_SUM;
     this.ss.i_m.items[this.arr_].CATEGORY  = this.ss.i_m.items[this.arr_+1].CATEGORY;

     this.pdtary[this.arr_] =  this.pdtary[this.arr_+1];
    

    }

    this.ss.i_m.items.pop();
    this.pdtary.pop();
    this.ia = this.ia-1;



}

calculation()
{
   
 
   this.ss.i_m.tails[0].GRAND_TOTAL = 0 
   this.total_qty = 0
   this.gross_total = 0;
   this.ss.i_m.tails[0].SUB_TOTAL = 0
   this.ss.i_m.tails[0].TOTAL_CESS = 0
   this.ss.i_m.tails[0].TOTAL_TAX = 0
   this.temp_total_tax = 0 
   this.ss.i_m.tails[0].GRAND_SUB_TOTAL = 0;
   this.ss.i_m.tails[0].You_Save = 0;

   this.temp_total_mrp = 0 




for(this.j=0;this.j<this.ss.i_m.items.length;this.j++)

{
 //  this.ss.i_m.items[this.j].PRICE  = this.ss.i_m.items[this.j].PRICE_COPY;
  
 

   this.ss.i_m.items[this.j].RATE_SUM = this.ss.i_m.items[this.j].PRICE * this.ss.i_m.items[this.j].QTY

   this.temp_total_mrp =    this.temp_total_mrp  + (this.ss.i_m.items[this.j].MRP * this.ss.i_m.items[this.j].QTY)

   this.ss.i_m.tails[0].SUB_TOTAL = (this.ss.i_m.tails[0].SUB_TOTAL-0) + (this.ss.i_m.items[this.j].RATE_SUM-0)

   this.ss.i_m.tails[0].GRAND_SUB_TOTAL = (this.ss.i_m.tails[0].GRAND_SUB_TOTAL-0) + (this.ss.i_m.items[this.j].NET_PRICE * this.ss.i_m.items[this.j].QTY)
  

   if(this.ss.i_m.heads[0].CUS_TYPE === 'SEZ')
   {
    this.ss.i_m.tails[0].TOTAL_TAX =   0;
    this.gross_total = (this.gross_total-0) +  (this.ss.i_m.items[this.j].PRICE *  this.ss.i_m.items[this.j].QTY);
   }

   else
   {
    this.ss.i_m.tails[0].TOTAL_TAX =   this.ss.i_m.tails[0].TOTAL_TAX +  ((this.ss.i_m.items[this.j].RATE_SUM  *  this.ss.i_m.items[this.j].TAX)/100) 
    this.gross_total = (this.gross_total-0) +  (this.ss.i_m.items[this.j].NET_PRICE *  this.ss.i_m.items[this.j].QTY);
   }

  
    if(this.ss.i_m.configs[0].IS_B2B === false && this.ss.i_m.items[this.j].TAX > 5)

    {
        this.ss.i_m.items[this.j].CESS_AMT = ((this.ss.i_m.items[this.j].PRICE * 1) / 100 ) * this.ss.i_m.items[this.j].QTY 
        this.ss.i_m.tails[0].TOTAL_CESS = (this.ss.i_m.tails[0].TOTAL_CESS-0) + (this.ss.i_m.items[this.j].CESS_AMT-0) 
        

    }

    this.total_qty = (this.total_qty-0) + (this.ss.i_m.items[this.j].QTY-0) ;
}


   this.ss.i_m.tails[0].GRAND_TOTAL =  (this.ss.i_m.tails[0].GRAND_SUB_TOTAL-0) -  (this.ss.i_m.tails[0].DISC_PERCENT-0) + (this.ss.i_m.tails[0].TOTAL_CESS-0)

   


   this.floor =  Math.floor(this.ss.i_m.tails[0].GRAND_TOTAL);  
   this.frac =  (this.ss.i_m.tails[0].GRAND_TOTAL) % 1;

     if(this.frac > .49)
     {
       this.frac = 1 - this.frac;
       this.floor = this.floor + 1;
     }

   this.ss.i_m.tails[0].GRAND_TOTAL = this.floor;  

   this.ss.i_m.tails[0].mrp_total = this.temp_total_mrp;
   this.ss.i_m.tails[0].You_Save =  (this.ss.i_m.tails[0].mrp_total-0) - (this.ss.i_m.tails[0].GRAND_TOTAL-0)
  


    
 





   }

   change_recamt()
{
  
  this.bal_due  =  this.ss.i_m.tails[0].GRAND_TOTAL - this.recamt_amt;

}

   change_discount(dis:any)
   {
    this.ss.i_m.tails[0].DISC_PERCENT = dis;
        this.calculation();
        this.change_recamt();
   }

   

save_invoice()
{

  this.ss.i_m.heads[0].CUSTOMER_NAME = 'Cash';
  this.ss.i_m.heads[0].CUS_ID = 0
  this.ss.i_m.heads[0].USER_LOG = this.ds.user_log;

  
  this.ss.i_m.heads[0].CUS_TYPE = 'NRML';
  this.ss.i_m.configs[0].ITEM_LENGTH = this.ss.i_m.items.length;
  this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = false;
  this.ss.i_m.heads[0].BILL_DATE = new Date().toISOString();
  this.ss.i_m.tails[0].TOTAL_PAID_TODAY = this.recamt_amt;
  this.ss.i_m.tails[0].TOTAL_DUE = this.bal_due;

  if(this.ss.i_m.tails[0].TOTAL_DUE <= 0)
  {
    this.ss.i_m.configs[0].IS_PARTIAL_PAY = false;
  }

  
      
  var cdb = 0;
  this.data_error_flag = 0;

  if(  this.ss.i_m.items.length === 0 )
  {

    this.data_error_flag = 1;
    this.data_error_string = 'No item in the list:  '+(cdb+1);

  }

  else
  {

  for(cdb ; cdb < this.ss.i_m.items.length ; cdb ++)
  {
 
   
 
    if( this.ss.i_m.items[cdb].FREE_ITEM === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'free Item should be zero or greater than zero:  '+(cdb+1);

    }




    if(  this.ss.i_m.items[cdb].PRICE === 0 || this.ss.i_m.items[cdb].PRICE === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Price should be greater than zero:  '+(cdb+1);

    }

 



    if(  this.ss.i_m.items[cdb].TAX === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Tax should not be Null on : '+(cdb+1);

    }

    if(  this.ss.i_m.items[cdb].QTY === null || this.ss.i_m.items[cdb].QTY === 0)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Quantity should not be Null or Zero on serial no :  '+(cdb+1);

    }
    
 

  }
  }
 

if(this.data_error_flag === 0)

{

  this.push_disabled_enter = true;


  this.ds.post_invoice(this.ss.i_m)
  .subscribe((jsonData) => { this.get_res(jsonData)
                  },(err) => console.error(err),
                 
                  );

}

else
{
  alert(this.data_error_string);
}

}


get_res(res:any)
{
  if( res.success === true)
  {

   this.ss.i_m.heads[0].INVOICE_NUMBER = res.mas_id;
    alert('Invoice Saved')
      window.print();

      this.router.navigate(['/dash']);
     

   

   }

   else
   {
     alert(res.msg)
     this.router.navigate(['/dash']);
   }
}
}