import { Component,OnInit } from '@angular/core';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { ExcelService } from '../../services/excel.service';
import { TableUtil } from "../auditor/tableUtil";

import { Router } from '@angular/router';
import { p_model } from './model';

import { items } from './model';
import { sublist } from './model';
@Component({
    selector: 'sales',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class SalesReport {
  
   
    data : any [] =[];
    post_invo = new items(1,[]);
    total_amount : number = 0;
    pdtlist : any [] = [];
    qty_list : any [] = [];
    amount_list : any [] = [];
    cats : any [] = [];
    items = [];
    itemCount = 0;
    to_date : string;
    from_date : string;
    isClickedOnce : boolean = false;


    view : boolean = false;


    length : number = 0;
    total_revenue : number =0;
     total_tax :number = 0;
     today: number = Date.now();
     total_due : number = 0;
     bool : boolean ;
     si : number = 0;
      j :number = 0;
      view_head : boolean = false
      total_stock_item : number = 0;
      tot_pur_val : number = 0;
      tot_sal_val : number = 0;
      tot_profit_val : number = 0;
     index : number = 0;
     view_cat : boolean = false;
     category : string = ''
     product_array : p_model [] = [];
       dateFormm = this.fb.group({                  
                                                  
                                                  from_date : '1987-09-10',
                                                  to_date: '2055-08-09',
                                                  cat : '',
                                                  option : 2
                                                 
                                              
                                         });

    constructor( private fb: FormBuilder,private es: ExcelService,
     private ds : DataService, private uS: UpdateService, private rs: Router ,private Rs: ReportService ) {
       
    }
ngOnInit() {
   

   this.get_cats();
   this.get_lists();

  
    
   
  }

 exportAsXLSX():void {
    // this.es.exportAsExcelFile(this.post_invo.item, 'sales_report');
    TableUtil.exportToExcel("Sales_Table_cat");
  }

get_lists()
{
   
  this.uS.item_price_detail_list()
 .subscribe((jsonData) => { this.getjson252(jsonData)
                      },(err) => console.error(err),
                      
                      );
}
  

  

      getjson252(json :any)
  {
    
    this.view = true;
    this.product_array = json;
    
   
  }







  change_cat(x:any)
  {
  
     
      this.category = x;

  }



search_by_cats()
{
     this.from_date = this.Rs.from_date ;
    this.to_date = this.Rs.to_date;

     this.isClickedOnce = true

    this.dateFormm.patchValue({from_date:  this.from_date, to_date: this.to_date })
  
    
    
    this.ds.get_sales_report(this.dateFormm.value)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );

}


  get_cats()
  {
        this.ds.get_product_categories()
       .subscribe((jsonData) => { this.getjson_cats(jsonData)
                      },(err) => console.error(err),
                      
                      );

  }

getjson_cats(json:any)
{
 
  
     this.cats[0] = 'All'

       for(var i = 0; i < json.length ; i++)
       {

         this.cats[i+1]= json[i].CATEGORY_NAME;
       }
    this.view_cat = true;

}





print_re()
{
  window.print();
}
 auto_push()
  {
        this.post_invo.item.push({ SI:1,DESCRIPTION:'',QUANTITY:0,PROFIT:0,UNIT:'pc',PUR_VALUE:0,SALE_VALUE:0})

               }



  getjson(s:any)
  {
     
     this.view = true;
     this.data = s;
      this.view_head = true
     this.length = this.data.length;
    
   this.express(this.length)

  }





  express(s: number)
  {
      
       for(var ix = 0; ix < s ; ix++)
       {
      
this.bool = this.check_list(this.data[ix].PRODUCT_DESCRIPTION);

if ( this.bool === false)
{
  this.pdtlist[this.j] = this.data[ix].PRODUCT_DESCRIPTION;
  this.qty_list[this.j] = this.data[ix].QUANTITY;


  this.auto_push();
  this.post_invo.si = this.j+1;
  this.post_invo.item[this.j].QUANTITY = this.data[ix].QUANTITY;
  this.post_invo.item[this.j].DESCRIPTION = this.data[ix].PRODUCT_DESCRIPTION;
  this.post_invo.item[this.j].SI = this.j+1;
  this.total_amount = this.total_amount + this.data[ix].AMOUNT;


  
 

 this.j = this.j + 1;
  
}
else 
{

 this.post_invo.item[this.index].QUANTITY = this.data[ix].QUANTITY + this.post_invo.item[this.index].QUANTITY;

 

}
       }
 
 this.total_stock_item = 0
 this.tot_pur_val = 0
  for(var ix = 0; ix < this.post_invo.item.length ; ix++)
       {
      
           var xp = this.product_array.filter(x=> x.DESCRIPTION === this.post_invo.item[ix].DESCRIPTION)

           this.post_invo.item[ix].PUR_VALUE = xp[0].PUR_PRICE * this.post_invo.item[ix].QUANTITY;
           this.post_invo.item[ix].SALE_VALUE = xp[0].PRICE * this.post_invo.item[ix].QUANTITY;
           this.post_invo.item[ix].PROFIT = this.post_invo.item[ix].SALE_VALUE  -  this.post_invo.item[ix].PUR_VALUE 


           this.total_stock_item = (this.total_stock_item-0) + (this.post_invo.item[ix].QUANTITY-0);
           this.tot_pur_val = (this.tot_pur_val-0) + (this.post_invo.item[ix].PUR_VALUE-0);
           this.tot_sal_val = (this.tot_sal_val-0) + (this.post_invo.item[ix].SALE_VALUE-0);
           this.tot_profit_val = (this.tot_profit_val-0) + (this.post_invo.item[ix].PROFIT-0);
        } 



    
  }

 check_list( a :any) : boolean
 {

  var i =0; var k = 0;
   for(i;i<this.pdtlist.length;i++)
                 {
                       if(this.pdtlist[i] === a )
                       {
                          k = 1;
                          this.index = i;
                          return true;
                       }
                 }
     if (k === 0)
     {
       return false;
     }
  
 }
print_report()
{
  window.print();
}
 
}