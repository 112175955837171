import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-company-main',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Company_main implements OnInit {

    company_Form: FormGroup;
    isClickedOnce : boolean = false

  constructor( private fb: FormBuilder, private ds :DataService, private router : Router ) { } 

  ngOnInit() {
  
   
  




      this.company_Form = this.fb.group({ 

        company_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      gst_in : '',
      ph1 :'',
      ph2 : '',
      adr_1 : '',
      adr_2 : '',
      adr_3 : '',
      email : '',
      b_name : '',
      b_branch : '',
      b_acn : '',
      b_ifsc : ''

      
    });


document.getElementById("defaultOpen").click();
    
    
  }

  openCity(evt, cityName) {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }
  


  add_company()
  {
    this.isClickedOnce = true
    console.log('hit')

    this.ds.add_company(this.company_Form.value)
    .subscribe((jsonData) => { this._res_add_com(jsonData)  
                     },(err) => console.error(err),
                      
                     );

  }

  _res_add_com(json:any)
  {

    if(json.sucess === true)
    {
      alert(json.msg)
    }

    else
    {
      alert(json.msg)

    }

    
  }
  



}