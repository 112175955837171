export class items {
    constructor(
      
           public si : number ,
           public item : sublist[],
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  public SI : number,
  public DESCRIPTION : string,
  public SALES_QTY : number,
  public SALES_RET_QTY : number,
  public PURCHASE_QTY : number,
  public PURCHASE_QTY_MIS : number,
  public PUR_RET_QTY : number,
  public OPENING_QTY : number,
  public TOTAL_QTY : number
  
    ) {  }
  }
  

  export class p_model 
  {
    constructor(
      public DESCRIPTION: string,
      public CATGERY: string,
      public ID: number,
      public OPEN_QTY : number
      
    )
    { }
  }


  export class p_model_rep 
  {
    constructor(
      public p_model_slave_array : p_model_slave[]
      
    )
    { }
  }


  
  export class p_model_slave 
  {
  constructor(
            
            public DESCRIPTION: string,
            public PRO_ID : number,
            public CATGERY: string,
            public SALES_QTY : number,
            public SALES_QTY_E : number,
            public SALES_RET_QTY : number,
            public PUR_QTY : number,
            public PUR_QTY_MIS : number,
            public PUR_RET_QTY : number,
            public OPEN_QTY : number,
            public SI : number,
            public BINNED_QTY : number,
            public CURR_QTY : number

        
           
            
  ) { }
  }
  