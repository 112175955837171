export class items {
  constructor(
    
         public si : number ,
         public item : sublist[],
        
       
      
    
  ) {  }
}


export class sublist 
{
 constructor(
public SI : number,
public DESCRIPTION : string,
public QUANTITY : number,
public PROFIT : number,
public UNIT : string,
public PUR_VALUE : number,
public SALE_VALUE : number

  ) {  }
}


export class p_model 
{
constructor(
          
          public DESCRIPTION: string,
          public CATGERY: string,
          public PRICE : number,
          public NET_PRICE : number,
          public PUR_PRICE : number,
          public AVAIL_QTY : number
      
         
          
) { }
}
