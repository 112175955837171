import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { SharingService } from '../../../services/sharing.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { exp_invo } from './model';

import { pdt_array } from './model';
import { vendor_model } from './model';


@Component({
  selector: 'app-expence-main-misce-edit',
  templateUrl: './edit.html',
  styleUrls: ['./edit.css']
})
export class Purchase_main_misce_edit implements OnInit {

  catForm: FormGroup;
  customer_u_Form: FormGroup;
  customer_d_Form: FormGroup;
  arrayOfCusValues : any[] =[];
  is_rec_no : number = 1;
  exp_entry_no : number = 1;
  from_date : string;
  is_itemss : number = 1;
  purchases : any [] = [];
  exp_invos = new exp_invo(1,'Purchase',0,0,[],'','',0,0,0,0,0,'','','',0);
  arrayOfKeyValues :  any [] =[];
   k : number = -1;
   up_click_once : boolean = false;

   product_array : pdt_array[] =[];
  
   arrayOfvendorValues :  any [] =[];
   j : number = 0 ;
   grand_total : number = 0;
   cats :any[] = [];
   vendsi : any = null
   vends : vendor_model[] = null
   total_tax_per : number = 0;
   net_amttt : number = 0;
   date_change_flag : number = 0;
   load_block : number = 0;
   modal: any ;
   batch_no : string = null;
   exp_date : Date;
   post_model = {"item":null,e_no:null}


  constructor( private fb: FormBuilder, private ds :DataService, private router : Router,private ss: SharingService ) { } 

  ngOnInit() {
  
  
     this.get_products();      
     
    //   this.get_vendors();
     //  this.addItem();
       this.get_cat_list();
       this.get_purchase_details_misc();
    
    this.catForm = this.fb.group({ 

      Category_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      
      
   });



      this.customer_d_Form = this.fb.group({ 

      customer_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      street : '',
      city : '',
      gstin :'',
      ph1 :'',
      ph2 : '',
      opbal : '',
      last_invo_num : ''
      
    });



    
    
  }


  get_purchase_details_misc()
  {
   this.post_model.e_no = this.ss.i_m.heads[0].INVOICE_NUMBER;

   this.ds.get_purchase_details_misc(this.post_model)
   .subscribe((jsonData) => { this.getjsonpy(jsonData)
                   },(err) => console.error(err)
                  
                   );



    
  }
  

  getjsonpy(js:any)
  {
    console.log('js',js);
    this.make_master(js.master);
    this.make_slave(js.slave);
  }

  make_master(data:any)
  {

this.exp_invos.VENDOR = data.VENDOR;
this.exp_invos.STREET = data.STREET;
this.exp_invos.DATE = data.EXP_DATE;
this.date_change_flag = 2;
this.exp_invos.GRAND_TOTAL = data.TOTAL_AMOUNT;
this.exp_invos.round_off = data.ROUND_OFF;
this.exp_invos.BILL_NO = data.BILL_NO;
this.exp_invos.E_ENTRY_NUMBER = data.EXPENCE_ENTRY_NO;




  }

  make_slave(data:any)
  {
    
    for(var i=0;i<data.length;i++)
    {
      this.addItem();
      this.exp_invos.ITEMS[i].DESCRIPTION = data[i].DESCRI_NAME;
      this.exp_invos.ITEMS[i].UNIT = data[i].UNIT;
      this.exp_invos.ITEMS[i].PRICE = data[i].PRICE;
      this.exp_invos.ITEMS[i].CATEGORY = data[i].CATEGORY;
      this.exp_invos.ITEMS[i].PRO_ID = data[i].PRO_ID;    
      this.exp_invos.ITEMS[i].QTY = data[i].QUANTITY;  

    }


  }


 




change_opt(bi : any)
{
  this.exp_invos.E_CATEGORY = bi;
}



getjson49(json :any)
  {
   alert(json.msg);
   this.router.navigate(['/dash']);
  }


getjson45(json :any)
  {
   this.arrayOfCusValues = json;
  }
get_exp_type_list()
{
   this.ds.get_exp_type_list()
      .subscribe((jsonData) => { this.getjsonss(jsonData)
                      },(err) => console.error(err)
                     
                      );

}

getjsonss(json :any)
  {
    
   
   for( var i = 0 ; i < json.length; i++)
  this.purchases[i] = json[i].CATEGORY_NAME;
  }



 get_exp_reciept_no()
 {
    this.ds.get_exp_rec_no()
              .subscribe((jsonData) => { this._get_rec_no(jsonData)
                      },(err) => console.error(err),
                       
                      );
 }
_get_rec_no(json :any)
        {     
            console.log('ci',json);
            this.exp_invos.E_ENTRY_NUMBER = json.count+1
            this.is_rec_no = 1;
        
       
         }


   get_products()
            {
              this.ds.get_products_list()
              .subscribe((jsonData) => { this._get_products(jsonData)
                      },(err) => console.error(err),
                        
                      );
           }

      _get_products(json :any)
        {     
            this.arrayOfKeyValues = json;
            this.product_array = json;
            console.log('json',this.product_array);
           
       
         }

         get_vendors()
         {
          this.ds.get_vendors_all()
          .subscribe((jsonData) => { this._get_vendors(jsonData)
                  },(err) => console.error(err),
                    
                  );
         }

         _get_vendors(json:any)
         {
           console.log(json)
           this.arrayOfvendorValues = json
         
           this.vends = json;
         }

addItem()
{
  this.exp_invos.ITEMS.push({SI_NO:1,CATEGORY:'',HSN_CODE:'',IGST_P:0,SGST_P:0,CGST_P:0,IGST_AMT:0,CGST_AMT:0,SGST_AMT:0,BATCH_NO:null,EXPIRY_DATE:null,
    DESCRIPTION:'', QTY:1,UNIT:'',PRICE:0,NET_PRICE:0,PRO_ID:0,FREE_QTY:0})

    this.load_block = 2;

}

add_cat()
{
  console.log(this.catForm.value);
  this.ds.add_expense_category(this.catForm.value)
      .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err)
                      
                      );


}
 getjson(json :any)
  {
    
  
   if (json.msg ==='Successfully saved')
   {
      alert('Category added sucessfully')
      this.router.navigate(['/dash']);
   }

   else if (json.msg === 'Category name already existed')
   {
      alert('Category already existed')
      this.router.navigate(['/dash']);
   }
  }



  get_cat_list()
  {

    
  
  
    this.ds.get_product_category()
    .subscribe((jsonData) => { this.get_res_cat_list(jsonData)
                    },(err) => console.error(err),
                   
                    );

  }
  get_res_cat_list(json:any)
  {
    console.log(json)
    for(var jp=0; jp<json.length;jp++)
    {
      this.cats[jp] = json[jp].CATEGORY_NAME

    }
    
   }
  

vendor_blur(vn:any)
{

this.exp_invos.VENDOR = vn;

this.exp_invos.STREET = this.vendsi.STREET
this.exp_invos.GST_IN = this.vendsi.GSTIN
this.exp_invos.CUS_ID = this.vendsi.ID
}

change_date()
{
  this.date_change_flag = 0;
}








focus_one(index:number)
{
  this.k = index ;
  this.exp_invos.ITEMS[this.k].SI_NO = index + 1;
}

  change_one(item_name:any)
{
  
    let s : any; 
s = this.product_array.filter(xi=> xi.DESCRIPTION === item_name);

console.log('s',s)

if(s.length > 0)
{

this.exp_invos.ITEMS[this.k].CATEGORY = s[0].CATGERY;
this.exp_invos.ITEMS[this.k].UNIT = s[0].UNIT;
this.exp_invos.ITEMS[this.k].DESCRIPTION = s[0].DESCRIPTION;
this.exp_invos.ITEMS[this.k].PRO_ID = s[0].ID; 

this.exp_invos.ITEMS[this.k].HSN_CODE = s[0].HSN_CODE;


this.exp_invos.ITEMS[this.k].PRICE = s[0].PUR_PRICE ; 

 }


   
 else

 {
   alert('Wrong Product name')
 }    
   
 this.cal_net_price();     
 }



 change_qty(qty:any)
 {
   this.exp_invos.ITEMS[this.k].QTY = qty
   
        this.cal_net_price();
   
 }

 change_free_qty(fq:any)
 {
  this.exp_invos.ITEMS[this.k].FREE_QTY = fq
   
  this.cal_net_price();
 }

 change_price(price:any,k:any)
 {
    this.k = k;
   this.exp_invos.ITEMS[this.k].PRICE = price;

  
   this.cal_net_price();
    
 }

cal_net_price()
{


   

  this.exp_invos.ITEMS[this.k].NET_PRICE =   this.exp_invos.ITEMS[this.k].PRICE * this.exp_invos.ITEMS[this.k].QTY 
  
   

   this.exp_invos.ITEMS[this.k].NET_PRICE =  Math.round(this.exp_invos.ITEMS[this.k].NET_PRICE * 100) / 100;
 

   this.exp_invos.GRAND_TOTAL = 0; 



   for(this.j=0;this.j<this.exp_invos.ITEMS.length;this.j++)
  {

    this.exp_invos.GRAND_TOTAL  = this.exp_invos.GRAND_TOTAL  + this.exp_invos.ITEMS[this.j].NET_PRICE 
   


  }

  

     
   this.exp_invos.GRAND_TOTAL =  (this.exp_invos.GRAND_TOTAL-0) + (this.exp_invos.round_off-0)
}

catch_invoice_date(s:any)
{ 
    console.log('s',s)
  // this.exp_invos.DATE = this.exp_invos.DATE.;
   this.date_change_flag = 2;

}

cat_to_date(s:any)
{ 
    
   this.exp_invos.DATE = s._validSelected.toLocaleDateString();

}

cus_blur(name:any)
{
  this.exp_invos.VENDOR =name;
}
billno_blur(no:any)
{
  this.exp_invos.BILL_NO = no
}









removeItem(ii:any)
{
  console.log('hi')
  this.exp_invos.ITEMS.pop()
  
}





Save_purchase()
{
    

  if(this.exp_invos.DATE === '' ||  this.exp_invos.DATE === null)
  {
    alert('select Date')
  }

  else

  {
   // this.up_click_once = true;
     console.log('model',this.exp_invos)
  this.ds.update_purchase_entry_without(this.exp_invos)
  .subscribe((data) => { this.res(data)
                      },(err) => console.error(err),
                       
                     );
  }

}


res(json:any)
{
  console.log('j',json)
  if (json.msg === "Successfully saved")
  
   alert('Purchase Saved')

 this.router.navigate(['/dash']);

}



   
}