export class p_model 
{
constructor(
          
          public PRODUCT_NAME: string,
          public HSN_CODE: string,
          public AVAIL_QTY : number,
          public UNIT : string
      
         
          
) { }
}








export class revenue_invo_model {
  constructor(
    
         public si : number ,
         public item : revenue_invo_model_sub[],
        
       
      
    
  ) {  }
}


export class revenue_invo_model_sub 
{
 constructor(


public SI : number,
public BILL_DATE : string,
public INVOICE_NUMBER : number,
public CUSTOMER_NAME : string,
public GRAND_TOTAL : number,
public TOTAL_TAX : number,




  ) {  }
}





export class purchase_invo_model {
  constructor(
    
         public si : number ,
         public item : purchase_invo_model_sub[],
        
       
      
    
  ) {  }
}


export class purchase_invo_model_sub 
{
 constructor(


public SI : number,
public EXPENCE_ENTRY_NO : number,
public EXP_DATE : string,
public EXPENCE_CATEGORY : string,
public VENDOR : string,
public BILL_NO : string,
public TOTAL_AMOUNT : number,




  ) {  }
}




export class exp_invo_model {
  constructor(
    
         public si : number ,
         public item : exp_invo_model_sub[],
        
       
      
    
  ) {  }
}


export class exp_invo_model_sub 
{
 constructor(


public SI : number,
public EXPENCE_ENTRY_NO : number,
public EXP_DATE : string,
public EXPENCE_CATEGORY : string,
public VENDOR : string,
public BILL_NO : string,
public TOTAL_AMOUNT : number,




  ) {  }
}










