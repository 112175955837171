export class payment_model {
    constructor(
      
  public pay_number : number,
  public cus_id : number,
  public vendor_street : string ,
  public vendor_city : string,
  public vendor_name : string,
  public cash_date : string,
  public entry_date : string,
  public amount_payed : number ,
  public due_balance : number ,
  public cheque_Date : string ,
  public bank_name : string ,
  public cheque_number : number,
  public balance_amt : number,
  public credit_bal : number,
  public is_payment :boolean,
  public is_cheque_payment : boolean,
  public old_payment : number,
  public rem_due : number
  
   
      
    ) {  }
  }