import { Component,OnInit } from '@angular/core';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import { TableUtil } from "../auditor/tableUtil";

 import { list } from './model';
 import { sssl } from  './model';



@Component({
    selector: 'cus-sale-report',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class Customer_sales_report {
  
 
    arrayOfCusValues : any[] =[];
    arrayOfdataValues : any[] =[];
    pdt_list : string[] = []; 
    pdt_count : number = 0;
    num_list : any[] = [];
    data : any [] =[];
    delta_view : boolean = false;
   // post_invo = new items(1,[]);
    total_sub_total : number = 0;
    total_tax : number =0 ;
    items = [];
    itemCount = 0;
    to_date : string;
    from_date : string;
    view : boolean = false;
    length : number = 0;
    total_grand : number =0;
    clicked : number = 0;
    main_index : number = 0;
    disp : number = 0;

     master_model = new list(1,[])

     delta = new sssl([])
     melta = new sssl([])
    
    Total_amt_all : number = 0;
    Total_payed_all : number = 0;
    Total_due_all : number = 0;
     bool : boolean ;
     si : number = 0;
     j :number = 0;
     index : number = 0;
     cus_name : string = '';
     cus_city : string = '';
     cus_gst : string ='';
    booldd : boolean = false;
     slaveForm = this.fb.group({
     
       invoice_number : '',
       is_b2b : '',
       cus_gst : ''


     });
       stateForm = this.fb.group({                  
                                                  
                                                  from_date : '',
                                                  to_date: '',
                                                  cus_name: '',
                                                  cus_id:'',
                                                  cus_street:'',
                                                  city:'',
                                                  gstin:'',
                                                  option : 2,
                                                  is_b2b : false
                                                 
                                              
                                         });

    constructor( private fb: FormBuilder, private ds : DataService, private uS: UpdateService,
       private rs: Router ,private Rs: ReportService ) {
       
    }
ngOnInit() {
   
 //  this.get_reports();
    this.from_date = this.Rs.from_date ;
    this.to_date = this.Rs.to_date;
    this.stateForm.patchValue({from_date:  this.from_date, to_date: this.to_date })
    this.get_customers();
    this.index = 0 ;
   
  }
  exportAsXLSX():void
  {
    TableUtil.exportToExcel("Sales_Table_cus");
  }

 

// print_re()
// {
//   window.print();
// }





cus_blur(xx:any)
{

 let s : any; 

s = this.arrayOfCusValues.filter(xi=> xi.CUSTOMER_NAME === xx);
this.stateForm.patchValue({cus_name :s[0].CUSTOMER_NAME }) ;
this.stateForm.patchValue({cus_street :s[0].STREET }) ;
this.stateForm.patchValue({city :s[0].CITY }) ;
this.stateForm.patchValue({gstin :s[0].GSTIN }) ;
this.stateForm.patchValue({cus_id : s[0].ID})

this.cus_name = s[0].CUSTOMER_NAME;
this.cus_city = s[0].CITY
this.cus_gst = s[0].GSTIN;


if(this.cus_gst != '' && this.cus_gst != null )
{
  
 this.stateForm.patchValue({is_b2b: true}); 

}
else
{
  
  this.stateForm.patchValue({is_b2b: false}); 
}


}


 get_customers()
{
  
  this.ds.get_customers_list_b2b()
  .subscribe((jsonData) => { this.getjson5(jsonData)
                      },(err) => console.error(err),
                    
                      );
}
getjson5(json :any)
  {
    


  this.arrayOfCusValues = json;
   
  
   
}


generate_report()
 {
   this.disp = 1 ;
   this.clicked = this.clicked + 1;
   
this.ds.get_master_cus_sales_report(this.stateForm.value)
.subscribe((jsonData) => { this.getjson16(jsonData)
                      },(err) => console.error(err),
                    
                      );

 }


getjson16(js:any)
{

   


this.num_list = js;



   this.ds.get_master_cus_sales_report_slaves(this.stateForm.value)
.subscribe((jsonData) => { this.getjson17(jsonData)
                      },(err) => console.error(err),
                    
                      );
}

getjson17(js:any)
{
  
  




  var zero = 0

 for(var p = 0 ; p<js.length;p++)
      {

          
          
          this.bool = this.check_list(js[p].MASTER_ID);

           if(this.bool === true)

           {
               this.push_delta()

                this.delta.items[zero].PRODUCT_DESCRIPTION = js[p].PRODUCT_DESCRIPTION
                this.delta.items[zero].UNIT = js[p].UNIT
                this.delta.items[zero].MASTER_ID = js[p].MASTER_ID
                this.delta.items[zero].QUANTITY = js[p].QUANTITY
                this.delta.items[zero].NET_PRICE = js[p].NET_PRICE
                zero = zero + 1

           }






      }



       var zoo = 0

       for(var pc = 0 ; pc<this.delta.items.length;pc++)

       {
         if(zoo === 0)
         {

           this.pdt_list[0] = this.delta.items[0].PRODUCT_DESCRIPTION
           this.push_melta()
           this.melta.items[0].PRODUCT_DESCRIPTION = this.delta.items[0].PRODUCT_DESCRIPTION
           this.melta.items[0].NET_PRICE = this.delta.items[0].NET_PRICE
           this.melta.items[0].UNIT = this.delta.items[0].UNIT
           this.melta.items[0].MASTER_ID = this.delta.items[0].MASTER_ID
            this.melta.items[0].QUANTITY = this.delta.items[0].QUANTITY
            

            this.melta.items[0].SI = 1
           zoo = zoo + 1

         }

        else
        {

           this.booldd = this.check_dist(this.delta.items[pc].PRODUCT_DESCRIPTION);


            if(this.booldd === true)
              {
             


                  this.melta.items[this.index].PRODUCT_DESCRIPTION =  this.delta.items[pc].PRODUCT_DESCRIPTION
                  this.melta.items[this.index].UNIT = this.delta.items[pc].UNIT
                  this.melta.items[this.index].NET_PRICE = this.delta.items[pc].NET_PRICE
                  this.melta.items[this.index].QUANTITY = (this.melta.items[this.index].QUANTITY-0) + (this.delta.items[pc].QUANTITY-0)
                  
              }

              else
              {
                 this.pdt_list[zoo] = this.delta.items[pc].PRODUCT_DESCRIPTION
                 this.push_melta()
                 this.melta.items[zoo].PRODUCT_DESCRIPTION = this.delta.items[pc].PRODUCT_DESCRIPTION
                 this.melta.items[zoo].UNIT =  this.delta.items[pc].UNIT
                 this.melta.items[zoo].NET_PRICE = this.delta.items[pc].NET_PRICE
                 this.melta.items[zoo].QUANTITY = this.delta.items[pc].QUANTITY
                  
                 this.melta.items[zoo].SI = zoo+1
                 zoo = zoo + 1

              }
        }

         
        // this.booldd = this.check_dist(this.delta.items[pc].PRODUCT_DESCRIPTION);
         
         
       }





this.view = true




  
for(var tp =0; tp<this.melta.items.length;tp++)
{
   
  this.melta.items[tp].SALE_VALUE = this.melta.items[tp].QUANTITY * this.melta.items[tp].NET_PRICE



  this.total_tax = (this.total_tax-0) + (this.melta.items[tp].QUANTITY-0)
  this.total_sub_total = (this.total_sub_total-0) + (this.melta.items[tp].SALE_VALUE-0)
}



}






 check_dist( a :any) : boolean
 {


   
  var i =0; var k = 0;
   

   for(i;i<this.pdt_list.length;i++)
                 {

                  


                       if(this.pdt_list[i] === a )
                       {
                          k = 1;
                          this.index = i;
                          return true;
                       }
                 }
     if (k === 0)
     {
       return false;
     }
  
 }



 check_list( a :any) : boolean
 {

   
  var i =0; var k = 0;
   

   for(i;i<this.num_list.length;i++)
                 {

                   


                       if(this.num_list[i] === a )
                       {

                         
                          k = 1;
                          this.index = i;
                          return true;
                       }
                 }
     if (k === 0)
     {
       return false;
     }
  
 }



push_delta()
{
  this.delta.items.push({SI:null,UNIT:null,MASTER_ID:null,MASTER_STRING:null,IS_B2B:null,QUANTITY:null,NET_PRICE:null,PRODUCT_DESCRIPTION:null,SALE_VALUE:0})
}


push_melta()
{
   this.melta.items.push({SI:null,UNIT:null,MASTER_ID:null,MASTER_STRING:null,IS_B2B:null,QUANTITY:null,NET_PRICE:null,PRODUCT_DESCRIPTION:null,SALE_VALUE:0})
}

getjson15(mas: any)
{
  
 
   for(var i = 0; i < mas.length ; i++)
       {
  this.push_master();
  this.master_model.item[i].SI = i +  1 ;
  //this.master_model.item[i].BILL_DATE = mas[i].BILL_DATE;
  // this.master_model.item[i].INVOICE_NUMBER = mas[i].INVOICE_NUMBER;
  // this.master_model.item[i].ITEM_LENGTH = mas[i].ITEM_LENGTH;
  // this.master_model.item[i].SUB_TOTAL = mas[i].SUB_TOTAL
  // this.master_model.item[i].TAX_COLLECTED = mas[i].TAX_COLLECTED;
  // this.master_model.item[i].GRAND_TOTAL = mas[i].GRAND_TOTAL

  
  this.total_sub_total = (this.total_sub_total-0) + (mas[i].GRAND_TOTAL-0)
  

  this.total_tax = (this.total_tax-0) + (mas[i].TAX_COLLECTED-0);

      }
 
   this.view = true;

}




  print_report()
  {
    window.print()
  }

 push_master()
 {
   this.master_model.item.push({SI:0,DESCRIPTION:null,QTY:0,UNIT:null,PRICE:0,SALES_VALUE:0});
 }

// sub_push()
 // {
 //  this.slave_model.item.push({"SI": 1,"RECIEPT_NO":1,"CASH_DATE":"","IS_CASH_PAY":"CASH","AMOUNT_PAYED":0,

 //    "AMOUNT_DUE":0,"CHEQUE_NO":null,"CHEQUE_DATE":"","BANK_NAME":"",IS_SALES_RET:false});
 // }

}