import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
@Component({
  selector: 'app-add-product',
  templateUrl: './add_product.html',
  styleUrls: ['./add_product.css']
})
export class Add_product implements OnInit {

 productForm: FormGroup;
 product_add_Form : FormGroup;
 d_ : number = 0;
 snak_msg : string = '';
 modal : any;
 btn : any;
 span : any;
 cats :any[] = [];
 myModal_close : any;
 units :  any[] = [];
 
     constructor(private router: Router, private ds: DataService ,private fb: FormBuilder) {
    
     
    
  }
  

  ngOnInit() {

    this.get_all_category()
    this.get_all_units()

    this.modal = document.getElementById("myModal");

// Get the button that opens the modal
this.btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
this.span = document.getElementsByClassName("close")[0];


this.product_add_Form = this.fb.group({ 

  cat_name : ['']

})

    this.productForm = this.fb.group({ 

      // product_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
      // hsn_code : ['',[<any>Validators.required, <any>Validators.minLength(1),<any>Validators.maxLength(10)]],
      hsn_code : [''],
      bar_code : [''],
      category : null,
      mrp_price:0,
      description : null,
      opening_stock : [''],
      tax : 18,
      price:[''],
      net_price:0,
      unit:['Nos'],
      alt_qty :0,
      alt_unit:'',
      pur_price:0,
      e_price : 0,
      e_net_price:0
    
    });
    this.enter_def_tax();
  }

  get_all_units()
  {
      this.ds.get_all_units()
     .subscribe((jsonData) => { this.get_units_list(jsonData)
                     },(err) => console.error(err),
                    
                     );
  }

 
 get_units_list(js:any)
 {
     for(var jsp=0; jsp<js.length;jsp++)
     {
       this.units[jsp] = js[jsp].UNIT_NAME
 
     }
     
 }
  get_all_category()
  {
    this.ds.get_product_category()
    .subscribe((jsonData) => { this.get_res_cat_list(jsonData)
                    },(err) => console.error(err),
                   
                    );

  }
  get_res_cat_list(json:any)
  {
    
    for(var jp=0; jp<json.length;jp++)
    {
      this.cats[jp] = json[jp].CATEGORY_NAME

    }
    
   }



  open_modal()
  {
    
    this.modal.style.display = "block";
  }
  close_modal()
 {
  this.modal.style.display = "none";
 }
  
 enter_def_tax()
  {

    this.productForm.patchValue({opening_stock:0,price:0 });
  }

  add_cat()
  {

   

    this.ds.add_product_category(this.product_add_Form.value)
    .subscribe((jsonData) => { this.get_res_cat(jsonData)
                    },(err) => console.error(err),
                   
                    );
  }
  get_res_cat(json:any)
  {
   
      this.snak_fun(json.msg);
      this.get_all_category();

    
  }

  cal_price()
   {


   
     this.d_ = this.productForm.controls.net_price.value * 100 / (100 + this.productForm.controls.tax.value);
 
     this.productForm.patchValue({price : this.d_ });

       

   }


   cal_price_e()
   {

      
    this.d_ = this.productForm.controls.e_net_price.value * 100 / (100 + this.productForm.controls.tax.value);
 
    this.productForm.patchValue({e_price : this.d_ });

      

   }


  add_product()
  {

    if(this.productForm.controls.description.value === null)
    {
      this.snak_fun('Empty desciption field');
    }

    else
    {

       
    if(this.productForm.controls.category.value === null)
    {
      this.snak_fun('Empty Category field');
    }    

    else

    {


      if(this.productForm.controls.category.value === null)
      {
        this.snak_fun('Empty Category field');
      } 
    

           this.ds.add_product(this.productForm.value)
           .subscribe((jsonData) => { this.get_res(jsonData)
                      },(err) => console.error(err),
                     
                      );

    }

  }


 
  }

  get_res(json:any)
  {
    var x = 1
      if(x > 0)
      {
       this.snak_fun(json.msg); 
       x = -2;

        this.productForm.patchValue({description : null , category : null,
        hsn_code : null,id: null,
       net_price : 0, tax : 0 , opening_stock:0,
                avail_qty : 0 , unit : 'Nos',price : 0 , alt_qty : 0 , alt_unit : null , pur_price : 0 });
      }
   
    
  }
snak_fun(msg:string)
{
      this.snak_msg = msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
    
}


}