import { Component,OnInit } from '@angular/core';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { SharingService } from '../../services/sharing.service';
import { Router } from '@angular/router';

import { TableUtil } from '../auditor/tableUtil';

import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';

import { loaded_list , items }  from './model'; 
import { dummy } from './model';
 



@Component({
    selector: 'cus-auditor-report-tax',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class Auditor_tax_statement {
  
     
    dummy_ = new dummy([]);


    dummy_taxable_tot : number = 0;
    dummy_taxable_cgst : number = 0;
    dummy_taxable_sgst : number = 0;
    dummy_net_price : number = 0;
    dummy_tax_rate : number = 0;
    dummy_price : number = 0;
    dummy_qty : number = 0;


    load_ =   new loaded_list([],[])
    load_copy =   new loaded_list([],[])


      option : any;
    post_model : any = {invo_string:null,option:false,from_date:'',to_date: '',is_b2b:true}
    click : number = 0;

    

    data : any [] =[];
    data2 : any[] = [];

    total_tax_ret : number = 0;
    total_tax_qty : number = 0;
    temp_ : number = 0;
   
  
   
   
    
    
    view : boolean = true;
    view1 : boolean = false;



  
    loop1 : number = 0;
    loop2 : number = 0;
    loop3 : number = 0;
    loop4 : number = 0;
    loop5 : number = 0;
    loop6 : number = 0;


    length : number = 0;
    total_sub_total : number =0;
    total_tax : number =0;
    total_grand : number = 0;
    total_cess : number = 0;
    total_sub_total_c : number = 0;
    total_tax_c : number = 0;
    total_grand_c : number = 0;
    d_ : number = 0 ;
    i: number = 0;
    run : number = 0;
    index : number = 0;
     total_cess_ret : number = 0;
     view_cat : boolean= true;

     post_invo = new items(0,[])

     true_invo = new items(0,[]);

 

     bool : boolean ;
     dummy : boolean = false;

     cats : any [] = ['B2B','B2C'];
     isClickedOnce : boolean = true;
    


    constructor( private fb: FormBuilder, private ds : DataService, 
      private uS: UpdateService, private rs: Router ,private Rs: ReportService,private ss : SharingService ) {
       
    }
ngOnInit() {
   
   this.get_def_fin_yr();
   this.isClickedOnce = false;

   this.pu_model();
  }


  exportTable(){
    TableUtil.exportToExcel("ExampleTable_");
  }



  pu_model()
  {
  this.dummy_.DARAY.push({SI:null,NAME:'',INVOICE_NUMBER:null,GSTIN:null,DATE:null,TOTAL_TAXABLE:null,ZERO_P_TAXABLE:null,
  ZERO_P_CGST:null,ZERO_P_SGST:null,ZERO_P_TAX:null,T12_P_CGST:null,T12_P_SGST:null,T12_P_TAX:null,T12_P_TAXABLE:null,
  T18_P_CGST:null,T18_P_SGST:null,T18_P_TAX:null,T18_P_TAXABLE:null,T28_P_CGST:null,
  T28_P_SGST:null,T28_P_TAX:null,T28_P_TAXABLE:null,T5_P_CGST:null,T5_P_SGST:null,T5_P_TAX:null,T5_P_TAXABLE:null,CESS:null})
  }

 

  change_cat(val:any)
  {
    if(val === 'B2B')
    {
      this.post_model.is_b2b = true;
    }
    else
    {
    this.post_model.is_b2b = false;
    }
  }

  get_def_fin_yr()
  {
    this.ds.get_default_invoice_string()
    .subscribe((jsonData) => { this._get_invo_string(jsonData) 
      },(err) => console.error(err));
  }

  _get_invo_string(json:any)
  {
   
  
    this.post_model.invo_string = json.STRING_NAME;
    
  }

generate_report()
{

  this.isClickedOnce = true;
  this.click = 1;
   this.post_model.from_date = this.Rs.from_date;
   this.post_model.to_date = this.Rs.to_date;
  

   
       this.ds.get_audit_report_tax(this.post_model)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );




}

make_mm(data:any)
{
    console.log('jsm',data.master);
    console.log('jsm',data.slave);

    
  for(this.loop1=0;this.loop1<data.master.length;this.loop1++)
  {
    this.dummy_.DARAY[this.loop1].INVOICE_NUMBER = data.master[this.loop1].INVOICE_NUMBER;
    this.dummy_.DARAY[this.loop1].CESS = data.master[this.loop1].CESS_COLLECTED;
    this.dummy_.DARAY[this.loop1].GSTIN = data.master[this.loop1].CUSTOMER_GST_IN;
    this.dummy_.DARAY[this.loop1].NAME = data.master[this.loop1].CUSTOMER_NAME;
    this.dummy_.DARAY[this.loop1].DATE = data.master[this.loop1].BILL_DATE;



    this.check_zero(data.slave,this.dummy_.DARAY[this.loop1].INVOICE_NUMBER);
    this.check_5(data.slave,this.dummy_.DARAY[this.loop1].INVOICE_NUMBER);
    this.check_12(data.slave,this.dummy_.DARAY[this.loop1].INVOICE_NUMBER);
    this.check_18(data.slave,this.dummy_.DARAY[this.loop1].INVOICE_NUMBER);
    this.check_28(data.slave,this.dummy_.DARAY[this.loop1].INVOICE_NUMBER);

    this.pu_model();

   
 
  }  

 

  this.net_taxable(data.master.length);



}

check_zero(slave,i_n)
{
  this.dummy_taxable_tot = 0;

  slave = slave.filter(xy=>xy.MASTER_ID === i_n && xy.TAX === 0)
  
    console.log('slave',slave);
   if(slave.length > 0)
   {
        for(this.loop2=0;this.loop2<slave.length;this.loop2++)
        {
                    
              this.dummy_net_price = slave[this.loop2].NET_PRICE;
              this.dummy_qty = slave[this.loop2].QUANTITY;
              this.dummy_tax_rate = slave[this.loop2].TAX;

              
         this.dummy_price = this.dummy_net_price * 100 / (100 + this.dummy_tax_rate);
 
           
            this.dummy_taxable_tot =  this.dummy_taxable_tot +  (this.dummy_price *  this.dummy_qty) 
                  

        }

        this.dummy_.DARAY[this.loop1].ZERO_P_TAXABLE =    this.dummy_taxable_tot;
        this.dummy_.DARAY[this.loop1].ZERO_P_CGST =    0
        this.dummy_.DARAY[this.loop1].ZERO_P_SGST =    0;
        this.dummy_.DARAY[this.loop1].ZERO_P_TAX =    0;
        

    } 
   else
   {
    this.dummy_.DARAY[this.loop1].ZERO_P_TAXABLE = 0
    this.dummy_.DARAY[this.loop1].ZERO_P_CGST = 0
    this.dummy_.DARAY[this.loop1].ZERO_P_TAX = 0
    this.dummy_.DARAY[this.loop1].ZERO_P_SGST = 0

   }
  
}


check_5(slave,i_n)
{
  this.dummy_taxable_tot = 0;

  slave = slave.filter(xy=>xy.MASTER_ID === i_n && xy.TAX === 5)
  
    console.log('slave',slave);
   if(slave.length > 0)
   {
        for(this.loop2=0;this.loop2<slave.length;this.loop2++)
        {
                    
              this.dummy_net_price = slave[this.loop2].NET_PRICE;
              this.dummy_qty = slave[this.loop2].QUANTITY;
              this.dummy_tax_rate = slave[this.loop2].TAX;

              
         this.dummy_price = this.dummy_net_price * 100 / (100 + this.dummy_tax_rate);
 
           
            this.dummy_taxable_tot =  this.dummy_taxable_tot +  (this.dummy_price *  this.dummy_qty) 
                  

        }

        this.dummy_.DARAY[this.loop1].T5_P_TAXABLE =    this.dummy_taxable_tot;
        this.dummy_.DARAY[this.loop1].T5_P_CGST =    (this.dummy_.DARAY[this.loop1].T5_P_TAXABLE * 2.5 )/ 100
        this.dummy_.DARAY[this.loop1].T5_P_SGST =    (this.dummy_.DARAY[this.loop1].T5_P_TAXABLE * 2.5 )/ 100
        this.dummy_.DARAY[this.loop1].T5_P_TAX =    (this.dummy_.DARAY[this.loop1].T5_P_TAXABLE * 5 )/ 100
        

    } 
   else
   {
    this.dummy_.DARAY[this.loop1].T5_P_TAXABLE = 0
    this.dummy_.DARAY[this.loop1].T5_P_CGST = 0
    this.dummy_.DARAY[this.loop1].T5_P_SGST = 0
    this.dummy_.DARAY[this.loop1].T5_P_TAX = 0

   }

}


check_12(slave,i_n)
{
  this.dummy_taxable_tot = 0;

  slave = slave.filter(xy=>xy.MASTER_ID === i_n && xy.TAX === 12)
  
    console.log('slave',slave);
   if(slave.length > 0)
   {
        for(this.loop2=0;this.loop2<slave.length;this.loop2++)
        {
                    
              this.dummy_net_price = slave[this.loop2].NET_PRICE;
              this.dummy_qty = slave[this.loop2].QUANTITY;
              this.dummy_tax_rate = slave[this.loop2].TAX;

              
         this.dummy_price = this.dummy_net_price * 100 / (100 + this.dummy_tax_rate);
 
           
            this.dummy_taxable_tot =  this.dummy_taxable_tot +  (this.dummy_price *  this.dummy_qty) 
                  

        }

        this.dummy_.DARAY[this.loop1].T12_P_TAXABLE =    this.dummy_taxable_tot;
        this.dummy_.DARAY[this.loop1].T12_P_CGST =    (this.dummy_.DARAY[this.loop1].T12_P_TAXABLE * 6 )/ 100
        this.dummy_.DARAY[this.loop1].T12_P_SGST =    (this.dummy_.DARAY[this.loop1].T12_P_TAXABLE * 6 )/ 100
        this.dummy_.DARAY[this.loop1].T12_P_TAX =    (this.dummy_.DARAY[this.loop1].T12_P_TAXABLE * 12 )/ 100
        

    } 
   else
   {
    this.dummy_.DARAY[this.loop1].T12_P_TAXABLE = 0
    this.dummy_.DARAY[this.loop1].T12_P_CGST = 0
    this.dummy_.DARAY[this.loop1].T12_P_SGST = 0
    this.dummy_.DARAY[this.loop1].T12_P_TAX = 0

   }

}


check_18(slave,i_n)
{
  this.dummy_taxable_tot = 0;

  slave = slave.filter(xy=>xy.MASTER_ID === i_n && xy.TAX === 18)
  
    console.log('slave',slave);
   if(slave.length > 0)
   {
        for(this.loop2=0;this.loop2<slave.length;this.loop2++)
        {
                    
              this.dummy_net_price = slave[this.loop2].NET_PRICE;
              this.dummy_qty = slave[this.loop2].QUANTITY;
              this.dummy_tax_rate = slave[this.loop2].TAX;

              
         this.dummy_price = this.dummy_net_price * 100 / (100 + this.dummy_tax_rate);
 
           
            this.dummy_taxable_tot =  this.dummy_taxable_tot +  (this.dummy_price *  this.dummy_qty) 
                  

        }

        this.dummy_.DARAY[this.loop1].T18_P_TAXABLE =    this.dummy_taxable_tot;
        this.dummy_.DARAY[this.loop1].T18_P_CGST =    (this.dummy_.DARAY[this.loop1].T18_P_TAXABLE * 9 )/ 100
        this.dummy_.DARAY[this.loop1].T18_P_SGST =    (this.dummy_.DARAY[this.loop1].T18_P_TAXABLE * 9 )/ 100
        this.dummy_.DARAY[this.loop1].T18_P_TAX =    (this.dummy_.DARAY[this.loop1].T18_P_TAXABLE * 18 )/ 100
        

    } 
   else
   {
    this.dummy_.DARAY[this.loop1].T18_P_TAXABLE = 0
    this.dummy_.DARAY[this.loop1].T18_P_CGST = 0
    this.dummy_.DARAY[this.loop1].T18_P_SGST = 0
    this.dummy_.DARAY[this.loop1].T18_P_TAX = 0

   }

}


check_28(slave,i_n)
{
  this.dummy_taxable_tot = 0;

  slave = slave.filter(xy=>xy.MASTER_ID === i_n && xy.TAX === 28)
  
    console.log('slave',slave);
   if(slave.length > 0)
   {
        for(this.loop2=0;this.loop2<slave.length;this.loop2++)
        {
                    
              this.dummy_net_price = slave[this.loop2].NET_PRICE;
              this.dummy_qty = slave[this.loop2].QUANTITY;
              this.dummy_tax_rate = slave[this.loop2].TAX;

              
         this.dummy_price = this.dummy_net_price * 100 / (100 + this.dummy_tax_rate);
 
           
            this.dummy_taxable_tot =  this.dummy_taxable_tot +  (this.dummy_price *  this.dummy_qty) 
                  

        }

        this.dummy_.DARAY[this.loop1].T28_P_TAXABLE =    this.dummy_taxable_tot;
        this.dummy_.DARAY[this.loop1].T28_P_CGST =    (this.dummy_.DARAY[this.loop1].T28_P_TAXABLE * 14 )/ 100
        this.dummy_.DARAY[this.loop1].T28_P_SGST =    (this.dummy_.DARAY[this.loop1].T28_P_TAXABLE * 14 )/ 100
        this.dummy_.DARAY[this.loop1].T28_P_TAX =    (this.dummy_.DARAY[this.loop1].T28_P_TAXABLE * 28 )/ 100
        

    } 
   else
   {
    this.dummy_.DARAY[this.loop1].T28_P_TAXABLE = 0
    this.dummy_.DARAY[this.loop1].T28_P_CGST = 0
    this.dummy_.DARAY[this.loop1].T28_P_SGST = 0
    this.dummy_.DARAY[this.loop1].T28_P_TAX = 0

   }

  

     


 
}






net_taxable(master:number)
{

for(this.loop3=0;this.loop3<master;this.loop3++)
{
 console.log('hifi',this.loop3);
 this.dummy_.DARAY[this.loop3].TOTAL_TAXABLE =   this.dummy_.DARAY[this.loop3].ZERO_P_TAXABLE  + 
  this.dummy_.DARAY[this.loop3].T5_P_TAXABLE + this.dummy_.DARAY[this.loop3].T12_P_TAXABLE  +  this.dummy_.DARAY[this.loop3].T18_P_TAXABLE +
  this.dummy_.DARAY[this.loop3].T28_P_TAXABLE 

}
this.dummy = true;

}
    







getjson(json:any)
{


  this.make_mm(json)


//   for(this.loop1=0;this.loop1<json.master.length;this.loop1++)
//   {
   


//   this.load_.master.push({INVOICE_NUMBER:0,CUSTOMER_NAME:null,BILL_DATE:'',CUSTOMER_GST_IN : ''})

//   this.load_.master[this.loop1].CUSTOMER_NAME = json.master[this.loop1].CUSTOMER_NAME
//   this.load_.master[this.loop1].INVOICE_NUMBER = json.master[this.loop1].INVOICE_NUMBER
//   this.load_.master[this.loop1].BILL_DATE = json.master[this.loop1].BILL_DATE
//   this.load_.master[this.loop1].CUSTOMER_GST_IN = json.master[this.loop1].CUSTOMER_GST_IN
 

//   }


  
//   for(this.loop3=0;this.loop3<json.slave.length;this.loop3++)
//   {
   

//   this.load_.slave.push({NET_PRICE:0,PRICE:0,QUANTITY:0,TAX:0,TAXABLE_:0,TAX_COLLECTED:0,MASTER_ID:0,is_ommited:false})

//   this.load_.slave[this.loop3].NET_PRICE = json.slave[this.loop3].NET_PRICE
//   this.load_.slave[this.loop3].TAX = json.slave[this.loop3].TAX
//   this.load_.slave[this.loop3].MASTER_ID = json.slave[this.loop3].MASTER_ID;
//   this.load_.slave[this.loop3].QUANTITY = json.slave[this.loop3].QUANTITY;


//   }


//  // this.sort(json);



// this.assign(json)

 
}

assign(data)
{
  for(this.loop2=0;this.loop2<data.master.length;this.loop2++)

  {
    this.push_post_master()
    
          this.post_invo.master_dets[this.loop2].CUSTOMER_NAME = data.master[this.loop2].CUSTOMER_NAME
          this.post_invo.master_dets[this.loop2].INVOICE_NUMBER = data.master[this.loop2].INVOICE_NUMBER
          this.post_invo.master_dets[this.loop2].BILL_DATE = data.master[this.loop2].BILL_DATE
          this.post_invo.master_dets[this.loop2].CUSTOMER_GST_IN = data.master[this.loop2].CUSTOMER_GST_IN
          

          for(this.loop3=0;this.loop3<this.post_invo.master_dets.length;this.loop3++)
          {

        

            this.load_copy.slave = this.load_.slave.filter(xy=>xy.MASTER_ID == this.post_invo.master_dets[this.loop3].INVOICE_NUMBER)

            for(this.loop4=0;this.loop4<this.load_copy.slave.length;this.loop4++)
            {
             

              this.push_post_slave(this.loop3);


              this.post_invo.master_dets[this.loop3].item[this.loop4].TAX = this.load_copy.slave[this.loop4].TAX;
              this.post_invo.master_dets[this.loop3].item[this.loop4].NET_PRICE = this.load_copy.slave[this.loop4].NET_PRICE;
              this.post_invo.master_dets[this.loop3].item[this.loop4].QTY = this.load_copy.slave[this.loop4].QUANTITY;
              this.d_ =  this.post_invo.master_dets[this.loop3].item[this.loop4].NET_PRICE * 100 / (100 +  this.post_invo.master_dets[this.loop3].item[this.loop4].TAX );
              this.post_invo.master_dets[this.loop3].item[this.loop4].PRICE = this.d_
              this.post_invo.master_dets[this.loop3].item[this.loop4].TAXABLE_VALUE = this.post_invo.master_dets[this.loop3].item[this.loop4].PRICE * this.post_invo.master_dets[this.loop3].item[this.loop4].QTY
              
              this.post_invo.master_dets[this.loop3].item[this.loop4].TAX_COLLECTED = 
             ( this.post_invo.master_dets[this.loop3].item[this.loop4].TAXABLE_VALUE * this.post_invo.master_dets[this.loop3].item[this.loop4].TAX ) / 100; 
            }  

             
            

          }        

    

  }

  this.sort_and_eli(this.post_invo)

}

push_post_master()
{
  this.post_invo.master_dets.push({INVOICE_NUMBER:0,CUSTOMER_NAME:'',BILL_DATE:'',CUSTOMER_GST_IN:'',item:[]})
}

push_post_slave(cout:number)
{
  this.post_invo.master_dets[cout].item.push({SI:0,PRICE:0,NET_PRICE:0,QTY:0,TAX:0,TAXABLE_VALUE:0,
    TAX_COLLECTED:0,is_ommited:false,TAX_VALUE_TOTAL:0})
}

sort_and_eli(data)
{

  this.run = 0

  for(this.loop5=0;this.loop5<data.master_dets.length;this.loop5++)

  {

    

    this.push_true_mater()

    this.true_invo.master_dets[this.loop5].CUSTOMER_NAME = data.master_dets[this.loop5].CUSTOMER_NAME
    this.true_invo.master_dets[this.loop5].INVOICE_NUMBER = data.master_dets[this.loop5].INVOICE_NUMBER
    this.true_invo.master_dets[this.loop5].BILL_DATE = data.master_dets[this.loop5].BILL_DATE
    this.true_invo.master_dets[this.loop5].CUSTOMER_GST_IN = data.master_dets[this.loop5].CUSTOMER_GST_IN
    

    this.run = 0;
    for(this.loop6=0;this.loop6<data.master_dets[this.loop5].item.length;this.loop6++)

        {
          
           
                   if(this.loop6 < 1)
                   {
                    this.push_true_slave(this.loop5)
                    this.true_invo.master_dets[this.loop5].item[this.loop6].TAX = data.master_dets[this.loop5].item[this.loop6].TAX
                    this.true_invo.master_dets[this.loop5].item[this.loop6].TAXABLE_VALUE = data.master_dets[this.loop5].item[this.loop6].TAXABLE_VALUE
                    this.true_invo.master_dets[this.loop5].item[this.loop6].TAX_COLLECTED = data.master_dets[this.loop5].item[this.loop6].TAX_COLLECTED
                    this.run = this.run + 1
                   }

                   else
                   {

                    this.bool = this.check_list(data.master_dets[this.loop5].item[this.loop6].TAX);


                

                     if(this.bool === false)
                           {
                            this.push_true_slave(this.loop5)
                            this.true_invo.master_dets[this.loop5].item[this.run].TAX = data.master_dets[this.loop5].item[this.loop6].TAX;
                            this.true_invo.master_dets[this.loop5].item[this.run].TAXABLE_VALUE = data.master_dets[this.loop5].item[this.loop6].TAXABLE_VALUE;
                            this.true_invo.master_dets[this.loop5].item[this.run].TAX_COLLECTED = data.master_dets[this.loop5].item[this.loop6].TAX_COLLECTED;
                            this.run = this.run + 1

                           }

                      else {
                                 this.true_invo.master_dets[this.loop5].item[this.index].TAXABLE_VALUE =  this.true_invo.master_dets[this.loop5].item[this.index].TAXABLE_VALUE + data.master_dets[this.loop5].item[this.loop6].TAXABLE_VALUE
                                 this.true_invo.master_dets[this.loop5].item[this.index].TAX_COLLECTED =  this.true_invo.master_dets[this.loop5].item[this.index].TAX_COLLECTED + data.master_dets[this.loop5].item[this.loop6].TAX_COLLECTED
                           }     

                 



                   }
                     



          


        }  




  }



}


check_list( a :any) : boolean
{

 var i =0; var k = 0;
  for(i;i<this.true_invo.master_dets[this.loop5].item.length;i++)
                {
                      if(this.true_invo.master_dets[this.loop5].item[i].TAX === a )
                      {
                         k = 1;
                         this.index = i;
                         return true;
                      }
                }
    if (k === 0)
    {
      return false;
    }
 
}




push_true_slave(prim)
{
  this.true_invo.master_dets[prim].item.push({SI:0,PRICE:0,NET_PRICE:0,QTY:0,TAX:0,TAXABLE_VALUE:0,TAX_COLLECTED:0,
    is_ommited:false,TAX_VALUE_TOTAL:0})
}

push_true_mater()
{
  this.true_invo.master_dets.push({INVOICE_NUMBER:0,CUSTOMER_NAME:'',BILL_DATE:'',CUSTOMER_GST_IN:'',item:[]})
}



























sort(data:any)
{
  

  for(this.loop2=0;this.loop2<data.master.length;this.loop2++)
   
  
  {
  this.push_det();


  this.post_invo.master_dets[this.loop2].CUSTOMER_NAME = data.master[this.loop2].CUSTOMER_NAME
  this.post_invo.master_dets[this.loop2].INVOICE_NUMBER = data.master[this.loop2].INVOICE_NUMBER
  this.post_invo.master_dets[this.loop2].BILL_DATE = data.master[this.loop2].BILL_DATE
  this.post_invo.master_dets[this.loop2].CUSTOMER_GST_IN = data.master[this.loop2].CUSTOMER_GST_IN
  


  this.true_invo.master_dets[this.loop2].CUSTOMER_NAME = data.master[this.loop2].CUSTOMER_NAME;
  this.true_invo.master_dets[this.loop2].INVOICE_NUMBER = data.master[this.loop2].INVOICE_NUMBER;
  this.true_invo.master_dets[this.loop2].BILL_DATE = data.master[this.loop2].BILL_DATE
  this.true_invo.master_dets[this.loop2].CUSTOMER_GST_IN = data.master[this.loop2].CUSTOMER_GST_IN

  
  }



  for(this.loop3=0;this.loop3<this.post_invo.master_dets.length;this.loop3++)
  {
    


    this.load_copy.slave = this.load_.slave.filter(xy=>xy.MASTER_ID == this.post_invo.master_dets[this.loop3].INVOICE_NUMBER)



       
    for(this.loop4 = 0;this.loop4<this.load_copy.slave.length;this.loop4++)
    {

      this.push_one(this.loop3);
      this.push_true_slave(this.loop3)
     
       if(this.loop4 > 0)
       {

                         for(var ix = 0; ix < this.loop4 ; ix++)
                            {
                          
                              this.bool = this.check_list(this.load_copy.slave[this.loop4].TAX );

                             
                    
                                                    if ( this.bool === false)
                                                    {
                                                      
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAX = this.load_copy.slave[this.loop4].TAX;
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].NET_PRICE = this.load_copy.slave[this.loop4].NET_PRICE;
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].QTY = this.load_copy.slave[this.loop4].QUANTITY;
                                                      this.d_ = this.post_invo.master_dets[this.loop2].item[this.loop4].NET_PRICE * 100 / (100 +  this.post_invo.master_dets[this.loop2].item[this.loop4].TAX );
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE = this.d_
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE = this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE * this.post_invo.master_dets[this.loop2].item[this.loop4].QTY
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_VALUE_TOTAL = this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_COLLECTED =   this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_COLLECTED +
                                                        (this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_VALUE_TOTAL * this.post_invo.master_dets[this.loop2].item[this.loop4].TAX  ) / 100;  
                                                    }

                                                    else
                                                    {
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].NET_PRICE = this.load_copy.slave[this.loop4].NET_PRICE;
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].QTY = this.load_copy.slave[this.loop4].QUANTITY;
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAX = this.load_copy.slave[this.loop4].TAX;


                                                      this.d_ =  this.load_copy.slave[this.loop4].NET_PRICE * 100 / (100 +  this.load_copy.slave[this.loop4].TAX );
                                                      
                                                     
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE = this.d_
                                                    
                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE = this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE * this.post_invo.master_dets[this.loop2].item[this.loop4].QTY
                                                      this.post_invo.master_dets[this.loop2].item[this.index].TAX_VALUE_TOTAL =  (this.post_invo.master_dets[this.loop2].item[this.index].TAXABLE_VALUE-0) + ( this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE-0)
                                                      

                                                      this.post_invo.master_dets[this.loop2].item[this.loop4].is_ommited = true; 
                                                      this.post_invo.master_dets[this.loop2].item[this.index].TAX_COLLECTED =   this.post_invo.master_dets[this.loop2].item[this.index].TAX_COLLECTED +  (this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_VALUE_TOTAL * this.post_invo.master_dets[this.loop2].item[this.loop4].TAX  ) / 100;  
                                                      
                                                    }
                                                  
                            }
       } 
       else
       {

      

       this.post_invo.master_dets[this.loop2].item[this.loop4].TAX = this.load_copy.slave[this.loop4].TAX;
       this.post_invo.master_dets[this.loop2].item[this.loop4].NET_PRICE = this.load_copy.slave[this.loop4].NET_PRICE;
       this.post_invo.master_dets[this.loop2].item[this.loop4].QTY = this.load_copy.slave[this.loop4].QUANTITY;
       this.d_ = this.post_invo.master_dets[this.loop2].item[this.loop4].NET_PRICE * 100 / (100 +  this.post_invo.master_dets[this.loop2].item[this.loop4].TAX );

       this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE = this.d_

       this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE = this.post_invo.master_dets[this.loop2].item[this.loop4].PRICE * this.post_invo.master_dets[this.loop2].item[this.loop4].QTY

       this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_VALUE_TOTAL = this.post_invo.master_dets[this.loop2].item[this.loop4].TAXABLE_VALUE
       

       this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_COLLECTED =   this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_COLLECTED +  (this.post_invo.master_dets[this.loop2].item[this.loop4].TAX_VALUE_TOTAL * this.post_invo.master_dets[this.loop2].item[this.loop4].TAX  ) / 100;  
       }
    


    }  
    
      
       
  }






}







push_all()
{
  
}


push_one(cout:number)
{

 
  this.post_invo.master_dets[cout].item.push({SI:0,PRICE:0,NET_PRICE:0,QTY:0,TAX:0,TAXABLE_VALUE:0,
    TAX_COLLECTED:0,is_ommited:false,TAX_VALUE_TOTAL:0})



}



push_det()
{
  this.post_invo.master_dets.push({INVOICE_NUMBER:0,CUSTOMER_NAME:'',BILL_DATE:'',CUSTOMER_GST_IN:'',item:[]})
  this.true_invo.master_dets.push({INVOICE_NUMBER:0,CUSTOMER_NAME:'',BILL_DATE:'',CUSTOMER_GST_IN:'',item:[]})

}


           
   

}




