import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';
import { DataService } from '../../services/data.service';
import { SharingService } from '../../services/sharing.service';
import { UpdateService } from'../../services/update.service';
import { pdt_model } from './model';
import { figure } from './model';
import {tax_deri}  from './model';
import  { hsn_array }  from './model';
import { batch_model } from './model';



@Component({
  selector: 'b2b-invoice',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Bill_b2b  {


  
   z : number = -1;
   j : number;
   total_qty : number = 0;
   gross_total : number = 0;
  //  invoice_number_b2b : number = 0;
  //  invoice_number_b2c : number = 0;

   invo_string : string = '';
   vendsi : any = null ;
   tool_tip_value : number = 0;
   tooltips : string[] = ['Adds a New Row','Remove a Row','Change Price']
   positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
   position = new FormControl(this.positionOptions[5]);

   
   
   is_b2b : boolean = false;
   isClickedOnce : boolean = false;
   disabled_button : boolean =true; 
   isPrint : boolean = true;
 
   customer_name : string = '';
   is_invo_num_loaded : boolean = false
   isServer_res : boolean = false
  
  
   arrayOfKeyValues: any[] = [];
   
   arrayOfspecValues: any[] = [];
   arrayOfCusValues : any[] =[];
   specs :any[] = []
 
  fig_model = new figure(0,0,'');
  product_array : pdt_model[] =[];
  batch_array : batch_model[] =[];
  batch_array_copy : batch_model[] =[];
  stock_check : number = 0;
  figured_out : string;
  fig_main : string ;
  fig_tax : string ;
  fig_sub : string ;
  floor : number;
  frac : number;
  date_change_flag : number = 0;
  snak_msg : string = '';
  print_section : boolean = false;
  b: number = 0;
  d_ : number = 0;

  tax_d = new tax_deri(0,[]);
  bool : boolean ;
  tax_index : number = 0;
  total_rate_sum : number = 0;
  se_ind : number = 0; 
  sehsn_ind : number = 0;
  total_tax_tot_value : number = 0;
  arr_ : number = 0;
  post_qq : any = {invo_string : "",is_b2b:false}
  up_click_once : boolean = false;

  height_var : number[] = [];
  main_height_var : number[] = [];
  table_height_var : number[] = [];
  space_height_var : number[] =[];

  hsn_arra :  hsn_array[] = []

  temp_alt : number[] = [];

 total_alt_qty : number = 0;

 temp_total_tax : number = 0;
 temp_total_mrp : number = 0 ;

 data_error_flag: number = 0;
 data_error_string : string = 'no_error';
 duplicate_print : boolean =  false
 triplicate_print : boolean  = false 
 a5_print : boolean = false ;
 a4_print : boolean = false ;
 a5_print_option : boolean = true;
 mm_80_print : boolean = true;
 batch_no : string = null;
 batch_id : number;
 modal : any;
 expiry_date : string;
 delta : any
 pdtary : number [] = [];

 arrayOfbatchValues :  any[] = [];
 temp_price : number = 0;
 


  constructor(private fb: FormBuilder, private ds :DataService, private router : Router,
                         private us : UpdateService, private ss : SharingService) { }
 ngOnInit() {

  this.modal = document.getElementById("myModal");

    this.duplicate_print = false;
    this.triplicate_print = false;
    this.a5_print = false;
    this.a4_print = true;
    this.mm_80_print = false;
    this.ds.item_count = 1;
     
    this.get_products();
  //  this.get_batch_list();

    

     if(this.ss.i_m.configs[0].IS_ON_UPDATE === true)
               {
                      


                    this.main_height_var[0] = 99.8;
                    this.main_height_var[1] = 755;
                    this.table_height_var[0] = 487;
                    this.space_height_var[0] = 800;
                    this.check_tr();


                    if(this.ss.i_m.configs[0].ITEM_LENGTH > 10)
                    {
                      this.a5_print_option = false;
                    }

               }

       else
       
       {

    this.main_height_var[0] = 99.8;
    this.table_height_var[0] = 487;
    this.space_height_var[0] =750;
       }        
  

       
   
  }





 

  close_modal()
  {
    this.ss.i_m.items[this.ss.i_m.varibs[0].K].BATCH_NO = this.batch_no;
    this.ss.i_m.items[this.ss.i_m.varibs[0].K].EXPIRY_DATE = this.expiry_date.toLocaleString();
   
  
   this.modal.style.display = "none";
  }
  close_modal_only()
  {
    this.modal.style.display = "none";

  }

  open_modal(index:any)
  {
    this.ss.i_m.varibs[0].K = index ;

    this.batch_id = null;
    this.batch_no = null;
    this.expiry_date = null;
  

   if(this.ss.i_m.configs[0].IS_ON_UPDATE)
   {

    this.batch_no =  this.ss.i_m.items[this.ss.i_m.varibs[0].K].BATCH_NO;
    this.expiry_date = this.ss.i_m.items[this.ss.i_m.varibs[0].K].EXPIRY_DATE;

   }

   this.batch_array = this.batch_array_copy.filter(xi=> xi.ITEM_ID == this.ss.i_m.items[this.ss.i_m.varibs[0].K].ID  );
  
    
    this.modal.style.display = "block";
  }
  focus_bns(id : number)
  {
 
  }

  change_a5()
  {
    if(this.a5_print === true)
    {
      this.mm_80_print = false;
      this.a4_print = false;
    }
  }
  change_a4()
  {
    if(this.a4_print === true)
    {
      this.mm_80_print = false;
      this.a5_print = false;
    }

  }
  change_batch(id : number)
  {
    console.log('id',id)
    this.delta = this.batch_array.filter(xi=> xi.ID == id );
    if(this.delta.length > 0)
    {
      this.ss.i_m.items[this.ss.i_m.varibs[0].K].BATCH_NO = this.delta[0].BATCH_NO
      this.ss.i_m.items[this.ss.i_m.varibs[0].K].EXPIRY_DATE  = this.delta[0].EXPIRY_DATE
      this.batch_no = this.delta[0].BATCH_NO
      this.expiry_date = this.delta[0].EXPIRY_DATE
    }
      
  }

check_tr()

{

  if( this.ds.item_count > 18)
       {
  
  
        this.main_height_var[0] =   199.6  
        this.table_height_var[0] =  1028
       
       }

       else
       {
        this.main_height_var[0] = 99.8;
        this.main_height_var[1] = 755;
        this.table_height_var[0] = 487;
      
       }

  

//    if( this.ss.i_m.varibs[0].I > 10 && this.ss.i_m.varibs[0].I <= 23)
//      {


//       this.main_height_var[0] =   199.6  
//       this.table_height_var[0] =  1028
//       this.space_height_var[0] =  250
//      }

// else 
// {

//   if( this.ss.i_m.varibs[0].I > 23)
//   {
//       this.main_height_var[0] =   199.6
//       this.table_height_var[0] =  1028
//       this.space_height_var[0] =  250
//   }
// }

  




}



get_batch_list()
{
  this.ds.get_batch_list()
  .subscribe((jsonData) => { this._get_batch(jsonData)
          },(err) => console.error(err),
           
          );
}

_get_batch(json :any)
{       

          
 this.batch_array = json;

 this.batch_array_copy = json;
  

}


print_invoice_focus()
{
  console.log('focused...')
}


 print_open()
 {

   this.check_tr()

     
    window.print();

 }



change_date()
{
  this.ss.date_change_flag = 0;
}


push_items( si : number )
    
           {
           
             if(si > this.ss.i_m.items.length)
             {
               
               this.ss.i_m.items.push({ SI_NO:si,ID:0, DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,BATCH_NO:null,EXPIRY_DATE:null,MRP:0,
     PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',FREE_ITEM:0,PRICE_COPY:0,BAR_CODE:'',MASTER_ID:0
     ,NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })

              }
              else
              {
                 this.reset_ss();
              }
            }

            reset_ss()
            {
             this.z = this.ss.i_m.items.length
              while (this.z > 1) 
              {
                 this.ss.i_m.items.pop();
                  this.z--                  
              }
            this.ss.i_m.tails[0].SUB_TOTAL = 0;
            }

    get_products()
            {
              this.ds.get_products_list()
              .subscribe((jsonData) => { this._get_products(jsonData)
                      },(err) => console.error(err),
                       
                      );
           }

      _get_products(json :any)
        {               
            this.arrayOfKeyValues = json;            
            this.product_array = json;
            this.isServer_res = true;            
            for(var nip = 0; nip < json.length; nip++)
           
            {
              this.specs[nip] = json[nip].SPECIFICATION
            }    
       
         }

 push_tax_der(x:any)
 {
   this.tax_d.SI = x;
   this.tax_d.deri.push({SI:1,HSN:'',TAX:0,TAXABLE_VALUE:0,CT_RATE:0,CT_AMT:0,ST_RATE:0,ST_AMT:0,TOT_AMT:0})

 }         



 change_one(item_code:any)
 {
 
  //  this.batch_array_copy = this.batch_array
 
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = item_code ;
   let si : any;     
 si = this.product_array.filter(xi=> xi.DESCRIPTION  === item_code);
 if(si.length > 0)
 {
   
 this.stock_check = si[0].AVAIL_QTY ;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].DESCRIPTION = si[0].DESCRIPTION;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].ID = si[0].ID;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].CATEGORY = si[0].CATGERY;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].HSN_CODE = si[0].HSN_CODE;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].UNIT = si[0].UNIT;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].MRP = si[0].MRP;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].TAX = si[0].TAX;
 this.temp_alt[this.ss.i_m.varibs[0].K] = si[0].ALT_QTY;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].ALT_UNIT = si[0].ALT_UNIT;
 this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE_COPY = si[0].BILL_NET_PRICE;
 
 

 if(this.ss.i_m.varibs[0].K > 0)
 {
 
   this.check_for_duplication(si[0].ID)

  
 }
 else
 {
  console.log('sus',this.ss.i_m.varibs[0].K)
    this.pdtary[this.ss.i_m.varibs[0].K] = si[0].ID;
 }
 
 if(this.ss.i_m.configs[0].IS_ON_UPDATE === false)
 {
 
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE = si[0].BILL_PRICE;
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE_COPY = si[0].BILL_PRICE;
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE = si[0].BILL_NET_PRICE;
 }
 
 
 this.calculation();
 
 }
 else
 {
   this.snak_fun('Enter valid Specification');
 }
 
 
 this.batch_array = this.batch_array_copy.filter(xy=>xy.ITEM_ID == this.ss.i_m.items[this.ss.i_m.varibs[0].K].ID )
 
 }
 




 check_for_duplication(id : number)
 {
  console.log('id', this.pdtary);
   var x = this.pdtary.indexOf(id,0)
   console.log('id',x)
   if(x < 0)
   {
    this.pdtary[this.ss.i_m.varibs[0].K] = id;
   }
   else
   {
    



     var nun = x + 1;

     if (x === this.ss.i_m.varibs[0].K)
     {
      
     }
     else
     {
    this.snak_fun('Already Present in Position '+ nun);
     }
   }

 }

push_hsn()
{
  this.hsn_arra.push({SI : 0,hsn:[]})
}




smart_dervation(len:any)
{
   var ip =0; var kp = 0;
 for(ip;ip<len;ip++)
   {

    this.bool =  this.check_dup(this.ss.i_m.items[ip].TAX)
   
     if(this.bool === true)
     {
       this.se_ind =  this.seach_index(this.ss.i_m.items[ip].TAX)
      
       var templeng = this.hsn_arra[this.se_ind].hsn.length
        
      



       this.search_hsn_index(this.ss.i_m.items[ip].HSN_CODE,this.se_ind)
      
       
    
       this.tax_d.deri[this.se_ind].TAXABLE_VALUE = (this.tax_d.deri[this.se_ind].TAXABLE_VALUE-0) + ((this.ss.i_m.items[ip].RATE_SUM))
      
       this.tax_d.deri[this.se_ind].CT_AMT = ((this.tax_d.deri[this.se_ind].TAXABLE_VALUE * this.tax_d.deri[this.se_ind].CT_RATE ) / 100)
      
       this.hsn_arra[this.se_ind].hsn[templeng] = this.ss.i_m.items[ip].HSN_CODE

     }

     else

     {
       this.push_tax_der(1);
       this.push_hsn();
        
        
        this.tax_d.deri[kp].TAX = this.ss.i_m.items[ip].TAX;
        this.tax_d.deri[kp].TAXABLE_VALUE = ((this.ss.i_m.items[ip].RATE_SUM) )
   

        this.hsn_arra[kp].SI = kp
        this.hsn_arra[kp].hsn[kp] = this.ss.i_m.items[ip].HSN_CODE
        
        this.tax_d.deri[kp].HSN = this.tax_d.deri[kp].HSN.concat(this.ss.i_m.items[ip].HSN_CODE)
        
 
        this.tax_d.deri[kp].CT_RATE = this.ss.i_m.items[ip].TAX / 2;
        this.tax_d.deri[kp].ST_RATE = this.ss.i_m.items[ip].TAX / 2;

        this.tax_d.deri[kp].CT_AMT =  (this.tax_d.deri[kp].TAXABLE_VALUE * this.tax_d.deri[kp].CT_RATE ) / 100
        
       
        kp++;
     }
     
   }  


  this.make_height_var()


 }  





make_height_var()
{
  var ips =0; var kps = 0;
  for(ips;ips<this.tax_d.deri.length;ips++)
  {

    if( this.tax_d.deri[ips].HSN.length  < 22 &&  this.tax_d.deri[ips].HSN.length  > 0)
    {
      this.height_var[ips] = 30;
    }
    else if( this.tax_d.deri[ips].HSN.length  < 44 &&  this.tax_d.deri[ips].HSN.length  >= 22)
    {
      this.height_var[ips] = 46;
    }
     else if( this.tax_d.deri[ips].HSN.length  < 66 &&  this.tax_d.deri[ips].HSN.length  >= 44)
    {
      this.height_var[ips] = 62;
    }

     else if( this.tax_d.deri[ips].HSN.length  < 88 &&  this.tax_d.deri[ips].HSN.length  >= 66)
    {
      this.height_var[ips] = 80;
    }

     else if( this.tax_d.deri[ips].HSN.length  < 110 &&  this.tax_d.deri[ips].HSN.length  >= 88)
    {
      this.height_var[ips] = 98;
    }
  }

  
}



search_hsn_index(hsn:any,index : any)
{

var op = 0;
for(var mpp  = 0; mpp < this.hsn_arra[index].hsn.length; mpp++)
{

  if(this.hsn_arra[index].hsn[mpp] === hsn)
  {
    op = 1;
  }
  else
  {
    
  }
 
}

  if(op === 0)
  {
      
       this.tax_d.deri[index].HSN = this.tax_d.deri[index].HSN.concat(',');
       this.tax_d.deri[index].HSN = this.tax_d.deri[index].HSN.concat(hsn)
  }

}








seach_index(tax:any)
{

    var is =0; var ks = 0;
   for(is;is<this.tax_d.deri.length;is++)
   {
   if( this.tax_d.deri[is].TAX === tax )
   {
   ks = 1;
    return is;
   }
   }

 if (ks === 0)
 {
   return 0;
 }

}



check_dup(tax:any)
{

   var i =0; var k = 0;
   for(i;i<this.tax_d.deri.length;i++)
   {
   if( this.tax_d.deri[i].TAX === tax )
   {
   k = 1;
    return true;
   }
   }

 if (k === 0)
 {
   return false;
 }

}


change_dis_amt(di_p:any)
{
  this.ss.i_m.tails[0].DISC_PERCENT = di_p;
  this.calculation();
}



calculation()
{
   
 
   this.ss.i_m.tails[0].GRAND_TOTAL = 0 
   this.total_qty = 0
   this.gross_total = 0;
   this.ss.i_m.tails[0].SUB_TOTAL = 0
   this.ss.i_m.tails[0].TOTAL_CESS = 0
   this.ss.i_m.tails[0].TOTAL_TAX = 0
   this.temp_total_tax = 0 
   this.ss.i_m.tails[0].GRAND_SUB_TOTAL = 0;
   this.ss.i_m.tails[0].You_Save = 0;

   this.temp_total_mrp = 0 




for(this.j=0;this.j<=this.ss.i_m.varibs[0].I+1;this.j++)

{
 //  this.ss.i_m.items[this.j].PRICE  = this.ss.i_m.items[this.j].PRICE_COPY;
  
 

   this.ss.i_m.items[this.j].RATE_SUM = this.ss.i_m.items[this.j].PRICE * this.ss.i_m.items[this.j].QTY

   this.temp_total_mrp =    this.temp_total_mrp  + (this.ss.i_m.items[this.j].MRP * this.ss.i_m.items[this.j].QTY)

   this.ss.i_m.tails[0].SUB_TOTAL = (this.ss.i_m.tails[0].SUB_TOTAL-0) + (this.ss.i_m.items[this.j].RATE_SUM-0)

   this.ss.i_m.tails[0].GRAND_SUB_TOTAL = (this.ss.i_m.tails[0].GRAND_SUB_TOTAL-0) + (this.ss.i_m.items[this.j].NET_PRICE * this.ss.i_m.items[this.j].QTY)
  

   if(this.ss.i_m.heads[0].CUS_TYPE === 'SEZ')
   {
    this.ss.i_m.tails[0].TOTAL_TAX =   0;
    this.gross_total = (this.gross_total-0) +  (this.ss.i_m.items[this.j].PRICE *  this.ss.i_m.items[this.j].QTY);
   }

   else
   {
    this.ss.i_m.tails[0].TOTAL_TAX =   this.ss.i_m.tails[0].TOTAL_TAX +  ((this.ss.i_m.items[this.j].RATE_SUM  *  this.ss.i_m.items[this.j].TAX)/100) 
    this.gross_total = (this.gross_total-0) +  (this.ss.i_m.items[this.j].NET_PRICE *  this.ss.i_m.items[this.j].QTY);
   }

  
    if(this.ss.i_m.configs[0].IS_B2B === false && this.ss.i_m.items[this.j].TAX > 5)

    {
        this.ss.i_m.items[this.j].CESS_AMT = ((this.ss.i_m.items[this.j].PRICE * 1) / 100 ) * this.ss.i_m.items[this.j].QTY 
        this.ss.i_m.tails[0].TOTAL_CESS = (this.ss.i_m.tails[0].TOTAL_CESS-0) + (this.ss.i_m.items[this.j].CESS_AMT-0) 
        

    }

    this.total_qty = (this.total_qty-0) + (this.ss.i_m.items[this.j].QTY-0) ;
}


   
this.ss.i_m.tails[0].GRAND_TOTAL =  (this.ss.i_m.tails[0].GRAND_SUB_TOTAL-0) -  (this.ss.i_m.tails[0].DISC_PERCENT-0) + (this.ss.i_m.tails[0].TOTAL_CESS-0)

   

   


   this.floor =  Math.floor(this.ss.i_m.tails[0].GRAND_TOTAL);  
   this.frac =  (this.ss.i_m.tails[0].GRAND_TOTAL) % 1;

     if(this.frac > .49)
     {
       this.frac = 1 - this.frac;
       this.floor = this.floor + 1;
     }

   this.ss.i_m.tails[0].GRAND_TOTAL = this.floor;  

   this.ss.i_m.tails[0].mrp_total = this.temp_total_mrp;
   this.ss.i_m.tails[0].You_Save =  (this.ss.i_m.tails[0].mrp_total-0) - (this.ss.i_m.tails[0].GRAND_TOTAL-0)
  
   this.get_figure(this.floor);



   this.get_fig_tax(this.ss.i_m.tails[0].TOTAL_TAX);

    
 





   }
  


get_fig_tax(y:any)
{
    this.fig_model.number_to_convert = y;
   this.ds.get_figure(this.fig_model)
   .subscribe((jsonData) => { this.getjson89(jsonData)
                      },(err) => console.error(err),
                       
                      );
}
getjson89(json:any)
{
   this.fig_tax = json.msg;
}


change_qty(qty:any)
{
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].QTY = qty;

   
       if (this.stock_check < qty)
 {
       this.snak_fun("Your Stock remains "+ this.stock_check +" Items Only");
    
 }





   this.calculation();
}  


change_free_qty(fq:any)
{
  this.ss.i_m.items[this.ss.i_m.varibs[0].K].FREE_ITEM = fq;
  this.calculation();
}



change_net_price(np:any)
{
   this.ss.i_m.items[this.ss.i_m.varibs[0].K].NET_PRICE = np ;
    
    this.d_ = np * 100 / (100 + this.ss.i_m.items[this.ss.i_m.varibs[0].K].TAX);

    this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE = this.d_

    this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE_COPY = this.d_

    this.calculation();
}

catch_invoice_date(s:any)
{ 
  
   this.ss.date_change_flag = 2 ;
 
   this.ss.i_m.heads[0].BILL_DATE = s._validSelected.toLocaleDateString();

 
}

focus_one(index:number)
{
  this.ss.i_m.varibs[0].K = index ;
  this.ss.i_m.items[index].SI_NO = index + 1;

  if(this.ss.i_m.configs[0].ITEM_LENGTH > 9)
  {
    this.a5_print_option = false;
  }
 
}

addItem(inx:number)

{
  
  this.ds.item_count = this.ds.item_count + 1;
 
   if(inx >= 42)
  {
    this.snak_fun('No more Items allowed')
  }
  else
  {
      
  this.ss.i_m.varibs[0].I= this.ss.i_m.varibs[0].I+1;

     this.check_tr()

      this.push_items(this.ss.i_m.varibs[0].I + 2);  
          
       } 

 if(this.ss.i_m.varibs[0].I > 8)
  {
    this.a5_print_option = false;
  }
  else
  {
    this.a5_print_option = true;
  }


}



RemoveItem(knx : number)
{

this.ds.item_count = this.ds.item_count - 1;


this.item_arrange(knx);


  this.ss.i_m.items.pop()
 
  this.ss.i_m.varibs[0].I = this.ss.i_m.varibs[0].I - 1;
  this.ss.i_m.varibs[0].K = this.ss.i_m.varibs[0].K - 1;
  this.calculation();

  if(this.ss.i_m.varibs[0].I > 8)
  {
    this.a5_print_option = false;
  }
  else
  {
    this.a5_print_option = true;
  }

  this.check_tr();

}



 item_arrange(index: number)
 {

   
    
    for(this.arr_ = index ; this.arr_ <= this.ss.i_m.varibs[0].I ; this.arr_ ++)
    {
      
    this.ss.i_m.items[this.arr_].DESCRIPTION = this.ss.i_m.items[this.arr_+1].DESCRIPTION;
    
    this.ss.i_m.items[this.arr_].ID = this.ss.i_m.items[this.arr_+1].ID;
    this.ss.i_m.items[this.arr_].HSN_CODE = this.ss.i_m.items[this.arr_+1].HSN_CODE;
    this.ss.i_m.items[this.arr_].QTY = this.ss.i_m.items[this.arr_+1].QTY;
    this.ss.i_m.items[this.arr_].UNIT = this.ss.i_m.items[this.arr_+1].UNIT;
    this.ss.i_m.items[this.arr_].TAX = this.ss.i_m.items[this.arr_+1].TAX;
    this.ss.i_m.items[this.arr_].PRICE = this.ss.i_m.items[this.arr_+1].PRICE;
    this.ss.i_m.items[this.arr_].NET_PRICE = this.ss.i_m.items[this.arr_+1].NET_PRICE;
    this.ss.i_m.items[this.arr_].RATE_SUM  = this.ss.i_m.items[this.arr_+1].RATE_SUM;
    this.ss.i_m.items[this.arr_].BATCH_NO  = this.ss.i_m.items[this.arr_+1].BATCH_NO;
    this.ss.i_m.items[this.arr_].EXPIRY_DATE  = this.ss.i_m.items[this.arr_+1].EXPIRY_DATE;

    }


 }

item_remove_flag()

{
  if(this.ss.i_m.varibs[0].I === -1)
  {  return true;  }
  else
  {  return false;  }
}  





get_grand_total(gt: any)
{

  this.ss.i_m.tails[0].GRAND_TOTAL = gt
}



   





get_figure(x:any)
{
    
  this.fig_model.number_to_convert = x;
   this.ds.get_figure(this.fig_model)
   .subscribe((jsonData) => { this.getjson87(jsonData)
                      },(err) => console.error(err),
                       
                      );

}

getjson87 (p:any)
{
 
   this.fig_main = p.msg;
  
 
}

print_invoice()
{

 


  

  this.smart_dervation(this.ss.i_m.items.length);
  

  if(this.ss.i_m.tails[0].TOTAL_DUE > 0)
  {
   this.ss.i_m.configs[0].IS_PARTIAL_PAY = true;
  }
  else
  {
      this.ss.i_m.configs[0].IS_PARTIAL_PAY  = false;
  }
   
   this.ss.i_m.configs[0].ITEM_LENGTH = this.ss.i_m.items.length
   

    if(this.ss.i_m.heads[0].CUSTOMER_NAME === '' || this.ss.i_m.heads[0].CUSTOMER_NAME === null)
     {    
       
      alert('select a customer'); 
    
    }
    else
    {

       
    if(this.ss.i_m.heads[0].INVOICE_NUMBER === 0 || this.ss.i_m.heads[0].INVOICE_STRING === null)
    {    
      
     alert('Invalid Invoice number or string Restart application'); 
   
    }
        
    else
    {

      this.check_for_data_errors();

   


                    }                 
    }


} 

check_for_data_errors()
{
    
  
  var cdb = 0;
  this.data_error_flag = 0;
  for(cdb ; cdb < this.ss.i_m.configs[0].ITEM_LENGTH ; cdb ++)
  {
 
   



    if(  this.ss.i_m.items[cdb].PRICE === 0 || this.ss.i_m.items[cdb].PRICE === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Price should be greater than zero:  '+(cdb+1);

    }


    if(  this.ss.i_m.items[cdb].PRICE === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Free item should be zero or greater:  '+(cdb+1);

    }

 



    if(  this.ss.i_m.items[cdb].TAX === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Tax should not be Null on : '+(cdb+1);

    }

    if(  this.ss.i_m.items[cdb].QTY === null || this.ss.i_m.items[cdb].QTY === 0)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Quantity should not be Null or Zero on serial no :  '+(cdb+1);

    }
    
 

  }

 

if(this.data_error_flag === 0)

{

  this.isClickedOnce = true;
  this.ss.i_m.heads[0].USER_LOG = this.ds.user_log;


  this.ds.post_invoice(this.ss.i_m)
  .subscribe((jsonData) => { this.get_res(jsonData)
                  },(err) => console.error(err),
                 
                  );

}

else
{
  alert(this.data_error_string);
}


  
}


get_res(res : any)
{

 
 if( res.success === true)
   {
    this.ss.i_m.heads[0].INVOICE_NUMBER = res.mas_id;
     this.print_section = true;
     alert('Invoice Saved')
       window.print();

  // if(this.ss.i_m.tails[0].TOTAL_PAID_TODAY > 0)
  // {
   //    this.ss.i_m.flags[0].IS_PAYMENT_ON_BILL = true;

    //   this.router.navigate(['/cash-on-bill']);
  // }
  // else
  // {

  // }
  this.router.navigate(['/dash']);

}

    



    else
    {
      alert(res.msg)
      this.router.navigate(['/dash']);
    }
}
  


check_is_all_correct()
{
  
}


update_invoice()
{

    this.check_is_all_correct();
     
    this.check_tr();


    if(this.ss.i_m.configs[0].IS_ALREADY_RET === true)
    {
      alert ('Updation Failed  Sales return entry found')
    }


    else

    {


                    var cdb = 0;
                    this.data_error_flag = 0;
                    for(cdb ; cdb < this.ss.i_m.items.length ; cdb ++)
                    { 
                  
                  
                      if(  this.ss.i_m.items[cdb].PRICE === 0 || this.ss.i_m.items[cdb].PRICE === null)
                      {
                  
                        this.data_error_flag = 1;
                        this.data_error_string = 'Price should be greater than zero:  '+(cdb+1);
                  
                      }
                  
                  
                      if(  this.ss.i_m.items[cdb].PRICE === null)
                      {
                  
                        this.data_error_flag = 1;
                        this.data_error_string = 'Free item should be zero or greater:  '+(cdb+1);
                  
                      }
                  
                  
                  
                  
                  
                      if(  this.ss.i_m.items[cdb].TAX === null)
                      {
                  
                        this.data_error_flag = 1;
                        this.data_error_string = 'Tax should not be Null on : '+(cdb+1);
                  
                      }
                  
                      if(  this.ss.i_m.items[cdb].QTY === null || this.ss.i_m.items[cdb].QTY === 0)
                      {
                  
                        this.data_error_flag = 1;
                        this.data_error_string = 'Quantity should not be Null or Zero on serial no :  '+(cdb+1);
                  
                      }
                      
                  
                  
                    }
                  
                  
                  
                  if(this.data_error_flag === 0)
                  
                  {
                    this.up_click_once = true;
                    this.calculation();
                    this.smart_dervation(this.ss.i_m.items.length);
                    this.ss.i_m.configs[0].ITEM_LENGTH = this.ss.i_m.items.length;           
                    this.ss.i_m.tails[0].GRAND_TOTAL_DIFF = (this.ss.i_m.tails[0].GRAND_TOTAL-0) - (this.ss.i_m.tails[0].OLD_GRAND_TOTAL-0);                 


                          if(this.ss.i_m.heads[0].INVOICE_NUMBER === 0 || this.ss.i_m.heads[0].INVOICE_STRING === null)
                          {    
                            
                            alert('Invalid Invoice number or string Restart application'); 
                          
                          }

                          else
                          {
                            this.ss.i_m.heads[0].USER_LOG = this.ds.user_log;
                            this.ds.update_invoice_part1(this.ss.i_m)
                            .subscribe((jsonData) => { this.get_res_update_init(jsonData)
                                          },(err) => console.error(err),
                                          );

                          }          
                     }  

                     else
                     {
                      alert(this.data_error_string);

                     }
                    }

}



get_res_update_init(res:any)
{

  if( res.success === true)
  {

    


    this.ds.update_invoice_part2(this.ss.i_m)
    .subscribe((jsonData) => { this.get_res_update(jsonData)
                   },(err) => console.error(err),
                  );
  }

}

get_res_update(res:any)
{

  
 if( res.success === true)
   {

     this.print_section = true;
     alert('Invoice Updated')
       window.print();

 
  this.router.navigate(['/dash']);

}
else
    {
      alert(res.msg)
      this.router.navigate(['/dash']);
    }
    

    }

    



snak_fun(msg:string)
{
      this.snak_msg = msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    
}

}


















  


