import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Item } from './model';

@Component({
  selector: 'app-bulk-update-product',
  templateUrl: './bulk_update.html',
  styleUrls: ['./bulk_update.css']
})
export class BulkUpdate_product implements OnInit {

 productForm: FormGroup;
 d_ : number = 0;
 snak_msg : string = '';
 arrayOfproductValues : any[] =[];
 cats :any[] = [];
 post : any = {"f":"k"};
 units : any[] = []
 Items = new Item(1,[]);
 modal : any;
 index : number;
 barcode_local : string;
 mrp_local : number;
 np_local : number;
 name_local : string;
 tax_local : number;

     constructor(private router: Router, private ds: DataService ,private fb: FormBuilder) {
    
     
    
  }
  

  ngOnInit() {
    this.modal = document.getElementById("native_update");
                  this.get_products();
                 
     
  
    
  }

  

  editItem(index:any)
  {
      this.index = index;
      this.open_modal();
   
  }

  
open_modal()
{
    this.modal.style.display = "block";
}

close_modal()
{
    this.Items.Item[this.index].BAR_CODE = this.barcode_local 
    

    this.modal.style.display = "none";

    
}
  

  get_products()
  {
       this.ds.get_product_list()
  .subscribe((jsonData) => { this.p_r(jsonData)
                      },(err) => console.error(err),
                      
                      );
  }
  p_r(json:any)
  {
    console.log('pdts',json)
     this.arrayOfproductValues = json;

     for(var i = 0;i<json.length;i++)
     {
       this.push_item();
       this.Items.Item[i].SI = i + 1;
       this.Items.Item[i].ID = json[i].ID;
       this.Items.Item[i].BAR_CODE = json[i].BAR_CODE;
       this.Items.Item[i].DESCRIPTION = json[i].DESCRIPTION;
       this.Items.Item[i].TAX = json[i].TAX;
       this.Items.Item[i].NET_PRICE = json[i].NET_PRICE;
       this.Items.Item[i].MRP = json[i].MRP;
       this.Items.Item[i].UNIT = json[i].UNIT;
     }
     

     




    
   

  }

  push_item()
  {
    this.Items.Item.push({   AVAIL_QTY: 0,
       BAR_CODE: '',
       CATGERY : '',
       DESCRIPTION : '',
       HSN_CODE : '',
       ID: 0,
       MRP  : 0,
       NET_PRICE : 0,
       PUR_PRICE : 0,
       SI:0,
       PRICE : 0,
       TAX : 0,
       UNIT : 0})
  }
 

 

  cal_price()
   {


     console.log('net',this.productForm.controls.net_price.value);
     this.d_ = this.productForm.controls.net_price.value * 100 / (100 + this.productForm.controls.tax.value);
   
     this.productForm.patchValue({price : this.d_ });

       

   }

  

  bulk_update_product()
  {
      this.ds.bulk_update_product(this.productForm.value)
      .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                     
                      );

  
  }

     getjson(json :any)
  {
    
  
   if (json.msg ==='Successfully updated')
   {
      alert('Product Updated')
     
   }

  
   else{
      alert('Something wrong try again')
    
   }
   
  }





  get_res(json:any)
  {
    var x = 1
      if(x > 0)
      {
       this.snak_fun(json.msg); 
       x = -2;
      }
   
    
  }

    get_all_category()
  {
    this.ds.get_product_category()
    .subscribe((jsonData) => { this.get_res_cat_list(jsonData)
                    },(err) => console.error(err),
                   
                    );

  }
  get_res_cat_list(json:any)
  {
    console.log(json)
    for(var jp=0; jp<json.length;jp++)
    {
      this.cats[jp] = json[jp].CATEGORY_NAME

    }
    
   }

snak_fun(msg:string)
{
      this.snak_msg = msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    
}


}