import { Component,OnInit,TemplateRef, ViewChild  } from '@angular/core';

import {  UpdateService } from '../../../services/update.service';
import { DataService } from '../../../services/data.service';
import { SharingService } from '../../../services/sharing.service';
import { invoices } from './model';
import { columns, Company, data } from './assets';
import { Columns } from 'ngx-easy-table';
import { ConfigService } from './config';

import { Router } from '@angular/router';
@Component({
    selector: 'purchase-all-mise',
    templateUrl: './view.html',
    styleUrls: ['./view.css'],
   
})
export class Purchaseall_mise {
    @ViewChild('detailsTemplate') detailsTemplateRef: TemplateRef<any>;
  columns: Columns[] = [];
  data: Company[] = [];

  configuration;
 
  clicked : number = 0;
  view : boolean = false;
  mos : any = {"invo_string":''};

 constructor( private ds : DataService, private uS: UpdateService, private rs: Router, private ss: SharingService  )
 {
       this.configuration = ConfigService.config;
   // this.data = data;
    this.columns = columns;
 }
  
  ngOnInit() {
   
   
   this.get_all_pur_mis();
       
  }

get_all_pur_mis()
{
      this.ds.get_all_pur_mis()
       .subscribe((jsonData) => { this.getjson_def(jsonData)
                      },(err) => console.error(err),
                      
                      );
}

 

getjson_def(as:any)
{
    

    this.data = as;
    this.view = true;
    console.log('data',this.data)

}
 
   eventEmitted($event) {

     

     if($event.event == 'onSearch')
     {
         
     }
     else
     {
        this.clicked = $event.value.row.EXPENCE_ENTRY_NO;
      alert("Selected Expense Number  " + this.clicked);
         
          
           this.ss.i_m.configs[0].IS_ON_UPDATE = true
           this.ss.i_m.heads[0].INVOICE_NUMBER = this.clicked
           //this.ss.i_m.heads[0].INVOICE_STRING  = $event.value.row.INVOICE_STRING;
          // this.ss.i_m.configs[0].IS_B2B = true
          this.rs.navigate(['/Purchase-maines_edit']);

     }


   

   


 
  }

   
}