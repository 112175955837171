import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import  { SharingService } from '../../../services/sharing.service';

import { Router } from '@angular/router';
import  { payment_model } from './model';

@Component({
  selector: 'app-payment-edit',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Payment_edit_Component implements OnInit {

arrayOfCusValues : any [] = [];
pay_options = ['Cash','Cheque'];
cheque_flag : boolean = false;
date_change_flag : number = 0;
date_c_change_flag : number = 0;
cash_date : string = '';
Cheque_Date : string = '';
pay_op : string = 'Cash';
pay_model = new payment_model(0,0,'','','','','',0,0,'','',0,0,0,true,false,0,0)
print_flag : boolean = false;
reciept_number : number = 0;

isClickedOnce : boolean = false;
onsucc : number = 0;
custoo : any ;
today: number = Date.now();
ser_res : boolean = false
ser_res2 : boolean = false
is_cash_date_edit : number = 0
fig_main : string = '';
fig_model : any = {number_to_convert : 0, msg:'hi'}
selectedOption : string = 'Cash'
     constructor(private router: Router,private ds: DataService, private ss: SharingService) {
    
     
    
  }
  

  ngOnInit() {

                this.update_intials()
                //this.get_vendors()
  
                this.date_c_change_flag = 0;

  

   
            }


            update_payment()
            {
                   this.pay_model.rem_due = (this.pay_model.amount_payed-0) - (this.pay_model.old_payment-0)

              console.log('this.pay',this.pay_model);

              this.ds.update_ex_payment(this.pay_model)
              .subscribe((jsonData) => { this.get_res3(jsonData)  
                                  },(err) => console.error(err),
                                  
                                  );


            }   
            
            
            get_res3(json:any)
            {
              console.log('res',json)
              if(json.msg === 'Updated Sucessfully')
              {
                alert('Updated Successfully')
                this.router.navigate(['/dash']);
              }
              else
              {
                alert('Updation Failed...')
                this.router.navigate(['/dash']);

              }
            }
            

  get_vendors()

{
  this.ds.get_vendors_all()
  .subscribe((jsonData) => { this.get_vendor_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
}


get_vendor_res(json:any)
{
  this.arrayOfCusValues = json;
  console.log('json............',json)
}


catch_chek_date(c_date:any)
{
  this.date_c_change_flag = 2
     this.Cheque_Date = c_date._validSelected;
    this.pay_model.cheque_Date = this.Cheque_Date;

}

change_vendor()
{

}

            update_intials()
            {
                this.pay_model.pay_number = this.ss.i_m.heads[0].INVOICE_NUMBER;               
                this.get_pay_details();
            }


            get_pay_details()
            {
              this.ds.get_payment_details_by_id(this.pay_model).subscribe((jsonData) => { this.get_pay_dets(jsonData)  
              },(err) => console.error(err),
               
              );
            }


get_pay_dets(data:any)
{

   console.log('data',data)
   this.pay_model.cus_id = data[0].CUS_ID;
   this.pay_model.vendor_name = data[0].CUS_NAME;
   this.pay_model.vendor_street = data[0].CUS_STREET;
   this.pay_model.entry_date = data[0].createdAt;
   this.pay_model.cash_date = data[0].CASH_DATE;
   this.pay_model.amount_payed = data[0].AMOUNT_PAYED;
   this.pay_model.cheque_Date = data[0].CHEQUE_DATE;
   this.pay_model.cheque_number = data[0].CHEQUE_NO;
   this.pay_model.bank_name = data[0].BANK_NAME;
   this.pay_model.old_payment = data[0].AMOUNT_PAYED;

   if(data[0].IS_CASH_PAY === false)
   {
    this.selectedOption  = 'Cheque';
    this.cheque_flag = true;
    this.pay_model.is_cheque_payment = true;
    this.date_c_change_flag = 2;
    this.Cheque_Date = data[0].CHEQUE_DATE;

   }

   
}


change_c_date()
{
  this.date_c_change_flag = 0;
}

get_reciept_no()
{

 this.ds.get_reciept_number_b2b().subscribe((jsonData) => { this.getval2(jsonData)  
                      },(err) => console.error(err),
                       
                      );

}

getval2(s:any)
{

 
 
  this.reciept_number = s.count + 1;
  this.ser_res2 = true;
  
}





change_cash_date()
{
  this.is_cash_date_edit = 1;
}

catch_invoice_date(s:any)
{
  this.is_cash_date_edit = 0 ;
  this.pay_model.cash_date = s._validSelected.toLocaleDateString();
}
  onChange_option(option)
{
   if(option === 'Cheque')
   {
      this.cheque_flag = true;
      this.pay_model.is_cheque_payment = true
     
   }
   else
   {
     this.cheque_flag = false;
     this.pay_model.is_cheque_payment = false;
     this.pay_model.bank_name = null;
     this.pay_model.cheque_Date = null;
     this.pay_model.cheque_number = null;
   }

 }



}
