import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SharingService } from '../services/sharing.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.html',
  styleUrls: ['./dash.css']
})
export class DashComponent  {
  
 b : number = 0;
 bc : number = 0;
 ngOnInit() {
  this.pop_items();
 
  }
  constructor(private ag : AuthGuard,private _formBuilder: FormBuilder,private router: Router,private ss : SharingService,private ds:DataService ) { }
  
  

  pop_items()
  {
    for (this.b = this.ss.i_m.items.length ; this.b > 0 ; this.b--)
    {
       this.ss.i_m.items.pop();
      
    }
    this.create_ss();
    this.get_company_info();

  }

  get_company_info()

  {
     this.ds.get_company_details()
              .subscribe((jsonData) => { this._get_dets(jsonData)
                      },(err) => console.error(err),
                       
                      );
  }


  _get_dets(json :any)
  {     

 this.ss.i_m.company_info[0].COMPANY_NAME = json.COMPANY_NAME;               
 this.ss.i_m.company_info[0].COMPANY_GST = json.COMPANY_GST;
 this.ss.i_m.company_info[0].COMPANY_PH1 = json.COMPANY_PH1;
 this.ss.i_m.company_info[0].COMPANY_PH2 = json.COMPANY_PH2;
 this.ss.i_m.company_info[0].COMPANY_ADR_1 = json.COMPANY_ADR_1;
 this.ss.i_m.company_info[0].COMPANY_ADR_2 = json.COMPANY_ADR_2;
 this.ss.i_m.company_info[0].COMPANY_ADR_3 = json.COMPANY_ADR_3;
 this.ss.i_m.company_info[0].COMPANY_EMAIL = json.COMPANY_EMAIL;     
 this.ss.i_m.company_info[0].COMPANY_BANK_NAME = json.COMPANY_BANK_NAME;
 this.ss.i_m.company_info[0].COMPANY_BANK_BRANCH = json.COMPANY_BANK_BRANCH;
 this.ss.i_m.company_info[0].COMPANY_BANK_AC_NO = json.COMPANY_BANK_AC_NO;
 this.ss.i_m.company_info[0].COMPANY_BANK_AC_IFSC = json.COMPANY_BANK_AC_IFSC;

 
       
 }  

 
  getval3(x:any)
  {

  
    if (x === 'Backup completed')
    {
      alert('Backup Completed')
    }
    else
    {
      alert(x);
    }
  }
   logout()
  {
    this.ag.token=false;
  }



  create_ss()
   {


 


if(this.ss.i_m.heads.length === 0)
{

  this.ss.i_m.duplicates.push({

     IS_D : null,
     sales_ret_paid_today : 0,
     sales_ret_total_cr_value : 0,
     sales_ret_rem_op_bal :  0
  

  })
  
  this.ss.i_m.company_info.push({

    COMPANY_NAME: null,
    COMPANY_GST: null,
    COMPANY_PH1 : null,
    COMPANY_PH2: null,
    COMPANY_ADR_1 : null,
    COMPANY_ADR_2 : null,
    COMPANY_ADR_3 : null,
    COMPANY_EMAIL : null,
    COMPANY_BANK_NAME : null,
    COMPANY_BANK_BRANCH : null,
    COMPANY_BANK_AC_NO : null,
    COMPANY_BANK_AC_IFSC : null
 
})



    this.ss.i_m.heads.push({

      INVOICE_NUMBER: null,
      SALES_RET_NUMBER: null,
      INVOICE_STRING: null,
      BILL_DATE : new Date().toISOString(),
      CUSTOMER_NAME: null,
      CUSTOMER_CITY : null,
      CUSTOMER_STREET : null,
      CUSTOMER_GST_IN : null,
      CUSTOMER_PHONE : null,
      CUSTOMER_PAN : null,
      CUSTOMER_PHONE2 : null,
      CUS_TYPE : null,
      TRANS_MODE : null,
      VEH_NO : null,
      PLACE_SUPPLY : null,
      BUNDLES : null,
      LPO : null,
      DN : null,
      CUS_DUE : 0,
      BILL_DUE : 0,
      SALE_RET_DATE : '',
      CUS_ID : 0,
      ROUTE_ID : null,
      USER_LOG : null

  })
 
  this.ss.i_m.tails.push({
 
 
     TOTAL_PAID_TODAY : 0,
     TOTAL_PRE_PAID : 0,
     SUB_TOTAL : 0,
     TOTAL_TAX : 0,
     TOTAL_CESS : 0,
     GRAND_TOTAL : 0,
     OLD_GRAND_TOTAL : 0,
     GRAND_TOTAL_DIFF :0,
     TOTAL_DUE : 0,
     OLD_DUE : 0,
     TOTAL_RETURN_AMT : 0,
     TOTAL_TAX_RETURN : 0,
     TOTAL_CESS_RETURN : 0,
     FLOOR_AMT : 0,
     GRAND_SUB_TOTAL : 0,
     DISC_PERCENT:0,
     mrp_total:0,
     You_Save:0,
     floor:0

 
 })
 
 
 this.ss.i_m.configs.push({
 
     ITEM_LENGTH : 0,
     ITEM_OLD_LENGTH : 0,
     IS_B2B : false,
     IS_PARTIAL_PAY : true,
     IS_DEL_POSSIBLE : true ,
     IS_ON_UPDATE : false ,
     ACTIVE_TAX_COUNT : 0,
     IS_ALREADY_RET : false
 
 
 })
 
 this.ss.i_m.flags.push({
 
     IS_INVO_NUMBER_LOADED : false,
     DATE_CHANGE_FLAG : 0,
     IS_SERVER_RES : false,
     CHANGE_OVER_FLAG : false,
     IS_PAYMENT_ON_BILL : false,
     IS_PAYMENT_ON_UPDATE : false,
     IS_UPDATE_BUTTON_ENABLE : false,
     IS_UPDATE_ENTRY_CORRECT : false,
     IS_LISTED_CUSTOMER : false

 
 
 })

 this.ss.i_m.items.push({ SI_NO:1,ID:0, DESCRIPTION:'',HSN_CODE:'',FREE_ITEM:0,BAR_CODE:'',
 CATEGORY:'',QTY:1,TAX:0,CESS_AMT:0,MRP:0,BATCH_NO:null,EXPIRY_DATE:null,PRICE_COPY:0,MASTER_ID:0,
 PRICE:0,UNIT:'pc',ALT_QTY:0,ALT_UNIT:'',NET_PRICE:0,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,RET_QTY:0,STOCK_RET_QTY:0 })



 this.ss.i_m.varibs.push({
 
  I : -1,
  K : -1,
  Z : -1


})
}

else
{
  this.reset_heads()
}

this.ss.i_m.configs[0].IS_ON_UPDATE = false;
   }






   reset_heads()
   {
     this.ss.i_m.configs.pop();
     this.ss.i_m.company_info.pop();
     this.ss.i_m.flags.pop();
     this.ss.i_m.heads.pop();
     this.ss.i_m.tails.pop();
     this.ss.i_m.varibs.pop();
     this.ss.i_m.duplicates.pop();
     for (this.b = this.ss.i_m.items.length ; this.b > 0 ; this.b--)
     {
        this.ss.i_m.items.pop();
       
     }

     this.create_ss();


     for (this.bc = this.ss.i_m.old_items.length ; this.bc > 0 ; this.bc--)
     {
      this.ss.i_m.old_items.pop();
     }

   }





  update_customer()
  {
  
   this.router.navigate(['/update-customer']);

  }

 


    add_member()
    {
       this.router.navigate(['/add_product']);

    }

    add_party()
    {
      this.router.navigate(['/add_party']);
    }
  add_reciept()
  {
   this.router.navigate(['/invoice_reciept']);

  }
  exp_detail_entry()
  {

     this.router.navigate(['/exp_det']);

  }
 go_report()
  {
   this.router.navigate(['/estimate']);

  }
  all_invo()

  {
       this.router.navigate(['/invoice_all']);
  }

  add_cus()

  {
     this.router.navigate(['/add_customer']);
  }

  add_exp_cat()
  {

this.router.navigate(['/add_exp_cat']);

  }
  
  add_exp_entry()
  {
    this.router.navigate(['/add_exp_entry']);
  }

  go_estimate()
  {
     this.router.navigate(['/estimate_reciept']);
  }

estimate_list()
{
  this.router.navigate(['/estimate_list']);
}

update_product()
{
  this.router.navigate(['/update_product']);
}

b2c()
{

this.router.navigate(['/b2c-invoice']);
  
}

all_invo_b2b()
{
  this.router.navigate(['/b2b-all-invo']);
}

cash_recieve()
{
  
  this.router.navigate(['/cash-recieve']);
  
}
customer_statement()
{
  this.router.navigate(['/customer-statement']);
}


}