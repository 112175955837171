 export class cash_model {
    constructor(
      
  public rec_number : number,
  public cus_street : string ,
  public cus_name : string,
  public cus_id : number,
  public cash_date : string,
  public amount_payed : number ,
  public due_balance : number ,
  public cheque_Date : string ,
  public bank_name : string ,
  public cheque_number : number,
  public balance_amt : number,
 
  public is_cash_pay : boolean,
  public is_reciept : boolean,
  public credit_bal : number,
  public reference_number : string,
  public is_neft : boolean
  
  
   
      
    ) {  }
  }