export class p_model 
{
constructor(
          
          public DESCRIPTION: string,
          public HSN_CODE: string,
          public AVAIL_QTY : number,
          public SPECIFICATION : string,
          public NET_PRICE : number,
          public CATGERY : string,
          public NET_VALUE : number,
          public ROUTE_ID : string,
          public QUANTITY : number
      
         
          
) { }
}


export class items {
    constructor(
      
           public si : number ,
           public item : sublist[],
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  public SI : number,
  public DESCRIPTION : string,
  public QUANTITY : number,
  public ROUTE_ID : string,

  
    ) {  }
  }
  