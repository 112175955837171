import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
@Component({
  selector: 'app-add-unit',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Add_unit implements OnInit {

 product_Form: FormGroup;

 
 snak_msg : string = '';
 modal : any;
 btn : any;
 span : any;
 cats :any[] = [];
 myModal_close : any

     constructor(private router: Router, private ds: DataService ,private fb: FormBuilder) {
    
     
    
  }
  

  ngOnInit() {

   

    this.modal = document.getElementById("myModal");

// Get the button that opens the modal
this.btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
this.span = document.getElementsByClassName("close")[0];




this.product_Form = this.fb.group({ 

  unit_name : ['']


})

  
  }






  open_modal()
  {
    
    this.modal.style.display = "block";
  }
  close_modal()
 {
  this.modal.style.display = "none";
 }
  

  add_route()
  {
   

    if(this.product_Form.controls.unit_name.value === null)
    {
      this.snak_fun('Empty Name field');
    }
   else
   {

    this.ds.add_unit(this.product_Form.value)
    .subscribe((jsonData) => { this.get_res_unit(jsonData)
                    },(err) => console.error(err),
                   
                    );

  }
  }
  get_res_unit(json:any)
  {
   
      this.snak_fun(json.msg);
     

    
  }




snak_fun(msg:string)
{
      this.snak_msg = msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
    
}


}