import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { SharingService } from '../services/sharing.service';
import { DataService } from '../services/data.service';
import { User_role } from '../services/user_role';
import { AuthGuard } from '../services/auth.guard';


@Component({
  selector: 'app-return',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class ReturnComponent implements OnInit {
 
  invoice_string: string = ''
  isClickedOnce : boolean = false
  d_ : number = 0;
  j : number = 0
  test_cc : number = 0;
  fystring : string[] = [];
  isClickedSave : boolean = false;
  post_model : any = {invo_string : "",is_b2b:false , invo_number: 0}
  temp_cess : number[] = [];
  temp_tax : number[] = [];
  temp_to_amt : number = 0;
  total_ret_qty : number = 0;
  total_qty : number = 0;
  fig_main : string = null
fig_model : any = { number_to_convert : 0,fraction:0,msg : ''};
cus_model : any = { cus_id:-1,msg : ''};
  total_ret_sub : number = 0;

 
  constructor( private router: Router,private ds: DataService,private fb: FormBuilder, private ss : SharingService , private ag: AuthGuard , private ur : User_role) {   }
  ngOnInit()  {
  
  this.post_model.invo_string =  this.ss.i_m.heads[0].INVOICE_STRING 
   this.post_model.is_b2b = this.ss.i_m.configs[0].IS_B2B
   this.post_model.invo_number = this.ss.i_m.heads[0].INVOICE_NUMBER
   this.search_invoice()
  
   this.get_sales_ret_no()
   this.get_def_company_dets();

  }
    
  get_def_company_dets()
  {
    this.ds.get_def_company_details().subscribe((jsonData) => { this._get_def_com_dets(jsonData)  
    },(err) => console.error(err),);
  }
  _get_def_com_dets(json:any)
  {
    
    
    this.ss.i_m.company_info[0].COMPANY_NAME = json.COMPANY_NAME;
    this.ss.i_m.company_info[0].COMPANY_ADR_1 = json.COMPANY_ADR_1;
    this.ss.i_m.company_info[0].COMPANY_ADR_2 = json.COMPANY_ADR_2;
    this.ss.i_m.company_info[0].COMPANY_ADR_2 = json.COMPANY_ADR_2;
    this.ss.i_m.company_info[0].COMPANY_GST = json.COMPANY_GST;
    this.ss.i_m.company_info[0].COMPANY_PH1 = json.COMPANY_PH1;
    this.ss.i_m.company_info[0].COMPANY_PH2 = json.COMPANY_PH2;
    this.ss.i_m.company_info[0].COMPANY_EMAIL = json.COMPANY_EMAIL;
    this.ss.i_m.company_info[0].COMPANY_BANK_NAME = json.COMPANY_BANK_NAME
    this.ss.i_m.company_info[0].COMPANY_BANK_AC_IFSC = json.COMPANY_BANK_AC_IFSC
    this.ss.i_m.company_info[0].COMPANY_BANK_AC_NO = json.COMPANY_BANK_AC_NO
    this.ss.i_m.company_info[0].COMPANY_BANK_BRANCH = json.COMPANY_BANK_BRANCH
    
  }


  get_sales_ret_no()
  {
   
       this.ds.get_sales_return_no().subscribe((jsonData) => { this._get_sales_ret_number(jsonData)  
        },(err) => console.error(err),);
    }

_get_sales_ret_number(json:any)
{


this.ss.i_m.heads[0].SALES_RET_NUMBER = json.count + 1;


this.ss.i_m.flags[0].IS_INVO_NUMBER_LOADED = true
}
  
catch_invoice_date(s:any)
{ 
  
   this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 3 ;
 
   this.ss.i_m.heads[0].SALE_RET_DATE = s._validSelected.toLocaleDateString();

 
}

change_date()
{
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 0;
}
   
  
    search_invoice()
    {
      


     this.ds.get_a_invoice_return(this.post_model)
  .subscribe((jsonData) => { this._get_a_invoice(jsonData) 
    },(err) => console.error(err));

    
    }

    _get_a_invoice(data : any)
    {
     
      if(data.success === false)
      {
        alert('Bill not found')
      }
      else
      {
       
        this.make_master(data.master)
        this.make_slave(data.slave)
        


      }
    
  
    
    }
    

    is_ret(index:any)
    {
      
       

 

      this.calculate_ret_amount(index)


    }

    calculate_ret_amount(index:any)
    {
    
                  this.total_qty = 0;
                  this.total_ret_qty = 0;
                  this.total_ret_sub = 0;
                  this.test_cc = 0;
                  this.ss.i_m.tails[0].TOTAL_RETURN_AMT  = 0;
                  this.ss.i_m.tails[0].TOTAL_CESS_RETURN = 0;
                  this.ss.i_m.tails[0].TOTAL_TAX_RETURN = 0;


     

                  for(var i = 0; i< this.ss.i_m.configs[0].ITEM_LENGTH ; i++)

                  {

                    this.temp_tax[i] = 0;
                    this.temp_cess[i] = 0;  
               

                    this.total_qty = this.total_qty + this.ss.i_m.items[i].QTY;
                   
                    if( this.ss.i_m.items[i].IS_RETURN === true )
                    {


                      this.total_ret_qty = (this.total_ret_qty-0) + (this.ss.i_m.items[i].RET_QTY-0);
                                            if(this.ss.i_m.items[i].QTY < this.ss.i_m.items[i].RET_QTY)
                                                  {                                                 
                                                 this.test_cc =  1;
                                                  }

                        this.total_ret_sub = this.total_ret_sub + (this.ss.i_m.items[i].PRICE * this.ss.i_m.items[i].RET_QTY)

                    }

                  }





           for(var i = 0; i< this.ss.i_m.configs[0].ITEM_LENGTH ; i++)

                { 
                        
                     if(this.test_cc === 0)
                     {

                     }

                     else

                     {
                      alert('Return Quantity Should Be less than Sales Quantity')
                       
                      i = this.ss.i_m.configs[0].ITEM_LENGTH;

                     }

                 }


                 for(var i = 0; i< this.ss.i_m.configs[0].ITEM_LENGTH ; i++)

                 { 

                  if( this.ss.i_m.items[i].IS_RETURN === true )
                  {

                  this.temp_tax[i] = ((this.ss.i_m.items[i].PRICE  *  this.ss.i_m.items[i].TAX)/100) * this.ss.i_m.items[i].RET_QTY ;
                  
                                   

                  }


                                                      if(this.ss.i_m.items[i].TAX > 5 && this.ss.i_m.configs[0].IS_B2B === false )
                                                       
                                           {

                                               this.d_ = ((this.ss.i_m.items[i].PRICE * 1 ) / 100)

                                                 this.temp_cess[i] = (this.d_ * this.ss.i_m.items[i].RET_QTY )

                                              
                                           }


                                           
           


                 }


                 for(var i = 0; i< this.ss.i_m.configs[0].ITEM_LENGTH ; i++)

                 { 

                  this.ss.i_m.tails[0].TOTAL_CESS_RETURN = (this.temp_cess[i]-0) + (this.ss.i_m.tails[0].TOTAL_CESS_RETURN-0)
                 this.ss.i_m.tails[0].TOTAL_TAX_RETURN  =   (this.temp_tax[i]-0) + (this.ss.i_m.tails[0].TOTAL_TAX_RETURN-0)  

                 this.ss.i_m.tails[0].TOTAL_RETURN_AMT =    (this.ss.i_m.tails[0].TOTAL_TAX_RETURN-0) + ( this.ss.i_m.tails[0].TOTAL_CESS_RETURN-0) + (this.total_ret_sub-0)
                   

                 this.ss.i_m.tails[0].TOTAL_RETURN_AMT =  Math.round(this.ss.i_m.tails[0].TOTAL_RETURN_AMT * 100) / 100;


                 }

                 this.ss.i_m.tails[0].TOTAL_PRE_PAID =  (this.ss.i_m.tails[0].TOTAL_RETURN_AMT-0) -  (this.ss.i_m.tails[0].OLD_DUE-0)
                 this.ss.i_m.tails[0].TOTAL_PRE_PAID =  Math.round(this.ss.i_m.tails[0].TOTAL_PRE_PAID * 100) / 100;

                 this.ss.i_m.tails[0].TOTAL_DUE = (this.ss.i_m.tails[0].TOTAL_PRE_PAID  - this.ss.i_m.tails[0].TOTAL_PAID_TODAY) 
                 this.ss.i_m.tails[0].TOTAL_DUE =  Math.round(this.ss.i_m.tails[0].TOTAL_DUE * 100) / 100;
                
                 

 this.get_figure(  this.ss.i_m.tails[0].TOTAL_RETURN_AMT)

    }

    blur_paid_today()
    {
      this.ss.i_m.tails[0].TOTAL_DUE = (this.ss.i_m.tails[0].TOTAL_PRE_PAID  - this.ss.i_m.tails[0].TOTAL_PAID_TODAY) 
      this.ss.i_m.tails[0].TOTAL_DUE =  Math.round(this.ss.i_m.tails[0].TOTAL_DUE * 100) / 100;
    }

    keyup_on_paid_today()
    {
      this.ss.i_m.tails[0].TOTAL_DUE = (this.ss.i_m.tails[0].TOTAL_PRE_PAID  - this.ss.i_m.tails[0].TOTAL_PAID_TODAY) 
      this.ss.i_m.tails[0].TOTAL_DUE =  Math.round(this.ss.i_m.tails[0].TOTAL_DUE * 100) / 100;
    }

    keyup_on_paid_today_b2b()
    {
      this.ss.i_m.tails[0].TOTAL_DUE = (this.ss.i_m.tails[0].TOTAL_PRE_PAID  - this.ss.i_m.tails[0].TOTAL_PAID_TODAY) 
      this.ss.i_m.tails[0].TOTAL_DUE =  Math.round(this.ss.i_m.tails[0].TOTAL_DUE * 100) / 100;
       this.ss.i_m.duplicates[0].sales_ret_total_cr_value = 
         (this.ss.i_m.tails[0].TOTAL_RETURN_AMT-0) + (this.ss.i_m.tails[0].TOTAL_PAID_TODAY-0)
     }




    get_figure(x:any)
    {
        
      this.fig_model.number_to_convert = x;
       this.ds.get_figure(this.fig_model)
       .subscribe((jsonData) => { this.getjson87(jsonData)
                          },(err) => console.error(err),
                           
                          );
    
    }
    
    getjson87 (p:any)
    {
     
       this.fig_main = p.msg;
      
     
    }  
  

  

        

            

   
make_master(data)
{
  this.ss.i_m.heads[0].CUS_ID = data.CUS_ID;
  this.ss.i_m.heads[0].BUNDLES = data.BUNDLES
  this.ss.i_m.heads[0].CUSTOMER_CITY = data.CUSTOMER_CITY
  this.ss.i_m.heads[0].CUSTOMER_GST_IN = data.CUSTOMER_GST_IN
  this.ss.i_m.heads[0].CUSTOMER_NAME = data.CUSTOMER_NAME
 
  this.ss.i_m.heads[0].CUSTOMER_PHONE = data.CUSTOMER_PHONE
  this.ss.i_m.heads[0].CUSTOMER_PHONE2 = data.CUSTOMER_PHONE2
  this.ss.i_m.heads[0].CUSTOMER_STREET = data.CUSTOMER_STREET
  this.ss.i_m.heads[0].PLACE_SUPPLY = data.PLACE_SUPPLY
  this.ss.i_m.heads[0].TRANS_MODE = data.TRANS_MODE
  this.ss.i_m.heads[0].VEH_NO = data.VEH_NO
  this.ss.i_m.heads[0].INVOICE_NUMBER = data.INVOICE_NUMBER
  this.ss.i_m.heads[0].INVOICE_STRING = data.INVOICE_STRING
  this.ss.i_m.heads[0].CUSTOMER_PAN = data.PAN_NO

  this.ss.i_m.tails[0].GRAND_TOTAL = data.GRAND_TOTAL
  this.ss.i_m.tails[0].TOTAL_TAX = data.TAX_COLLECTED
  this.ss.i_m.tails[0].SUB_TOTAL = data.SUB_TOTAL
  this.ss.i_m.tails[0].TOTAL_CESS = data.CESS_COLLECTED
  // this.ss.i_m.tails[0].TOTAL_DUE = data.TOTAL_DUE
  
  this.ss.i_m.tails[0].TOTAL_PRE_PAID = 0;
  
  this.ss.i_m.tails[0].TOTAL_PAID_TODAY = 0;
  this.ss.i_m.tails[0].GRAND_TOTAL = data.GRAND_TOTAL;
  this.ss.i_m.configs[0].IS_PARTIAL_PAY = data.IS_PARTIAL_PAY;
  this.ss.i_m.configs[0].ITEM_LENGTH = data.ITEM_LENGTH;
  this.ss.i_m.configs[0].ITEM_OLD_LENGTH = data.ITEM_LENGTH;
  this.ss.i_m.configs[0].IS_B2B = data.IS_B2B;
  this.ss.i_m.varibs[0].I = data.ITEM_LENGTH -  2;
  this.ss.i_m.varibs[0].K = data.ITEM_LENGTH -  2;
  this.ss.i_m.flags[0].IS_INVO_NUMBER_LOADED = true;
  this.ss.i_m.flags[0].DATE_CHANGE_FLAG = 2 ;
  this.ss.i_m.flags[0].CHANGE_OVER_FLAG = true;

  this.ss.i_m.tails[0].TOTAL_RETURN_AMT = 0;
  this.ss.i_m.heads[0].BILL_DATE = data.BILL_DATE;
  this.ss.i_m.heads[0].SALE_RET_DATE = data.BILL_DATE;

  if(this.ss.i_m.heads[0].CUS_ID > 0 )
  {
    this.get_opening_bal_by_cus_id(this.ss.i_m.heads[0].CUS_ID);
  }

  else
  {
    this.ss.i_m.tails[0].OLD_DUE = data.TOTAL_DUE
  }

  
 
}

get_opening_bal_by_cus_id(id:any)

{
   this.cus_model.cus_id = id;
   this.ds.get_customers_vendors_details_by_id(this.cus_model)
   .subscribe((jsonData) => { this._get_ret_res_cus_id(jsonData) 
   },(err) => console.error(err));
}

_get_ret_res_cus_id(json:any)
{
  console.log('re',json)

  this.ss.i_m.tails[0].OLD_DUE = json.OPENING_BALANCE;
}


make_slave(data)
{
  this.push_ss_edit(data.length-1);
  for(var i=0;i<data.length;i++)
  {

    this.ss.i_m.items[i].DESCRIPTION = data[i].PRODUCT_DESCRIPTION   
     
     this.ss.i_m.items[i].CATEGORY = data[i].CATGERY
    this.ss.i_m.items[i].HSN_CODE = data[i].HSN_CODE
   
    
    this.ss.i_m.items[i].QTY = (data[i].QUANTITY-0) - (data[i].RET_QTY-0) 
    this.ss.i_m.items[i].SI_NO = data[i].SI_NO
    this.ss.i_m.items[i].UNIT = data[i].UNIT
    this.ss.i_m.items[i].TAX = data[i].TAX
    this.ss.i_m.items[i].NET_PRICE = data[i].NET_PRICE 


    this.d_ = this.ss.i_m.items[i].NET_PRICE * 100 / (100 + this.ss.i_m.items[i].TAX);
    this.ss.i_m.items[i].PRICE = this.d_
    // this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE =  Math.round(this.ss.i_m.items[this.ss.i_m.varibs[0].K].PRICE * 100) / 100;
     this.ss.i_m.items[i].PRICE = Math.round(this.ss.i_m.items[i].PRICE * 100) / 100;  

  }  

  this.ss.i_m.flags[0].IS_SERVER_RES = true  
}
push_ss_edit(len)
{
  for(var i=0;i<len;i++)
        {          
          this.ss.i_m.items.push({ SI_NO:0,ID:0,DESCRIPTION:'',HSN_CODE:'',CATEGORY:'',BAR_CODE:'',MASTER_ID:0,
          QTY:1,TAX:null,CESS_AMT:0,MRP:0,BATCH_NO:null,EXPIRY_DATE:null,FREE_ITEM:0,PRICE_COPY:0,
          PRICE:null,UNIT:null,NET_PRICE:null,NET_PRICE_COPY:0,RATE_SUM:0,ITEM_SUM:0,IS_RETURN:false,ALT_QTY: 0,ALT_UNIT:'',RET_QTY:0,STOCK_RET_QTY:0})    
        }
}


save_return()
{

  this.isClickedSave = true

 


  this.ds.post_a_return(this.ss.i_m)
  .subscribe((jsonData) => { this._get_ret_res(jsonData) 
    },(err) => console.error(err));



}

_get_ret_res(json:any)
{
 

  if(json.success === true)
    {
      alert('Return updated');
      window.print();
      this.router.navigate(['/dash']);
    }
}
  
}



