import { Component,OnInit } from '@angular/core';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { SharingService } from '../../services/sharing.service';
import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import { ExcelService } from '../../services/excel.service';
 import { items } from './model';
 import { TableUtil } from "../auditor/tableUtil";

 
 


@Component({
    selector: 'cus-slaes-report',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class Sales_statement_all_customer {
  
 

    option : any;
    post_model : any = {option:false,from_date:'',to_date: '' }
    click : number = 0;

    post_invo = new items(1,[]);
   

    data : any [] =[];
    data2 : any[] = [];

    total_tax_ret : number = 0;
    total_tax_qty : number = 0;
    temp_ : number = 0;
   
  
   
    
    
    
    view : boolean = false;
    view1 : boolean = false;
    length2 : number = 0;
    length : number = 0;
    total_sub_total : number =0;
    total_tax : number =0;
    total_grand : number = 0;
    total_cess : number = 0;


    total_sub_total_c : number = 0;
    total_tax_c : number = 0;
    total_grand_c : number = 0;



     bool : boolean ;
     total_cess_c : number = 0;

     to_igst : number = 0;
     to_cgst : number = 0;
     to_sgst : number = 0;
     to_amt : number = 0;
     total_cess_ret : number = 0;
    
 
    


    constructor( private fb: FormBuilder, private ds : DataService, private es: ExcelService,
      private uS: UpdateService, private rs: Router ,private Rs: ReportService, private ss: SharingService ) {
       
    }
ngOnInit() {
   

   this.generate_report()
  }



 exportAsXLSX():void {
   
  
  TableUtil.exportToExcel("SaleTable_alal_cus");

  }

  print_report()
  {
    window.print();
  }


generate_report()
{
  this.click = 1;
   this.post_model.from_date = this.Rs.from_date;
   this.post_model.to_date = this.Rs.to_date;
  

   
       this.ds.get_audit_report_b2c(this.post_model)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );


 



}







     getjson(ss:any) {

         this.view = true;
     this.data = ss;
     console.log('data.....',this.data);
     this.length = this.data.length;
   
   this.express(this.length)

         }



 express(s: number)
  {
      console.log('da',this.data);

      var bi = 0;
     
      this.total_sub_total_c = 0;

       for(var i = 0; i < s ; i++)
       {

             this.auto_push();
            
             this.post_invo.item[bi].INVOICE_NUMBER = this.data[i].INVOICE_NUMBER;
             this.post_invo.item[bi].CUSTOMER_NAME = this.data[i].CUSTOMER_NAME;
             this.post_invo.item[bi].BILL_DATE = this.data[i].BILL_DATE;
             this.post_invo.item[bi].CUSTOMER_GST_IN = this.data[i].CUSTOMER_GST_IN;
             this.post_invo.item[bi].SUB_TOTAL = (this.data[i].GRAND_TOTAL-0) -  (this.data[i].TAX_COLLECTED-0);
             this.post_invo.item[bi].TAX_AMOUNT = this.data[i].TAX_COLLECTED-0

             this.total_sub_total_c = this.total_sub_total_c + this.post_invo.item[bi].SUB_TOTAL;

             if (this.data[i].IS_B2B === true)

                {
                  this.post_invo.item[bi].INVOICE_TYPE = 'B2B';
                }
              else

              {
                this.post_invo.item[bi].INVOICE_TYPE = 'B2C';

              }

            
    
             this.post_invo.item[bi].GRAND_TOTAL = this.data[i].GRAND_TOTAL;
     
                    this.post_invo.item[bi].SI = i+1;

                    this.total_sub_total = (this.total_sub_total-0) + (this.data[i].SUB_TOTAL-0);
 
                     this.total_tax = (this.total_tax-0) + (this.data[i].TAX_COLLECTED-0); 

                       this.total_cess =   (this.total_cess-0) + (this.post_invo.item[bi].CESS_COLLECTED-0) ;

                     this.total_grand = (this.total_grand-0) + (this.data[i].GRAND_TOTAL-0); 

                     bi = bi + 1;

                     this.post_invo.item[bi-1].SI = bi;


       }

    this.totooo(s)

 
 }


totooo(e:number)
{
  this.auto_push()
  this.auto_push()
  this.post_invo.item[e+1].CUSTOMER_GST_IN = 'Total'
  this.post_invo.item[e+1].SUB_TOTAL =  this.total_sub_total_c;
  this.post_invo.item[e+1].GRAND_TOTAL = this.total_grand;
  this.post_invo.item[e+1].TAX_AMOUNT = (this.total_grand-0) - (this.total_sub_total_c)
}


   print_re()
  {
    window.print();
  }


 auto_push()
  {
       
        this.post_invo.item.push({ "SI":null,
         "INVOICE_NUMBER":null,"INVOICE_TYPE":'',"BILL_DATE":'',"CUSTOMER_NAME":'',"CESS_COLLECTED":null,
         "CUSTOMER_GST_IN" :'', "SUB_TOTAL":null,TAX_AMOUNT:null,
          "GRAND_TOTAL":null }) 
          
          


  }

        
}