import { Component,OnInit } from '@angular/core';
import {  UpdateService } from '../../services/update.service';
import {  ReportService } from '../../services/report.service';
import { DataService } from '../../services/data.service';
import { SharingService } from '../../services/sharing.service';
import { Router } from '@angular/router';

import { TableUtil } from "./tableUtil";

import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';

 import { items } from './model';
 import { items_r } from './model';
 import { items_pur } from './model';
 import { items_pur_ret } from './model';
 
 
 


@Component({
    selector: 'cus-auditor-report',
    templateUrl: './auditor.html',
    styleUrls: ['./auditor.css']
})
export class Auditor_statement {
  
 

    option : any;
    post_model : any = {option:false,from_date:'',to_date: '' }
    click : number = 0;

    post_invo = new items(1,[]);
    post_invo_c = new items(1,[]);
    post_invo_r = new items_r(1,[]);
    post_invo_pur = new items_pur(1,[]);
    post_invo_pur_ret = new items_pur_ret(1,[]);

    data : any [] =[];
    data2 : any[] = [];

    total_tax_ret : number = 0;
    total_tax_qty : number = 0;
    temp_ : number = 0;
   
  
   
    
    
    
    view : boolean = false;
    view1 : boolean = false;
    length2 : number = 0;
    length : number = 0;
    total_sub_total : number =0;
    total_tax : number =0;
    total_grand : number = 0;
    total_cess : number = 0;
    total_sub_total_c : number = 0;
    total_tax_c : number = 0;
    total_grand_c : number = 0;
     bool : boolean ;
     total_cess_c : number = 0;

     to_igst : number = 0;
     to_cgst : number = 0;
     to_sgst : number = 0;
     to_amt : number = 0;
     to_ret_qty : number = 0;
     to_tax_rev : number = 0;
     to_amt_rev : number = 0;
     total_tax_ret_amt : number = 0;

     total_cess_ret : number = 0;
    
 
    


    constructor( private fb: FormBuilder, private ds : DataService, 
      private uS: UpdateService, private rs: Router ,private Rs: ReportService,private ss : SharingService ) {
       
    }
ngOnInit() {
   

   this.generate_report()
  }

slide_change(x:any)
{
  
  if (x.checked === true)
   {
     this.post_model.option = true
   }

   else
   {

      this.post_model.option = false
   }
}

generate_report()
{
  this.click = 1;
   this.post_model.from_date = this.Rs.from_date;
   this.post_model.to_date = this.Rs.to_date;
  

   
       this.ds.get_audit_report_b2c(this.post_model)
       .subscribe((jsonData) => { this.getjson(jsonData)
                      },(err) => console.error(err),
                      
                      );


    this.ds.get_audit_report_ret(this.post_model)
       .subscribe((jsonData) => { this.getjson_ret(jsonData)
                      },(err) => console.error(err),
                      
                      );


       this.ds.get_purchase_report(this.post_model)
         .subscribe((jsonData) => { this.getjson_pur(jsonData)
                      },(err) => console.error(err),
                      
                      );

         this.ds.get_all_pur_return_det()
         .subscribe((jsonData) => { this.getjson_pur_ret(jsonData)
                      },(err) => console.error(err),
                      
                      );



}

getjson_pur_ret(json:any)
{


     for(var od = 0 ; od < json.length ; od++)
              {
                 
                     this.push_pur_ret_item()
                      this.post_invo_pur_ret.item[od].SI_NO = od +1 ;
                      this.post_invo_pur_ret.item[od].EXPENCE_ENTRY_NO = json[od].ENTRY_NO
                      this.post_invo_pur_ret.item[od].VENDOR = json[od].VENDOR_NAME
                      this.post_invo_pur_ret.item[od].VENDOR_STREET = json[od].VENDOR_STREET
                      this.post_invo_pur_ret.item[od].DESCRIPTION = json[od].DESCRIPTION
                      this.post_invo_pur_ret.item[od].RET_QTY = json[od].RET_QTY
                      this.post_invo_pur_ret.item[od].UNIT = json[od].UNIT
                      this.post_invo_pur_ret.item[od].PRICE = json[od].PRICE
                      this.post_invo_pur_ret.item[od].RETURN_DATE = json[od].RETURN_DATE
                      this.post_invo_pur_ret.item[od].TAX = json[od].TAX

                      this.post_invo_pur_ret.item[od].TAX_AMT = (this.post_invo_pur_ret.item[od].PRICE * this.post_invo_pur_ret.item[od].RET_QTY)/this.post_invo_pur_ret.item[od].TAX 

                      this.post_invo_pur_ret.item[od].RET_AMT  = (this.post_invo_pur_ret.item[od].PRICE * this.post_invo_pur_ret.item[od].RET_QTY) + (this.post_invo_pur_ret.item[od].TAX_AMT-0);

                      this.to_ret_qty = (this.to_ret_qty-0) +  this.post_invo_pur_ret.item[od].RET_QTY;
                      this.to_tax_rev = (this.to_tax_rev-0) + this.post_invo_pur_ret.item[od].TAX_AMT;
                      this.to_amt_rev = (this.to_amt_rev-0) + this.post_invo_pur_ret.item[od].RET_AMT

              }  




}


push_pur_ret_item()
{
  this.post_invo_pur_ret.item.push({SI_NO:0,EXPENCE_ENTRY_NO:0,VENDOR:'',VENDOR_STREET:'',DESCRIPTION:'',RET_QTY:0,UNIT:'',
    PRICE:0,RETURN_DATE:'',TAX:0,TAX_AMT:0,RET_AMT:0})
}



getjson_pur(json:any)
{
  

    if(json.success === true)
      {
            for(var oo = 0 ; oo < json.data.length ; oo++)
              {
                  this.push_pur_item()
                  this.post_invo_pur.item[oo].SI_NO = oo+1;
                this.post_invo_pur.item[oo].BILL_NO = json.data[oo].BILL_NO
                this.post_invo_pur.item[oo].CGST = json.data[oo].CGST
                this.post_invo_pur.item[oo].EXPENCE_ENTRY_NO = json.data[oo].EXPENCE_ENTRY_NO
                this.post_invo_pur.item[oo].EXP_DATE = json.data[oo].EXP_DATE
                this.post_invo_pur.item[oo].IGST = json.data[oo].IGST
                this.post_invo_pur.item[oo].SGST = json.data[oo].SGST
                this.post_invo_pur.item[oo].CUS_GST = json.data[oo].CUS_GST
                this.post_invo_pur.item[oo].TOTAL_AMOUNT = json.data[oo].TOTAL_AMOUNT
                this.post_invo_pur.item[oo].VENDOR = json.data[oo].VENDOR
                
                this.to_igst = (this.to_igst-0) + (json.data[oo].IGST-0)
                 this.to_cgst = (this.to_cgst-0) + (json.data[oo].CGST-0)
                  this.to_sgst = (this.to_sgst-0) + (json.data[oo].SGST-0)
                  this.to_amt = (this.to_amt-0) + (json.data[oo].TOTAL_AMOUNT-0)
              }

      }



}


push_pur_item()
{
  this.post_invo_pur.item.push({SI_NO:0,BILL_NO:0,CGST:0,EXPENCE_ENTRY_NO:0,EXP_DATE:'',IGST:0,SGST:0,TOTAL_AMOUNT:0,VENDOR:'',CUS_GST:''})
}
         
getjson_ret(sss:any)
{
   

   this.view1 = true;
   this.data2 = sss;
   this.length2 = this.data2.length;
    this.express_2(this.length2)
    

}


     getjson(ss:any) {

         this.view = true;
     this.data = ss;
     
     this.length = this.data.length;
   
   this.express(this.length)

         }

express_2(s:number)
{
   for(var i = 0; i < s ; i++)
       {




            this.auto_push_r();
          this.post_invo_r.item[i].SI_NO = i+1;
       
          this.post_invo_r.item[i].ID = this.data2[i].ID
          this.post_invo_r.item[i].INVOICE_NO = this.data2[i].INVOICE_NO
          
          this.post_invo_r.item[i].IS_B2B = this.data2[i].IS_B2B
          this.post_invo_r.item[i].CUSTOMER_NAME = this.data2[i].CUSTOMER_NAME
          this.post_invo_r.item[i].CUSTOMER_GST_IN = this.data2[i].CUSTOMER_GST_IN
          this.post_invo_r.item[i].RETURN_DATE = this.data2[i].RETURN_DATE
          this.post_invo_r.item[i].BILL_DATE = this.data2[i].BILL_DATE
          this.post_invo_r.item[i].ITEM_LENGTH = this.data[i].ITEM_LENGTH
          this.post_invo_r.item[i].SALES_RET_AMT = this.data2[i].SALES_RET_AMT
          this.post_invo_r.item[i].SALES_RET_GST = this.data2[i].SALES_RET_GST
          this.post_invo_r.item[i].SALES_RET_CESS = this.data2[i].SALES_RET_CESS

           this.total_tax_ret = (this.total_tax_ret-0) + (this.post_invo_r.item[i].SALES_RET_GST-0);
           this.total_tax_ret_amt = (this.total_tax_ret_amt-0) + (this.post_invo_r.item[i].SALES_RET_AMT-0);
           this.total_cess_ret = (this.total_cess_ret-0) + (  this.post_invo_r.item[i].SALES_RET_CESS-0);
        }
this.view1 = true;
}


 express(s: number)
  {
      

      var bi = 0;
      var ci = 0;

       for(var i = 0; i < s ; i++)
       {

          
         if(this.data[i].IS_B2B === true)
         {
             this.auto_push();
            
             this.post_invo.item[bi].INVOICE_NUMBER = this.data[i].INVOICE_NUMBER;
             this.post_invo.item[bi].CUSTOMER_NAME = this.data[i].CUSTOMER_NAME;
             this.post_invo.item[bi].BILL_DATE = this.data[i].BILL_DATE;
             this.post_invo.item[bi].CUSTOMER_GST_IN = this.data[i].CUSTOMER_GST_IN;
             this.post_invo.item[bi].SUB_TOTAL = (this.data[i].GRAND_TOTAL-0) -  (this.data[i].TAX_COLLECTED-0);
             this.post_invo.item[bi].TAX_COLLECTED = this.data[i].TAX_COLLECTED 

               if(this.data[i].CESS_COLLECTED === null)
                     {
                       this.post_invo.item[bi].CESS_COLLECTED = 0
                     }
               else
                    {
                        this.post_invo.item[bi].CESS_COLLECTED = this.data[i].CESS_COLLECTED;
                     }
    
             this.post_invo.item[bi].GRAND_TOTAL = this.data[i].GRAND_TOTAL;
     
                    this.post_invo.item[bi].SI = i+1;

                    this.total_sub_total = (this.total_sub_total-0) + (this.data[i].SUB_TOTAL-0);
 
                     this.total_tax = (this.total_tax-0) + (this.data[i].TAX_COLLECTED-0); 

                       this.total_cess =   (this.total_cess-0) + (this.post_invo.item[bi].CESS_COLLECTED-0) ;

                     this.total_grand = (this.total_grand-0) + (this.data[i].GRAND_TOTAL-0); 

                     bi = bi + 1;

                     this.post_invo.item[bi-1].SI = bi;


          }  


          else

          {
                this.auto_push_b2c();
            
             this.post_invo_c.item[ci].INVOICE_NUMBER = this.data[i].INVOICE_NUMBER;
             this.post_invo_c.item[ci].CUSTOMER_NAME = this.data[i].CUSTOMER_NAME;
             this.post_invo_c.item[ci].BILL_DATE = this.data[i].BILL_DATE;
             this.post_invo_c.item[ci].CUSTOMER_GST_IN = '';
             this.post_invo_c.item[ci].SUB_TOTAL = (this.data[i].GRAND_TOTAL-0) -  ((this.data[i].TAX_COLLECTED-0) + (this.data[i].CESS_COLLECTED-0));
             this.post_invo_c.item[ci].TAX_COLLECTED = this.data[i].TAX_COLLECTED 
             this.post_invo_c.item[ci].GRAND_TOTAL = this.data[i].GRAND_TOTAL;

                 if(this.data[i].CESS_COLLECTED === null)
                     {
                       this.post_invo_c.item[ci].CESS_COLLECTED = 0
                     }
               else
                    {
                        this.post_invo_c.item[ci].CESS_COLLECTED = this.data[i].CESS_COLLECTED;
                        this.post_invo_c.item[ci].GRAND_TOTAL = (this.post_invo_c.item[ci].GRAND_TOTAL-0) 
                     }

     
                    this.post_invo_c.item[ci].SI = i+1;

                    this.total_sub_total_c = (this.total_sub_total_c-0) + (this.data[i].SUB_TOTAL-0);
 
                     this.total_tax_c = (this.total_tax_c-0) + (this.data[i].TAX_COLLECTED-0); 

                       this.total_cess_c =   (this.total_cess_c-0) + (this.post_invo_c.item[ci].CESS_COLLECTED-0) ;

                     this.total_grand_c = (this.total_grand_c-0) + (this.data[i].GRAND_TOTAL-0); 
                     ci = ci + 1

                     this.post_invo_c.item[ci-1].SI = ci;

          }

       }

 
 }


   print_re()
  {
    window.print();
  }


 auto_push()
  {
       
        this.post_invo.item.push({ "SI":1,
         "INVOICE_NUMBER":1,"BILL_DATE":'',"CUSTOMER_NAME":'',
         "CUSTOMER_GST_IN" :'', "SUB_TOTAL":0,
          "TAX_COLLECTED" : 0,"CESS_COLLECTED":0,"GRAND_TOTAL":0 })

    

  }

   auto_push_b2c()
  {
       
        this.post_invo_c.item.push({ "SI":1,
         "INVOICE_NUMBER":1,"BILL_DATE":'',"CUSTOMER_NAME":'',
         "CUSTOMER_GST_IN" :'', "SUB_TOTAL":0,
          "TAX_COLLECTED" : 0,"CESS_COLLECTED":0,"GRAND_TOTAL":0 })

    

               }




               auto_push_r()
         {
       
        this.post_invo_r.item.push({ID:0,SI_NO:0,INVOICE_NO:0,CUSTOMER_NAME:'',CUSTOMER_GST_IN:'Nil',
      IS_B2B:true,BILL_DATE:'',RETURN_DATE:'',ITEM_LENGTH:0,SALES_RET_AMT:0,SALES_RET_GST:0,SALES_RET_CESS:0})

    

               }



               exportTable(){
                TableUtil.exportToExcel("ExampleTable");
              }

              exportTable_b2b(){
                TableUtil.exportToExcel("ExampleTable_b2b");
              }

              exportTable_s_r(){
                TableUtil.exportToExcel("ExampleTable_s_r");
              }

              exportTable_pur()
              {
                TableUtil.exportToExcel("ExampleTable_pur");
              }

              exportTable_p_r()
              {
                TableUtil.exportToExcel("ExampleTable_pur_ret");

              }
            



}


