import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { DataService } from '../../services/data.service';
import { UpdateService } from '../../services/update.service';
import { ExcelService } from '../../services/excel.service';
import {  ReportService } from '../../services/report.service';
import { p_model } from './model';
import { items } from './model';
import { TableUtil } from "../auditor/tableUtil";

@Component({
  selector: 'stock-despatch-list',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Stock_despatch implements OnInit {
 

   arrayOfKeyValues: any[] = [];
   post_invo = new items(1,[]);
   isclicked : boolean= false;
   avil_qty : any;
   view : boolean = false;
   product_array : p_model [] = [];
   filtered_array : p_model [] = [];
   smart_array : p_model [] = [];
   smart_copy_array : p_model [] = [];
   exact_array : p_model [] = [];
   pdtlist : any [] = [];
   qty_list : any [] = [];
   total_net_value : number = 0;
   total_avl_qty : number = 0;
   cats : any [] = [];
   view_cat :  boolean = false;
   category : string = '';
   pr_cats : string = '';
   bool : boolean ;
   j : number = 0 ;
   
   total_items : number = 0;
   z : number = 0;
   mikd : boolean = false;

   fil_count : number = 0;

   index : number = 0;
   total_quantity : number = 0
     dateFormm = this.fb.group({                  
                                               
      from_date : '1987-09-10',
      to_date: '2055-08-09',
      cat : '',
      option : 2
                                                 
                                                 
                                              
                                         });

  constructor(private fb: FormBuilder,private es: ExcelService, private router: Router , private Rs : ReportService,
     private ds: DataService , private us : UpdateService) {   }
  ngOnInit()  {
                    this.get_lists();
     
                    this.get_cats();

              }

  get_cats()
  {
        this.ds.get_all_sales_routes()
       .subscribe((jsonData) => { this.getjson_cats(jsonData)
                      },(err) => console.error(err),
                      
                      );

  }

getjson_cats(json:any)
{
 

     this.cats[0] = 'All'

       for(var i = 0; i < json.length ; i++)
       {

         this.cats[i+1] = json[i].ROUTE_NAME;
       }
this.view_cat = true;

}


  change_cat(x:any)
  {
    

    this.z = this.post_invo.item.length
    while (this.z > 1) 
    {
       this.post_invo.item.pop();
        this.z--                  
    }


     console.log('boxer', this.post_invo.item );
     
      this.category = x;
      this.search_by_cats();

  }

  search_by_cats()
  {

        // this.isClickedOnce = true

        this.product_array = this.exact_array;


        if(this.category != 'All')
        {

        
             this.product_array = this.exact_array.filter(xy=> xy.ROUTE_ID === this.category)
    

            // this.express(this.product_array)


        }


        else
       {
         this.product_array = this.exact_array;
        // this.express(this.product_array)
        
       }


       this.express(this.product_array)

       this.mikd =  true
  
  }
  

get_lists()
{

  this.dateFormm.patchValue({from_date:  this.Rs.from_date, to_date: this.Rs.to_date })
    
  this.us.list_stocks_by_routes(this.dateFormm.value)
 .subscribe((jsonData) => { this.getjson2(jsonData)
                      },(err) => console.error(err),
                      
                      );
}
  
 exportAsXLSX():void {
   // this.es.exportAsExcelFile(this.product_array, 'stock_report');
    TableUtil.exportToExcel("StockTable_des");
    
  }
  

      getjson2(json :any)
  {
   
    this.view = true;
this.product_array = json;
this.exact_array = json;



 
 for(var od = 0 ; od < json.length ; od++)
 {
   this.product_array[od].NET_VALUE =  this.product_array[od].NET_PRICE * this.product_array[od].AVAIL_QTY;
   this.total_net_value = (this.total_net_value-0) +   (this.product_array[od].NET_VALUE-0);
   this.total_avl_qty = (this.total_avl_qty-0)+ (this.product_array[od].AVAIL_QTY-0)
 }


   
  }



 print_report() 
{
  window.print();
}
 



auto_push()
{
      this.post_invo.item.push({ SI:1,DESCRIPTION:'',QUANTITY:0,ROUTE_ID:null})

             }




express(data:any)
{  

   

       this.j = 0;

       


  for(var ix = 0; ix < data.length ; ix++)

      {
 
       this.bool = this.check_list(data[ix].PRODUCT_DESCRIPTION);

          if ( this.bool === false)
          {
          this.pdtlist[this.j] = data[ix].PRODUCT_DESCRIPTION;
          this.qty_list[this.j] = data[ix].QUANTITY;
          
          this.j = this.j + 1;
          }

          else

          {
            // this.fil_count = this.fil_count + 1 ;
                // this.smart_array[this.index].QUANTITY = (this.smart_array[this.index].QUANTITY-0) + (data[ix].QUANTITY-0)

                // this.smart_array = this.filtered_array.splice(0,ix) 

              this.qty_list[this.index]  = ( this.qty_list[this.index]-0) +  (data[ix].QUANTITY-0)

             
              
          }



   
      }



      console.log('p array',this.pdtlist);
      console.log('q array',this.qty_list);
     

     
  
       for(var ixz = 0; ixz < this.j ; ixz++)
      {
        console.log('ymmam');
         this.auto_push();
         this.post_invo.si = ixz + 1
         this.post_invo.item[ixz].QUANTITY = this.qty_list[ixz];
        //  this.post_invo.item[this.j].ROUTE_ID = data[ixz].ROUTE_ID;
         this.post_invo.item[ixz].DESCRIPTION = this.pdtlist[ixz];

  
      }

      // {

      //   console.log('p array',this.pdtlist[ix]);
      //   console.log('q array',this.qty_list[ix]);
      //   this.smart_copy_array[ix].DESCRIPTION = this.pdtlist[ix];
      //   this.smart_copy_array[ix].QUANTITY = this.qty_list[ix];
      // }


      // this.smart_array = this.smart_copy_array;

// this.auto_push();
// this.post_invo.si = this.j+1;
// this.post_invo.item[this.j].QUANTITY = data[ix].QUANTITY;
// this.post_invo.item[this.j].ROUTE_ID = data[ix].ROUTE_ID;
// this.post_invo.item[this.j].DESCRIPTION = data[ix].PRODUCT_DESCRIPTION;
// this.post_invo.item[this.j].SI = this.j+1;
//this.total_amount = this.total_amount + this.data[ix].AMOUNT;





// this.j = this.j + 1;

// }
// else 
// {

// this.post_invo.item[this.index].QUANTITY = data[ix].QUANTITY + this.post_invo.item[this.index].QUANTITY;

// this.filtered_array[this.index].QUANTITY = this.filtered_array[this.index].QUANTITY + data[ix].QUANTITY


// }
//   }

// this.total_items = 0
// this.total_quantity = 0
// this.filtered_array = this.product_array.copyWithin(this.product_array.length,0,this.product_array.length-1);
// for(var ix = 0; ix < this.post_invo.item.length ; ix++)
//   {
 
//      console.log('affirm',this.post_invo.item[ix].ROUTE_ID)

//     // this.filtered_array[ix].ROUTE_ID = this.post_invo.item[ix].ROUTE_ID;
//    this.filtered_array[0].ROUTE_ID = 'hi'
  
//       this.total_quantity = (this.total_quantity-0) + (this.post_invo.item[ix].QUANTITY-0);
//    } 




}


check_list( a :any) : boolean
{

 var i =0; var k = 0;
  for(i;i<this.pdtlist.length;i++)
                {
                      if(this.pdtlist[i] === a )
                      {
                         k = 1;
                         this.index = i;
                         return true;
                      }
                }
    if (k === 0)
    {
      return false;
    }
 
}






   
  }

