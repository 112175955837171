import { Component, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { columns, Company, data } from './assets';
import { Columns } from 'ngx-easy-table';
import { ConfigService } from './config';
import { Router } from '@angular/router';
@Component({
  selector: 'app-expence-main-view',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Expence_main_view implements OnInit {

  @ViewChild('detailsTemplate') detailsTemplateRef: TemplateRef<any>;
  columns: Columns[] = [];
  data: Company[] = [];

  configuration;
 
  clicked : number = 0;
  view : boolean = false;
  mos : any = {"invo_string":''};



  constructor( private fb: FormBuilder, private ds :DataService, private router : Router ,public rs : Router) {
    this.configuration = ConfigService.config;
    // this.data = data;
     this.columns = columns;
   } 

  ngOnInit() {
   

       this.get_all_mis_expense()
   

    
  }


  get_all_mis_expense()
   {
        this.ds.get_all_mis_expensesss()
    .subscribe((jsonData) => { this.expenses_res(jsonData)
                      },(err) => console.error(err),
                       
                      );
   }

   expenses_res(json:any)
   {
     console.log('nicj',json)
     
     this.data = json;
     this.view = true;
   }

   eventEmitted($event) {

     console.log('e',$event)

    if($event.event == 'onClick')
    {
      this.clicked = $event.value.row.EXPENCE_ENTRY_NO;
      alert("Selected Expense Number  " + this.clicked);
      this.ds.ee_entry_no = this.clicked
      this.rs.navigate(['/expense-view-edit']); 
    }
    else
    {
      
        
         
       
       //  this.rs.navigate(['/New']);

    }

 }
   
}